import React, { ChangeEvent } from "react";
import { FormControl, Select } from "@chakra-ui/react";
import { InputWrapper } from "./InputWrapper";
import { DropdownProps } from "./types";

export const Dropdown: React.FC<DropdownProps> = ({
    helperText,
    isInvalid,
    options,
    selection,
    setSelection,
    defaultValue,
    title
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelection(event.target.value)
    }

    const renderDropdown = () => {
        return(
            <FormControl>
                <Select 
                    placeholder="Select the chapter for this assignment" 
                    isInvalid={isInvalid}  
                    onChange={(event) => handleChange(event)}
                    value={selection}
                >
                    {options.map((item) => {
                        return(
                            <option key={item.chapterId} value={item.chapterId}>Chapter {item.chapterOrderNumber}: {item.chapterTitle}</option>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    return(
        <InputWrapper 
            child={renderDropdown()}
            helperText={helperText}
            title={title}
        />
    );
};