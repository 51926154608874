import React from "react";
import { Container, Stack, Text } from "@chakra-ui/react";

const Footer: React.FC = () => {
    return(
    <Container 
        as="footer" 
        role="contentinfo" 
        py={{ base: '12', md: '16' }} 
        maxW="container.lg"
        width="100vw"
    >
        <Stack spacing={{ base: '4', md: '5' }} textAlign="center">
            <Text fontSize="sm" color="gray.800">
                &copy; Copyright {new Date().getFullYear()} - Alicia W. Walker. All rights reserved.
            </Text>
        </Stack>
    </Container>
    );
};

export default Footer;