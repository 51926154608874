import React from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import { 
    Box, 
    Container, 
    Divider, 
    Flex, 
    Heading, 
    IconButton, 
    Spacer, 
    Spinner, 
    Text, 
    Tooltip 
} from "@chakra-ui/react";
import { SubmissionDownloadProps } from "./types";
import { DownloadType } from "../../pages/course/enums";
import { useQuery, UseQueryResult } from "react-query";
import { pttpClient } from "../../clients/pttp";

const SubmissionDownload: React.FC<SubmissionDownloadProps> = ({ 
    chapters, 
    type,
    user,
    setAlertStatus,
    setNotice,
    course,
    isFetching
}) => {
    const [currentSending, setCurrentSending] = React.useState<number>(-1);
    const [courseId, setCourseId] = React.useState<string>("");
    const [chapterId, setChapterId] = React.useState<string>("");
    const [assignmentId, setAssignmentId] = React.useState<string>("");
    const [isSending, setIsSending] = React.useState<boolean>(false);

    const { 
        isFetching: getReflectionsSubmissionsIsFetching,
        refetch: getReflectionsSubmissionsRefetch
    }: UseQueryResult<string|null, Error>  = useQuery<string|null, Error>([`getChapterSubmittedReflectionsByCourse`, chapterId, courseId], async () => {        
        return pttpClient.getChapterSubmittedReflectionsByCourse(
            user, 
            chapterId,
            {
                courseId
            }
        )
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            window.scrollTo(0, 0);
            setAlertStatus("warning");
            setNotice("We're having trouble exporting the reflection question submissions for this course and chapter. It could be that no submitted reflection questions exist.");
        },
        onSuccess: (data) => {
            window.open(data ?? "", '_BLANK');
            window.scrollTo(0, 0);
            setAlertStatus("success");
            setNotice("Reflection question submissions were successfully exported. You may need to enable popups for this website in your browser.");
        }
    });

    const { 
        isFetching: getAssignmentSubmissionsIsFetching, 
        refetch: getAssignmentSubmissionsRefetch
    }: UseQueryResult<string|null, Error>  = useQuery<string|null, Error>([`getAssignmentSubmissionsByCourse`, assignmentId, courseId], async () => {        
        return pttpClient.getAssignmentSubmissionsByCourse(
            user, 
            assignmentId,
            {
                courseId
            }
        )
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            window.scrollTo(0, 0);
            setAlertStatus("warning");
            setNotice("We're having trouble exporting the assignment submissions for this course and chapter. It could be that no submitted reflection questions exist.");
        },
        onSuccess: (data) => {
            window.open(data ?? "", '_BLANK');
            window.scrollTo(0, 0);
            setAlertStatus("success");
            setNotice("Assignment submissions were successfully exported. You may need to enable popups for this website in your browser.");
        }
    });

    const handleReflectionsDownload = (courseId: string, chapterId: string) => {
        setCourseId(courseId);
        setChapterId(chapterId);

        setTimeout(() => {
            getReflectionsSubmissionsRefetch();
        }, 300);
        setIsSending(false);
    };

    const handleAssignmentsDownload = (courseId: string, assignmentId: string) => {
        setCourseId(courseId);
        setAssignmentId(assignmentId);

        setTimeout(() => {
            getAssignmentSubmissionsRefetch();
        }, 300);
        setIsSending(false);
    };

    const renderChaptersTable = () => {
        return (
            <>
                {chapters.map((chapter, index) => {
                const dueDate = chapter.assignment?.dueDate ? chapter.assignment?.dueDate : new Date("January 1, 2000");

                // TODO: Clean this logic up
                const isDisabled = type === DownloadType.REFLECTIONS ?
                    (chapter.reflectionsDueDate ? (chapter.reflectionsDueDate > new Date() ? true : false) : false) :
                    (chapter.assignment?.dueDate ? (dueDate > new Date() ? true : false) : false)

                return (
                    <Container paddingTop="5px" marginBottom="18px"  maxWidth="100%" key={index}>
                        <Flex height="70px" boxShadow="base" borderRadius="8px">
                            <Box padding="1.2rem .25rem 1rem 1.4rem">
                                <Text fontSize="lg" fontStyle="italic" noOfLines={1}>Chapter {chapter.orderNumber}: {chapter.title}</Text>
                            </Box>
                            <Spacer />
                            <Box padding=".6rem .6rem 1rem 0rem">
                                <Tooltip 
                                    hasArrow 
                                    label={isDisabled ? 
                                        `It is not yet past the due date for ${type === DownloadType.REFLECTIONS ? "reflection questions in this chapter." : "this chapter's assignment."}` : 
                                        `Download Student ${type === DownloadType.REFLECTIONS ? "Reflection" : "Assignment"} Submissions`
                                    } 
                                    placement="top" 
                                    bg="gray.700" 
                                    color="white" 
                                    borderRadius="5px" 
                                    padding="5px 10px 5px 10px"
                                >
                                    <IconButton
                                        as="a"
                                        href={"#"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsSending(true)
                                            setCurrentSending(index);
                                            type === DownloadType.REFLECTIONS ?  handleReflectionsDownload(course.id, chapter.id) : handleAssignmentsDownload(course.id, chapter.assignment?.id ?? "");
                                        }}
                                        variant="ghost"
                                        color="red.600"
                                        aria-label="Download Submissions"
                                        size="lg"
                                        isLoading={(isSending || getReflectionsSubmissionsIsFetching || getAssignmentSubmissionsIsFetching) ? (currentSending === index ? true : false) : false}
                                        icon={<DownloadIcon />}
                                        disabled={isDisabled}
                                    />
                                </Tooltip>
                            </Box>
                        </Flex>
                        <Divider />
                    </Container>
                );
            })}
            </>
        );
    }

    return(
        <Container maxW="container.lg" marginTop="48px" padding={0}>
            <Heading as="h3"  marginBottom="20px" fontSize="1.3rem">
                Download Student {type === DownloadType.REFLECTIONS ? "Reflection" : "Assignment"} Submissions
            </Heading>
            {isFetching ? 
                <Spinner /> : 
                (chapters.length !== 0 ? 
                    renderChaptersTable() :
                    <Text>There are no chapters with {type === DownloadType.REFLECTIONS ? "reflection questions" : "assignments"}.</Text>
                )
            }
        </Container>
    );    
};

export default SubmissionDownload;