import * as React from "react";
import {
    Box,
    ChakraProvider,
    Container,
    extendTheme,
    Grid,
    Spinner,
    ThemeConfig
} from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthContext } from "./config/context";
import { cognito } from "./clients/cognito";
import { User } from "./models/User";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import Home from "./pages/home";
import ProtectedRoute from "./components/ProtectedRoute/";
import Auth from "./pages/signIn";
import SignOut from "./pages/signOut";
import UpdatePassword from "./pages/resetPassword";
import Wrapper from "./pages/wrapper";
import Chapter from "./pages/chapter";
import NotFound from "./pages/404";
import EditChapter from "./pages/editChapter";
import EditAssignment from "./pages/editAssignment";
import Assignment from "./pages/assignment";
import Courses from "./pages/courses";
import EditCourse from "./pages/editCourse";
import Course from "./pages/course";
import { AxiosError } from "axios";

const App: React.FC = () => {

    const customTheme: ThemeConfig = extendTheme({
        config: {
            initialColorMode: "light",
            useSystemColorMode: false
        },
    });

    const [currentUser, setCurrentUser] = React.useState<User|null>(null);
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
    const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string>("");
    const [shouldShowChangePassword, setShouldShowChangePassword] = React.useState<boolean>(false);
    const [successfulSignInResult, setSuccessfulSignInResult] = React.useState<Promise<any>>(Promise.resolve());

    const reactQueryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: async (error) => {
                const err = error as AxiosError;

                if (err.request.status === 401) {
                    console.log("User session has expired. Logging user out...");
                    cognito.signOut(setIsAuthenticated, setError, setCurrentUser);
                }
            }
        }),
        defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
            }
        },
    });

    React.useEffect(() => {
        setIsAuthenticating(true);

        try {
            cognito.getUserSession(setIsAuthenticated, setCurrentUser);
        }
        catch(error: any) {
            if (error.message !== "No current user") {
                setCurrentUser(null);
                alert(error);
            }
        }

        setIsAuthenticating(false);
    }, []);

    if(isAuthenticating) {
        return (
            <Box textAlign="center" fontSize="md">
                <Grid minH="100vh" p={3}>
                    <Container alignSelf="center">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="red.500"
                            size="xl"
                        />
                    </Container>
                </Grid>
            </Box>
        );
    }

    const value = {
        currentUser,
        setCurrentUser,
        isAuthenticated, 
        setIsAuthenticated, 
        shouldShowChangePassword, 
        setShouldShowChangePassword, 
        successfulSignInResult, 
        setSuccessfulSignInResult
    };
  
    return(
        <ChakraProvider theme={customTheme}>

            <AuthContext.Provider value={value}>
                <QueryClientProvider client={reactQueryClient}>
                    <Router>
                        <Switch>
                            <ProtectedRoute path="/chapter/:id"  exact={false} component={Chapter} wrapper={Wrapper} />
                            <ProtectedRoute path="/assignment/:id"  exact={false} component={Assignment} wrapper={Wrapper} />
                            <ProtectedRoute path="/edit/chapter/:id"  exact={false} component={EditChapter} wrapper={Wrapper} />
                            <ProtectedRoute path="/edit/assignment/:id"  exact={false} component={EditAssignment} wrapper={Wrapper} />
                            <ProtectedRoute path="/add/chapter"  exact={false} component={EditChapter} wrapper={Wrapper} />
                            <ProtectedRoute path="/add/assignment"  exact={false} component={EditAssignment} wrapper={Wrapper} />
                            <ProtectedRoute path="/courses" exact={false} component={Courses} wrapper={Wrapper} />
                            <ProtectedRoute path="/add/course"  exact={false} component={EditCourse} wrapper={Wrapper} />
                            <ProtectedRoute path="/edit/course/:id"  exact={false} component={EditCourse} wrapper={Wrapper} />
                            <ProtectedRoute path="/course/:id"  exact={false} component={Course} wrapper={Wrapper} />
                            <ProtectedRoute path="/" exact={true} component={Home} wrapper={Wrapper} />
                            <Route path="/loggedout" component={SignOut} />
                            <Route path="/changepassword" component={UpdatePassword} />
                            <Route path="/login" component={Auth} />
                            <Route component={NotFound} />
                        </Switch>
                    </Router>
                </QueryClientProvider>
            </AuthContext.Provider>
          
        </ChakraProvider>
    );
};

export default App;
