import * as React from "react"
import {
    Box,
    Grid,
    Container,
    Alert,
    AlertIcon,
    Icon,
    Heading,
    AlertStatus
} from "@chakra-ui/react";
import { useParams, useLocation } from "react-router-dom";
import { IUrlParams } from "../chapter/types";
import { MdAssignment } from "react-icons/md";
import { Assignment } from "../../models/Assignment";
import { EditAssignmentState } from "./enums";
import { IChapterDropdownOption } from "../../components/EditAssignmentDetail/types";
import { EditAssignmentProps, EditAssignmentStateProps } from "./types";
import { ChapterMetadata } from "../../models/Chapter";
import { IDynamicQuestion } from "../../components/EditAssignmentQuestions/types";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import { pttpClient } from "../../clients/pttp";
import EditAssignmentDetail from "../../components/EditAssignmentDetail";
import PageError from "../../components/PageError";
import SkeletonLoad from "../../components/SkeletonLoad";

const EditAssignment: React.FC<EditAssignmentProps> = ({ loadedChapters, loadedAssignments, user }) => {
    useDocumentTitle("Edit Assignment");

    const { id } = useParams<IUrlParams>();
    const location = useLocation();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [chaptersMetadata, setChaptersMetadata] = React.useState<ChapterMetadata[]|null>(null);
    const [availableChapters, setAvailableChapters] = React.useState<IChapterDropdownOption[]|null>(null);
    const [assignment, setAssignment] = React.useState<Assignment|null>();
    const [error, setNotice] = React.useState<string>("");
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("error");
    const [editAssignmentState, setEditAssignmentState] = React.useState<EditAssignmentState>(EditAssignmentState.EDIT);

    // If/when we implement Q/A assignments, assignment questions and answers will have to be pulled,
    // put together into dynamicQuestions, and passed to EditAssignmentDetails. For now, we're leaving
    // this null.
    const [dynamicQuestions, setDynamicQuestions] = React.useState<IDynamicQuestion[]|null>(null);

    const { 
        isError: getIsError, 
        isFetching: getIsFetching,
        isFetched: getIsFetched,
        data: getData,
        refetch: getRefetch
    }: UseQueryResult<Assignment|null, Error>  = useQuery<Assignment|null, Error>([`getAssignment${id}`], async () => {
        return pttpClient.getAssignment(user, id ?? "")
    }, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    const { 
        data: getChaptersMetadataData,
        refetch: getChaptersMetadataRefetch
    }: UseQueryResult<ChapterMetadata[], Error>  = useQuery<ChapterMetadata[], Error>(["getChaptersMetadata"], async () => {
        return pttpClient.getChaptersMetadata(user)
    }, {
        cacheTime: 43200000,
        staleTime: 43200000,
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus("error");
            setNotice("We're having trouble retrieving the list of chapters that don't have assignments. Please try again.");
        }
    });

    React.useEffect(() => {
        if(location.pathname.endsWith("/add/assignment/") || location.pathname.endsWith("/add/assignment")) {
            setEditAssignmentState(EditAssignmentState.ADD);
        } else {
            if(id !== undefined) {
                getRefetch();
            }
        }
    }, [id, location.pathname, loadedAssignments, loadedChapters]);

    React.useEffect(() => {
        setAssignment(getData);
    }, [getData]);

    React.useEffect(() => {
        const stateChaptersMetadata = location.state ? (location.state as EditAssignmentStateProps).chaptersMetadata : null;
        
        if(!stateChaptersMetadata) {
            getChaptersMetadataRefetch();
        } else {
            setChaptersMetadata(stateChaptersMetadata);
        }
    }, [location.state]);

    React.useEffect(() => {
        setChaptersMetadata(getChaptersMetadataData ?? []);
    }, [getChaptersMetadataData]);

    React.useEffect(() => {
        // Get the chapters that don't yet have an assignment
        // Only call the API if chaptersMetadata aren't passed in the location state (user navigates from home page)
        if(chaptersMetadata?.length === 0) {
            setAvailableChapters(null);
        } else {
            const chaptersWithoutAssignments = chaptersMetadata?.filter((chapter) => {
                return chapter.assignment === null;
            });

            const availableChaptersAsDropdownItem: IChapterDropdownOption[]|undefined = chaptersWithoutAssignments?.map((chapter) => {
                return {
                    chapterId: chapter.id,
                    chapterOrderNumber: chapter.orderNumber,
                    chapterTitle: chapter.title
                }
            });

            setAvailableChapters(availableChaptersAsDropdownItem ?? []);
        }

        setIsLoading(false);
    }, [chaptersMetadata]);

    const renderHeader = () => {
        return <Heading marginBottom={10}>{editAssignmentState === EditAssignmentState.EDIT ? "Edit" : "Add"} Assignment</Heading>
    }

    if(getIsFetching || isLoading || (editAssignmentState === EditAssignmentState.EDIT && assignment === undefined)) {
        return (
            <SkeletonLoad />
        );
    }

    if(getIsError) {
        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    const renderComponent = (): React.ReactElement => {
        if(editAssignmentState === EditAssignmentState.EDIT) {
            // If the assignment is undefined, it hasn't been loaded yet. If it's null or an object, it's been loaded.
            // We only want to show the page error if the assignment has been fetched and not found.
            if(getIsFetched && assignment === null) {
                return <PageError message="This assignment doesn't exist." display={<Icon as={MdAssignment} w={180} h={180} color="red.500" />} />;
            } else if (getIsFetched && assignment) {
                return (
                    <>
                        {renderHeader()}
                        <EditAssignmentDetail 
                            state={editAssignmentState} 
                            assignment={assignment} 
                            setNotice={setNotice} 
                            setAlertStatus={setAlertStatus}
                            chapterDropdownOptions={availableChapters} 
                            dynamicQuestions={dynamicQuestions}
                            user={user}
                        />
                    </>
                );
            }
        }

        return (
                <>
                    {renderHeader()}
                    <EditAssignmentDetail 
                        state={EditAssignmentState.ADD} 
                        setNotice={setNotice} 
                        setAlertStatus={setAlertStatus}
                        chapterDropdownOptions={availableChapters} 
                        user={user}
                    />
                </>
            );
    }

    return(
        <>
            <Box id="_progress" backgroundColor="red.500"></Box>
            <Box textAlign="center" fontSize="md" paddingTop="20px">
                <Grid minH="100vh" p={{ sm: 0, lg: 3}} alignContent="flex-start">
                {
                    error !== "" &&
                    <Container maxW="container.lg"  marginBottom="40px">
                        <Alert status={alertStatus} borderRadius="8px" textAlign="left" justifySelf="center">
                            <AlertIcon />
                            {error}
                        </Alert>
                    </Container>
                }
                    <Container maxW="container.lg">
                        {renderComponent()}
                    </Container>
                </Grid>
            </Box>
        </>
    );
}

export default EditAssignment;