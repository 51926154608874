import { AssignmentFormat } from "../components/EditAssignmentDetail/enums";
import { IDynamicAnswerChoice } from "../components/EditAssignmentQuestions/types";
import { AssignmentMetadata, AssignmentSubmission } from "../models/Assignment";
import { ChapterMetadata } from "../models/Chapter";
import { User } from "../models/User";

/**
 * 
 * Datetime
 * 
 */

export const parsePrettyDate = (date: Date, includeTime=true) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const time = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });

    const day = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

    if(!includeTime) {
        return day;
    }

    return `${day} at ${time}`;
};

export const parsePrettyTime = (date: Date) => {
    return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
};

export const getDateFromUnixString = (unixString: string): Date => {
    return new Date(parseInt(unixString) * 1000)
}

export const getDateFromUnixStringOrNull = (unixString: string | undefined): Date | null => {
    if(!unixString) {
        return null;
    }

    return new Date(parseInt(unixString) * 1000);
}

export const getUnixStringFromDate = (date: Date): string => {
    return `${Math.floor(date.getTime() / 1000)}`;
};

export const getUnixStringFromDateOrNull = (date: Date | null): string | null => {
    if(!date) {
        return null;
    }

    return `${Math.floor(date.getTime() / 1000)}`;
};


/**
 * 
 * Custom type parsing
 * 
 */
export const getDynamicAnswerChoices = (answerChoices: string[]) => {
    let counter = 0;
    const choices: IDynamicAnswerChoice[] = [];

    for(let i = 0; i < answerChoices.length; i++) {
        choices.push({ index: counter, choice: answerChoices[i] })
        counter++;
    }

    return choices;
};

export const resetDynamicAnswerChoicesIndexes = (answerChoices: IDynamicAnswerChoice[]) => {
    const choices: IDynamicAnswerChoice[] = [];

    for(let i = 0; i < answerChoices.length; i++) {
        choices.push({ index: i, choice: answerChoices[i].choice })
    }

    return choices;
};

export const resetChapterOrderNumbers = (data: ChapterMetadata[]) => {
    const chapters = data;

    for(let i = 0; i < data.length; i++) {
        chapters[i].orderNumber = i + 1;
    }
    return chapters;
};

export const getChaptersWithAssignments = (chapters: ChapterMetadata[]): ChapterMetadata[] => {
    return chapters.filter((chapter) => chapter.assignment !== null);
};

export const getChaptersWithReflectionQuestions = (chapters: ChapterMetadata[]): ChapterMetadata[] => {
    return chapters.filter((chapter) => chapter.hasReflections);
};

export const getRelevantAssignmentSubmissions = (assignment: (AssignmentMetadata | null), assignmentSubmissions: AssignmentSubmission[]): (AssignmentSubmission[]) => {
    const relevantAssignmentSubmissions = assignmentSubmissions.filter((assignmentSubmission) => assignmentSubmission.assignmentId === assignment?.id);
   
    if(relevantAssignmentSubmissions) {
        return relevantAssignmentSubmissions;
    }
    return [];
}

export const deserializeAssignmentFormat = (format: string): AssignmentFormat => {
    switch(format) {
        case "Document":
            return AssignmentFormat.DOCUMENT
        case "Quiz":
            return AssignmentFormat.QUIZ
        default:
            return AssignmentFormat.VIDEO
    }
}


/**
 * 
 * Type checks
 * 
 */
export const isString = (value: any): value is string => {
    return typeof value === "string";
};

export const isStringList = (value: any): value is string[] => {
    return Array.isArray(value) && value.every(item => typeof item === "string");
};

export const isBoolean = (value: any): value is boolean => {
    return typeof value === "boolean";
};

export const isDate = (value: any): value is Date => {
    if(!value) {
        return false;
    }
    
    return (value as Date).getDate !== undefined;
};

export const getPageTitle = (title: string) => {
    return `${title} - The Holistic Conductor`;
};

/**
 * 
 * API
 * 
 */
export const getRequestHeaders = (user: User | null) => {
    return {
        "Authorization": user?.jwtToken ?? "",
        "Content-Type": "application/json",
    }
};

/**
 * 
 * Misc
 * 
 */

export const generateRandomId = (length: number) => {
    return Math.floor(Math.pow(10, length-1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length-1) - 1));
};

export const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);