import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuthContext } from "../../config/context";
import { ProtectedRouteProps } from "./types";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ wrapper, path, exact, ...props }) => {

    const { isAuthenticated } = useAuthContext();
    const location = useLocation();

    console.log(`Requested route: ${location.pathname}`);

    if(!isAuthenticated) {
        return (
            <>
                <Redirect to={{
                    pathname: '/login',
                    state: { redirectTo: location.pathname }
                }} />
            </>
        );
    } else {
         return <Route render={() => React.createElement(wrapper, props)} path={path} exact={exact} />;
    }
}

export default ProtectedRoute;