import * as React from "react"
import {
    Container,
    Button,
    Checkbox,
    Box,
    Divider,
    Heading,
    FormControl,
    FormHelperText,
} from "@chakra-ui/react";
import { EditCourseDetailProps } from "./types";
import { Prompt } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import { EditCourseState } from "../../pages/editCourse/enums";
import { DateTimePicker, Switcher, TextInput } from "../Inputs";
import { FiSave } from "react-icons/fi";
import { useQuery, UseQueryResult } from "react-query";
import { BiSend } from "react-icons/bi";
import { IPostOutput } from "../../constants/types";
import { pttpClient } from "../../clients/pttp";
import { getUnixStringFromDate, getUnixStringFromDateOrNull } from "../../utils";
import DatePicker from "react-datepicker";

const EditCourseDetail: React.FC<EditCourseDetailProps> = ({ 
    course, 
    state, 
    user,
    setNotice,
    setAlertStatus
}) => {
    const now = new Date();

    const tempMeetingTime = new Date();
    const tempEndDate = now;
    tempEndDate.setMonth(now.getMonth() + 4);

    const [title, setTitle] = React.useState<string>(course?.title ?? "");
    const [crn, setCrn] = React.useState<string>(course?.crn ?? "");
    const [meetingTime, setMeetingTime] = React.useState<Date|null>(course?.meetingTime ?? now);
    const [meetingDays, setMeetingDays] = React.useState<string>(course?.meetingDays ?? "");
    const [startDate, setStartDate] = React.useState<Date>(course?.startDate ?? now);
    const [endDate, setEndDate] = React.useState<Date>(course?.endDate ?? tempEndDate);
    const [syncWithBlackboard, setSyncWithBlackboard] = React.useState<boolean>(course?.syncWithBlackboard ?? false);
    const [blackboardId, setBlackboardId] = React.useState<string>(course?.blackboardId ?? "");

    const [titleIsInvalid, setTitleIsInvalid] = React.useState<boolean>(false);
    const [crnIsInvalid, setCrnIsInvalid] = React.useState<boolean>(false);
    const [startDateIsInvalid, setStartDateIsInvalid] = React.useState<boolean>(false);
    const [endDateIsInvalid, setEndDateIsInvalid] = React.useState<boolean>(false);
    const [blackboardIdIsInvalid, setBlackboardIdIsInvalid] = React.useState<boolean>(false);
    const [enableMeetingTimeIsChecked, setEnableMeetingTimeIsChecked] = React.useState<boolean>(course?.meetingTime ? true : false);

    const [changesHaveBeenSaved, setChangesHaveBeenSaved] = React.useState<boolean>(false);

    const { 
        isLoading: postIsLoading,
        refetch: postRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>(["updateCourse"], async () => {
        const courseId = course?.id ?? "invalidCourse";

        return pttpClient.updateCourse(
            user, 
            courseId, 
            state,
            {
                title,
                meetingDays,
                meetingTime: enableMeetingTimeIsChecked ? getUnixStringFromDateOrNull(meetingTime) : null,
                startDate: getUnixStringFromDate(startDate),
                endDate: getUnixStringFromDate(endDate),
                crn: crn
            }
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus("warning");
            setNotice("We're having trouble saving the course. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setAlertStatus("success");
            setNotice(`The course was successfully ${state == EditCourseState.EDIT ? "saved" : "added"}!`);
            window.scrollTo(0, 0);
        }
    });

    useBeforeunload((event) => {
        if (!changesHaveBeenSaved) {
          event.preventDefault();
        }
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setNotice("");

        if(title === "") {
            setTitleIsInvalid(true);
        } else {
            setTitleIsInvalid(false);
        }

        if(crn === "") {
            setCrnIsInvalid(true);
        } else {
            setCrnIsInvalid(false);
        }

        if(startDate === null) {
            setStartDateIsInvalid(true);
        } else {
            setStartDateIsInvalid(false);
        }

        if(endDate === null) {
            setEndDateIsInvalid(true);
        } else {
            setEndDateIsInvalid(false);
        }

        if(syncWithBlackboard) {
            if(blackboardId === "") {
                setBlackboardIdIsInvalid(true);
            } else {
                setBlackboardIdIsInvalid(false);
            }
        }

        if(title === "" || crn === "" || startDate === null || endDate === null || (syncWithBlackboard ? blackboardId === ""  : false)) {
            setAlertStatus("error");
            setNotice("Please fill out all of the required fields.");
            window.scrollTo(0, 0);
        } else {
            postRefetch();
        }

    }

    return(
        <>
            <Prompt
                when={!changesHaveBeenSaved}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <form
                onSubmit={(event) => {
                    setChangesHaveBeenSaved(true);
                    handleSubmit(event);
                }}
            >
                <TextInput 
                    placeholder="Course Title"
                    title="Course Title"
                    value={title}
                    isInvalid={titleIsInvalid}
                    setValue={setTitle}
                    helperText="Required. The course's title goes here."
                />
                <TextInput 
                    placeholder="Course CRN"
                    title="Course CRN"
                    value={crn}
                    isInvalid={crnIsInvalid}
                    setValue={setCrn}
                    helperText="Required. The unique CRN for this course."
                />
                <TextInput 
                    placeholder="Course Meeting Days"
                    title="Course Meeting Days"
                    value={meetingDays}
                    isInvalid={false}
                    setValue={setMeetingDays}
                    helperText="What days of the week does this course meet? (e.g. MWF)"
                />
                <Container maxW="container.lg" padding="0" marginBottom="40px">  
                    <Box marginBottom=".5rem">
                        <Heading as="h2" size="md" textAlign="left">
                            Course Meeting Time
                        </Heading>
                    </Box>
                    <Divider />
                    <Box textAlign="left" marginTop="1rem">
                        <Checkbox 
                            onChange={(event) => {
                                const isChecked = event.target.checked;

                                if(!isChecked) {
                                    setMeetingTime(null);
                                } else {
                                    setMeetingTime(tempMeetingTime)
                                }

                                setEnableMeetingTimeIsChecked(isChecked);
                            }} 
                            defaultChecked={enableMeetingTimeIsChecked}
                        >
                            Enable meeting time
                        </Checkbox>  
                    </Box>
                    <Box textAlign="left" marginTop="1rem">
                        {enableMeetingTimeIsChecked && <FormControl>
                            <DatePicker
                                selected={meetingTime ?? tempMeetingTime}
                                onChange={(date: Date) => {
                                    setMeetingTime(date);
                                }}
                                timeInputLabel="Time:"
                                dateFormat="h:mm aa"
                                showTimeSelectOnly={true}
                                showTimeInput
                            />
                            <FormHelperText lineHeight={7}>What time of day does this course meet?</FormHelperText>
                        </FormControl>}
                    </Box>  
                </Container>
                <DateTimePicker 
                    title="Course Start Date"
                    isInvalid={startDateIsInvalid}
                    date={startDate}
                    setDate={setStartDate}
                    showDateSelectOnly={true}
                    helperText="Required. What date does this course start?"
                />
                <DateTimePicker 
                    title="Course End Date"
                    isInvalid={endDateIsInvalid}
                    date={endDate}
                    setDate={setEndDate}
                    showDateSelectOnly={true}
                    helperText="Required. What date does this course end?"
                /> 
                <Switcher 
                    title="Sync With Blackboard"
                    isInvalid={false}
                    isChecked={syncWithBlackboard}
                    setIsChecked={setSyncWithBlackboard}
                    id="blackboard-switch"
                    helperText="Switch on if results of this assignment should sync with the course on Blackboard."
                    display="none"
                />
                {syncWithBlackboard && 
                <TextInput
                    placeholder="Blackboard ID"
                    title="Course Blackboard ID"
                    value={blackboardId}
                    isInvalid={false}
                    setValue={setBlackboardId}
                    helperText="Required. The unique ID of the Blackboard course."
                />}
                <Container maxWidth="container.lg" textAlign="left" padding="0">
                    <Button 
                        colorScheme="red" 
                        type="submit"
                        leftIcon={state === EditCourseState.ADD ? <BiSend /> : <FiSave />}
                        aria-label={state === EditCourseState.ADD ? "Add Course" : "Save Changes"}
                        isLoading={postIsLoading}
                    >
                        {state === EditCourseState.ADD ? "Add Course" : "Save Changes"}
                    </Button>
                </Container>
            </form>
        </>
    );
}

export default EditCourseDetail;