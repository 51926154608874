import { 
    Container,
    Flex,
    Box,
    Text,
    RadioGroup,
    Radio
} from "@chakra-ui/react";
import React from "react";
import { ActionsButtons } from "..";
import { QuestionDetailProps } from "../types";

const TrueFalseQuestionDetail: React.FC<QuestionDetailProps> = ({ 
    question, 
    number, 
    addUnsavedQuestion, 
    setUnsavedQuestion, 
    handleDeleteQuestion,
    scrollRef,
    isEditing=false 
}) => {
    const [isShown, setIsShown] = React.useState<boolean>(false);

    return (
        <Container 
            marginBottom="30px" 
            padding="0"
            maxW="container.lg" 
            key={question.question} 
            onMouseEnter={() => setIsShown(true)} 
            onMouseLeave={() => setIsShown(false)}
            style={{position: "relative"}}
        >
            <Flex marginBottom="10px">
                <Box>
                    <Text as="b">Question {number}</Text>: <Text as="i">True or False</Text>
                </Box>
            </Flex>
            <Flex marginBottom="14px">
                <Box>
                    <Text>{question.question}</Text>
                </Box>
            </Flex>
            {isEditing && <Flex marginBottom="14px">
                <Box>
                    <Text>Answer: <i>{question.answer}</i></Text>
                </Box>
            </Flex>}
            {!isEditing && 
                <RadioGroup defaultValue={question.answer} colorScheme="red" marginBottom="20px" isDisabled={isEditing}>
                    <Flex marginBottom="5px">
                        <Box>
                            <Radio value="true">True</Radio>
                        </Box>
                    </Flex>
                    <Flex>
                        <Box>
                            <Radio value="false">False</Radio>
                        </Box>
                    </Flex>
                </RadioGroup>
            }
            {(isEditing && isShown) && <ActionsButtons 
                                            question={question} 
                                            setUnsavedQuestion={setUnsavedQuestion} 
                                            addUnsavedQuestion={addUnsavedQuestion} 
                                            handleDeleteQuestion={handleDeleteQuestion}
                                            scrollRef={scrollRef}
                                            number={0} 
                                       />}
        </Container>
    );
};

export default TrueFalseQuestionDetail;