import * as React from "react"
import {
  Box,
  Grid,
  Container,
  Alert,
  AlertIcon,
  Heading,
  Flex,
  Button,
  Text,
  AlertStatus
} from "@chakra-ui/react";
import { CoursesProps } from "./types";
import { useHistory } from "react-router";
import { Course } from "../../models/Course";
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import { IGetCoursesMetadataOutput } from "../../constants/types";
import { pttpClient } from "../../clients/pttp";
import PageError from "../../components/PageError";
import SkeletonLoad from "../../components/SkeletonLoad/";
import CoursePreview from "../../components/CoursePreview";

const Courses: React.FC<CoursesProps> = ({ user }) => {
    useDocumentTitle("View Courses");

    const [activeCourses, setActiveCourses] = React.useState<Course[]>([]);
    const [expiredCourses, setExpiredCourses] = React.useState<Course[]>([]);
    const [notice, setNotice] = React.useState<string>("");
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("warning");

    const history = useHistory();

    const { 
        isFetching: getIsFetching, 
        isError: getIsError, 
        data: getData,
        isFetched: getIsFetched
    }: UseQueryResult<IGetCoursesMetadataOutput, Error>  = useQuery<IGetCoursesMetadataOutput, Error>(["getCoursesMetadata"], async () => {
        return pttpClient.getCoursesMetadata(user)
    }, {
        cacheTime: 43200000,
        staleTime: 43200000
    });

    const renderNotice = (): React.ReactElement => {
        return (
            <Container maxW="container.lg" justifySelf="center" marginBottom="30px">
                <Alert status={alertStatus} borderRadius="8px" textAlign="left">
                    <AlertIcon />
                    {notice}
                </Alert>
            </Container>
        )
    };

    React.useEffect(() => {
        if(getData) {
            setActiveCourses(getData?.activeCourses);
            setExpiredCourses(getData?.expiredCourses);
        }
    }, [user, getData]);

    const renderItems = (courses: Course[], active: boolean): React.ReactElement => {
        if(courses.length === 0) {
            return(
                <Text as="i">There are no {active ? "active" : "expired"} courses.</Text> 
            );
        }

        return(
            <>
                { 
                    courses.map((course) => {
                        return (
                            <CoursePreview 
                                course={course} 
                                key={course.id} 
                                setNotice={setNotice} 
                                setAlertStatus={setAlertStatus}
                                user={user}
                                endDate={course.endDate}
                            />
                        );
                    })
                }
            </>
        );
    }

    if(getIsFetching || (!getIsFetched && activeCourses.length === 0) || (!getIsFetched && expiredCourses.length === 0)) {
        return (
            <SkeletonLoad />
        );
    }

    if(getIsError) {
        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    return(
        <>
            <Box textAlign="center" fontSize="md" paddingTop="20px">
                <Grid p={3} alignContent="flex-start">
                    {notice !== "" && renderNotice()}
                    <Container maxW="container.lg" marginBottom="18px" marginTop="10px">
                        <Flex justifyContent="space-between">
                            <Box justifySelf="flex-start" alignSelf="center">
                                <Button leftIcon={<ArrowBackIcon />} onClick={() => history.goBack()}>
                                    Return Home
                                </Button>
                            </Box>
                            <Box>
                                <Button rightIcon={<AddIcon />} onClick={() => history.push('/add/course')}>
                                    Add Course
                                </Button>
                            </Box>
                        </Flex>
                    </Container>
                    <Container maxW="container.lg" marginBottom="20px">
                        <Heading as="h3" size="md" marginBottom="20px">Active Courses</Heading>
                        {renderItems(activeCourses, true)}
                    </Container>
                    <Container maxW="container.lg">
                        <Heading as="h3" size="md" marginBottom="20px">Expired Courses</Heading>
                        {renderItems(expiredCourses, false)}
                    </Container>
                </Grid>
            </Box>
        </>
    );
}

export default Courses;