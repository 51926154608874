import * as React from "react"
import {
  Box,
  Container,
  Button,
  AlertIcon,
  Alert,
  Icon,
  Text,
  Flex,
  useMediaQuery
} from "@chakra-ui/react";
import { AiTwotoneHome } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { useAuthContext } from "../../config/context";
import { cognito } from "../../clients/cognito";
import { useLocation, useHistory } from "react-router";
import { HeaderProps } from "./types";
import { useQuery, UseQueryResult } from "react-query";

const Header: React.FC<HeaderProps> = ({ user }) => {

    const { setIsAuthenticated, setCurrentUser } = useAuthContext();
    const [pageError, setPageError] = React.useState<string>("");
    const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
    const location = useLocation();
    const history = useHistory();

    const { 
        isLoading,
        error,
        refetch
    }: UseQueryResult<any, Error>  = useQuery<any, Error>([`signOut`], async () => {
        return cognito.signOut(setIsAuthenticated, setPageError, setCurrentUser);
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => setPageError(error?.message ?? "")
    });

    const handleSignOut = (): void => {
        console.log("Signing out...");
        refetch();
    }

    const shouldDisplayHomeButton = location.pathname !== "/";

    const renderHomeButton = (): React.ReactElement => {
        return(
            <Button 
                leftIcon={<Icon as={AiTwotoneHome} w={4} h={4} marginLeft={isSmallerThan1000 ? "5px" : "0"} />} 
                colorScheme="red" 
                variant="outline"  
                marginRight="20px" 
                title="Return to home" 
                onClick={() => history.push("/")}
                aria-label="Return to home"
            >
                <Text display={isSmallerThan1000 ? "none" : "block"}>Return to Home</Text>
            </Button>
        );
    };

    return(
        <>
            <Container 
                maxW="container.lg" 
                marginBottom={shouldDisplayHomeButton ? "20px" : "30px"}
            >
                <Flex 
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                >
                    <Box textAlign="left" flexGrow="1" alignSelf="center" display={shouldDisplayHomeButton ? "none" : "block"}>
                        Welcome, {user ? `${user.givenName} ${user.familyName}` : "user"}!
                    </Box>
                    <Box textAlign="right"flexGrow="1">
                        {shouldDisplayHomeButton && renderHomeButton()}
                        <Button 
                            leftIcon={<Icon as={FiLogOut} 
                            marginLeft={isSmallerThan1000 ? "6px" : "0"} />} 
                            colorScheme="red" variant="solid" 
                            onClick={handleSignOut} title="Sign out"
                            aria-label="Sign Out"
                            isLoading={isLoading}
                            loadingText={"Signing out..."}
                        >
                            <Text display={isSmallerThan1000 ? "none" : "block"}>Sign out</Text>
                        </Button>
                    </Box>
                </Flex>
            </Container>
            <Container>
                {
                    pageError !== "" &&
                    <Alert status="error" borderRadius="8px">
                        <AlertIcon />
                        {error}
                    </Alert>
                }
            </Container>
        </>
    );
}

export default Header;