import React from "react";
import {
    Container,
    Text,
    Box,
    Flex,
    Tooltip,
    IconButton,
    FormControl,
    Input,
    Icon,
    FormHelperText,
    Select,
    Button
} from "@chakra-ui/react";
import { CgCornerDownRight } from "react-icons/cg"
import { IDynamicAnswerChoice, EditMultipleChoiceQuestionProps } from "../types";
import { AddIcon, CheckIcon, CloseIcon, MinusIcon } from "@chakra-ui/icons";
import { generateRandomId, getDynamicAnswerChoices, resetDynamicAnswerChoicesIndexes } from "../../../utils";
import { QuestionType } from "../../../models/Question";

const EditMultipleChoiceQuestion: React.FC<EditMultipleChoiceQuestionProps> = ({question, answer,  setUnsavedQuestion, savedAnswerChoices, handleAddQuestion, dynamicId}) => {
    const [dynamicAnswerChoices, setDynamicAnswerChoices] = React.useState<IDynamicAnswerChoice[]>(savedAnswerChoices ? getDynamicAnswerChoices(savedAnswerChoices) : []);
    const [dynamicQuestion, setDynamicQuestion] = React.useState<string>(question ?? "");
    const [dynamicAnswer, setDynamicAnswer] = React.useState<string>(answer ?? "");

    const renderAnswerChoice = (answerChoice: IDynamicAnswerChoice) => {
        return(
            <Flex flexBasis="100%" paddingTop="10px" key={answerChoice.index}>
                <Box flex="1" marginRight="10px" textAlign="right">
                    <Icon as={CgCornerDownRight} />
                </Box>
                <Box flex="23" marginRight="10px">
                    <Input 
                        placeholder="Write answer choice here" 
                        value={answerChoice.choice}
                        onChange={(event) => {
                            const modifyableAnswerChoices = [ ...dynamicAnswerChoices ];
                            modifyableAnswerChoices[answerChoice.index].choice = event.target.value;
                            setDynamicAnswerChoices(modifyableAnswerChoices);
                        }}
                    />
                </Box>
                <Box flex="2">
                <Tooltip hasArrow label="Remove Answer Choice" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                    <IconButton 
                        aria-label="remove-answer-choice" 
                        size="md" 
                        colorScheme="red"
                        variant="ghost" 
                        icon={<MinusIcon w={3} h={3} />} 
                        onClick={() => {
                            const modifyableAnswerChoices = [ ...dynamicAnswerChoices ]
                            modifyableAnswerChoices.splice(answerChoice.index, 1);
                            setDynamicAnswerChoices(resetDynamicAnswerChoicesIndexes(modifyableAnswerChoices));
                        }}
                    />
                    </Tooltip>
                </Box>
            </Flex>
        );
    };

    React.useEffect(() => {
        if(!savedAnswerChoices) {
            setDynamicAnswerChoices([ {index: 0, choice: ""} ]);
        }
    }, [savedAnswerChoices]);

    return(
        <Container padding="0px 20px 50px 20px" maxW="container.lg">
            <Flex>
                <Box w="12px" bg="red.100"></Box>
                <Box flex="1" marginLeft="10px">
                    <Text as="b">Multiple Choice Question:</Text>
                    <Flex flexBasis="100%" paddingTop="10px">
                        <Box flex="24" marginRight="10px">
                            <FormControl>
                                <Input 
                                    placeholder="What would you like to ask?" 
                                    value={dynamicQuestion} 
                                    onChange={(event) => setDynamicQuestion(event.target.value)}
                                />
                                <FormHelperText textAlign="left">Question</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box flex="1" textAlign="right" marginRight="10px">
                            <Tooltip hasArrow label="Stop Editing Without Saving" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                                <IconButton 
                                    aria-label="delete-question" 
                                    size="md" 
                                    colorScheme="red" 
                                    icon={<CloseIcon w={3} h={3} />} 
                                    onClick={() => {
                                        setUnsavedQuestion(null);
                                        setDynamicAnswerChoices([]);
                                    }}
                                />
                            </Tooltip>
                        </Box>
                        <Box flex="1" textAlign="right">
                            <Tooltip hasArrow label="Add Question" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                                <IconButton 
                                    aria-label="add-question" 
                                    size="md" 
                                    colorScheme="green" 
                                    icon={<CheckIcon w={3} h={3} />} 
                                    onClick={() => handleAddQuestion({
                                        dynamicId: dynamicId ?? `dynamicId_${generateRandomId(5)}`,
                                        questionType: QuestionType.MULTIPLE_CHOICE,
                                        question: dynamicQuestion,
                                        answer: dynamicAnswer,
                                        answerChoices: dynamicAnswerChoices.map((answerChoice) => answerChoice.choice)
                                    }, dynamicId ? true : false)}
                                />
                            </Tooltip>
                        </Box>
                    </Flex>
                    {dynamicAnswerChoices.map((answerChoice) => renderAnswerChoice(answerChoice))}
                    <Flex flexBasis="100%" paddingTop="30px" justifyContent="center">
                        <Box>
                            <Button 
                                leftIcon={<AddIcon />} 
                                colorScheme="red" 
                                size="sm"
                                onClick={() => {
                                        if(dynamicAnswerChoices.length !== 0) {
                                            setDynamicAnswerChoices([ ...dynamicAnswerChoices, { index: dynamicAnswerChoices[dynamicAnswerChoices.length - 1].index + 1, choice: "" } ]);
                                        } else {
                                            setDynamicAnswerChoices([ {index: 0, choice: ""} ]);
                                        }
                                    }
                                }
                            >
                                Add Answer Choice
                            </Button>
                        </Box>
                    </Flex>
                    <Flex flexBasis="100%" paddingTop="30px" justifyContent="center" alignItems="center">
                        <Box marginRight="10px">
                            Which answer choice is the correct one?
                        </Box>
                        <Box>
                            <Select 
                                placeholder="Select answer" 
                                defaultValue={dynamicAnswer}
                                onChange={(event) => setDynamicAnswer(event.target.value)}
                            >
                                {dynamicAnswerChoices.map((answerChoice) => {
                                        return <option 
                                                    value={answerChoice.choice} 
                                                    key={answerChoice.index}
                                                >
                                                    {answerChoice.choice}
                                                </option>;
                                    }
                                )}
                            </Select>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Container>
    );
};

export default EditMultipleChoiceQuestion;