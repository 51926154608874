import * as React from "react"
import {
  Box,
  Grid,
  Container,
  Spinner,
} from "@chakra-ui/react";
import { cognito } from "../../clients/cognito";
import { useAuthContext } from "../../config/context";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import { ILocation } from "./types";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import Login from "../../components/Login";

const Auth: React.FC = () => {
    useDocumentTitle("Sign In");

    const { 
        isAuthenticated, 
        setIsAuthenticated, 
        setShouldShowChangePassword, 
        setSuccessfulSignInResult,
        setCurrentUser
    } = useAuthContext();

    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [pageError, setPageError] = React.useState<string>("");
    const [isAuthenticating, setIsAuthenticating] = React.useState<boolean>(false);

    const { 
        isLoading, 
        error,
        refetch
    }: UseQueryResult<any, Error>  = useQuery<any, Error>([`signIn`], async () => {
        return cognito.signIn(
            email, 
            password, 
            setPageError, 
            setIsAuthenticated, 
            setShouldShowChangePassword, 
            setSuccessfulSignInResult,
            setCurrentUser
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => setPageError(error?.message ?? "")
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setPageError("");
        setIsAuthenticating(true);

        refetch();

        setIsAuthenticating(false);
    }

    const { state } = useLocation<ILocation>();

    if(isAuthenticated) {
        return <Redirect to={state !== undefined ? state.redirectTo : "/"} />;
    }

    const renderComponent = () => {
        if(isAuthenticating) {
            return(
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="red.500"
                    size="xl"
                />
            );
        }

        return(
            <Login 
                setEmail={setEmail} 
                setPassword={setPassword} 
                handleSubmit={handleSubmit} 
                error={pageError}
                isLoading={isLoading}
            />
        );
    };

    return(
        <Box textAlign="center" fontSize="md">
            <Grid minH="100vh" p={3}>
                <Container alignSelf="center">
                    {renderComponent()}
                </Container>
            </Grid>
        </Box>
    );
}

export default Auth;