import React, { useEffect } from "react";
import {
    Heading,
    Container,
    Spacer,
    Text,
    UnorderedList,
    ListItem,
    Box,
    Button,
    Alert,
    AlertIcon,
    useDisclosure,
    AlertStatus,
} from "@chakra-ui/react";
import { AssignmentDetailProps } from "./types";
import { parsePrettyDate } from "../../utils";
import { IDynamicQuestion } from "../EditAssignmentQuestions/types";
import { MultipleChoiceQuestionDetail, ShortAnswerQuestionDetail, TrueFalseQuestionDetail } from "../AssignmentQuestionsDetail";
import { QuestionType } from "../../models/Question";
import { serializeDynamicQuestions } from "../../pages/assignment/serializer";
import { AssignmentFormat } from "../EditAssignmentDetail/enums";
import { BiSend } from "react-icons/bi";
import { TextInput } from "../Inputs";
import { useQuery, UseQueryResult } from "react-query";
import { IPostOutput } from "../../constants/types";
import { AssignmentSubmission } from "../../models/Assignment";
import { pttpClient } from "../../clients/pttp";
import DOMPurify from "dompurify";
import DefaultModal from "../modals/Default";

const AssignmentDetail: React.FC<AssignmentDetailProps> = ({ 
    assignment, 
    chapterNumber, 
    isPreviewing=false, 
    setChangesHaveBeenSaved,
    user
}) => {
    const [questionsList, setQuestionsList] = React.useState<IDynamicQuestion[] | null>(null);
    const [inputValue, setInputValue] = React.useState<string>("");
    const [inputValueIsInvalid, setInputValueIsInvalid] = React.useState<boolean>(false);
    const [dueDate, setDueDate] = React.useState<Date|null>(assignment.dueDate ?? new Date("January 1, 2000"));
    const [notice, setNotice] = React.useState<string>("");
    const [submission, setSubmission] = React.useState<AssignmentSubmission|null>(null);
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("info");
    const [isPastDueDate, setIsPastDueDate] = React.useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { 
        isLoading: saveIsLoading,
        refetch: saveRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>([`submitAssignment${assignment.id}`], async () => {
        return pttpClient.submitAssignment(
            user, 
            assignment.id,
            {
                sub: user?.id ?? "invalidUserId",
                url: inputValue
            }
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus && setAlertStatus("warning");
            setNotice && setNotice("We're having trouble submitting the assignment for this chapter. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setAlertStatus && setAlertStatus("success");
            setNotice && setNotice("Your assignment for this chapter were successfully saved!");
            window.scrollTo(0, 0);
        }
    });

    const {
        data: getAssignmentSubmissionData,
        refetch: getAssignmentSubmissionRefetch,
        isFetched: getAssignmentSubmissionIsFetched
    }: UseQueryResult<AssignmentSubmission|null, Error>  = useQuery<AssignmentSubmission|null, Error>([`getAssignmentSubmissionForUser${assignment?.id}`], async () => {
        return pttpClient.getAssignmentSubmissionForUser(
            user, 
            assignment?.id ?? "invalidAssignmentId",
            {
                sub: user?.id ?? "invalidUserId"
            },
            setNotice,
            setAlertStatus
        )
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        cacheTime: 3600000,
        staleTime: 3600000,
        retry: false
    });

    useEffect(() => {
        setQuestionsList(serializeDynamicQuestions(assignment.questions ?? []));

        setIsPastDueDate(dueDate ? (dueDate < new Date()) : false);

        // Look for an assignment submission
        if(!user?.isAdmin) {
            getAssignmentSubmissionRefetch();
        }
    }, [assignment]);

    useEffect(() => {
        setSubmission(getAssignmentSubmissionData ?? null);

        if(getAssignmentSubmissionData) {
            setAlertStatus("success");
            setNotice("You've successfully submitted an answer to this assignment! See below for a link to your assignment submission.");
        }
    }, [getAssignmentSubmissionData]);

    const handleOnOpen = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onOpen();
    };

    // These include question types that are not yet supported... remnants of an ambitious time
    const renderQuestion = (question: IDynamicQuestion, number: number) => {
        switch(question.questionType) {
            case QuestionType.TRUE_FALSE:
                return <TrueFalseQuestionDetail 
                            question={question} 
                            number={number} 
                            key={number}
                        />;
            case QuestionType.MULTIPLE_CHOICE:
                return <MultipleChoiceQuestionDetail
                            question={question} 
                            number={number} 
                            key={number} 
                        />;
            case QuestionType.SHORT_ANSWER:
                return <ShortAnswerQuestionDetail
                            question={question} 
                            number={number} 
                            key={number} 
                        />;
            default:
                return <Text>Cannot determine question type.</Text>;
        }
    }

    const renderView = () => {
        switch(assignment.format) {
            case AssignmentFormat.QUIZ:
                if(questionsList) {
                    let number = 0;
                    return questionsList.map((question) => {
                        number++;
                        return renderQuestion(question, number);
                    })
                }

                return (
                    <Text size="md" paddingBottom="30px">No questions have been added yet.</Text>
                );
            // Only Video is implemented as a feature
            case AssignmentFormat.VIDEO:
                return(
                    <TextInput 
                        placeholder="Paste video link"
                        value={(getAssignmentSubmissionIsFetched && submission !== null) ? (submission.url ?? "") : inputValue}
                        setValue={setInputValue}
                        isInvalid={inputValueIsInvalid}
                        isDisabled={getAssignmentSubmissionIsFetched && submission !== null}
                        title="Upload Submission Video Link"
                        helperText="Paste a link to your video submission here. You can host this video by uploading it to YouTube and setting the visibility to 'Unlisted'."
                    />
                );
            default:
                return(
                    <>This is a document upload input</>
                );
        }
    };

    const renderInstructions = () => {
        switch(assignment.format) {
            case AssignmentFormat.QUIZ:
                return(
                    <>
                        <Text align="left" fontSize="md" lineHeight={6} display="inline">
                            This assignment may include the following types of questions. Please read each question carefully and respond with the following instructions given the question type:
                        </Text>
                        <Spacer h={3} />
                        <Text align="left" fontSize="md" lineHeight={6} marginLeft={6}>
                            <UnorderedList>
                                <ListItem><i>True or False: </i>select if the statement in the question is True or False.</ListItem>
                                <ListItem><i>Multiple Choice: </i>select the best answer to the question out of the given answer choices.</ListItem>
                                <ListItem><i>Short Answer: </i>respond in your own words.</ListItem>
                            </UnorderedList>
                        </Text>
                    </>                    
                );
            // Only Video is implemented as a feature
            case AssignmentFormat.VIDEO:
                return(
                    <></>
                );
            default:
                return(
                    <Text align="left" fontSize="md" lineHeight={6} display="inline">
                        Please use the form below to either select a PDF document from your device or write your submission in the provided field.
                    </Text>
                );
        }
    };

    const handleSubmit = (): void => {
        setNotice && setNotice("");

        if(inputValue === "") {
            setInputValueIsInvalid(true);
        } else {
            setInputValueIsInvalid(false);
        }

        if(inputValue === "") {
            setAlertStatus && setAlertStatus("error");
            setNotice && setNotice("Please fill out all of the required fields.");
            window.scrollTo(0, 0);
        } else {
            saveRefetch();
        }

        onClose();

        setChangesHaveBeenSaved && setChangesHaveBeenSaved(true);
    };

    return(
        <Container maxWidth="container.lg" padding={0}>
            <form onSubmit={handleOnOpen}>
                {
                    notice !== "" &&
                    <Container maxW="container.lg"  marginBottom="40px">
                        <Alert status={alertStatus} borderRadius="8px" textAlign="left" justifySelf="center">
                            <AlertIcon />
                            {notice}
                        </Alert>
                    </Container>
                }
                <Text paddingBottom={4} size="md">Chapter {chapterNumber} Assignment</Text>
                <Heading as='h1'>{assignment.title}</Heading>
                <Spacer h={4} />
                {dueDate && <Text fontSize="md" as="i" color="gray.600">Due by {parsePrettyDate(dueDate)}</Text>}
                <Spacer h={10} />
                <Text align="left" fontSize="md" lineHeight={6}>
                    <span>Please carefully read the description below for how to proceed with this assignment. Once you have entered in a link to your video in the box below, press "Submit" to complete the assignment. If you navigate away from the page without pressing "Submit", your changes will be lost.</span>
                </Text>
                {renderInstructions()}
                <Spacer h={4} />
                <Text align="left" fontSize="md" lineHeight={6}>
                    <b>Description: </b>
                    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(assignment.description) !== "" ? assignment.description : "There is currently no content in this chapter."}}></span>
                </Text>
                <Spacer h={10} />
                {renderView()}
                <Box textAlign="left">
                    <Button 
                        colorScheme="red"
                        leftIcon={<BiSend />}
                        type="submit"
                        disabled={isPreviewing || isPastDueDate || (getAssignmentSubmissionIsFetched && submission !== null) || user?.isAdmin}
                        isLoading={saveIsLoading}
                    >
                        Submit Assignment
                    </Button>
                </Box>
            </form>
            <DefaultModal 
                isOpen={isOpen} 
                onClose={onClose} 
                action={handleSubmit}
                actionButtonLabel="Submit Assignment"
                header="Submit assignment?"
                body="Are you sure you want to submit this assignment? This action cannot be reversed."
            />
        </Container>
    );
}

export default AssignmentDetail;