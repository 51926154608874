import React from "react";
import { 
    Box, 
    Container, 
    Flex, 
    Heading,
    Spinner
} from "@chakra-ui/react";
import { WelcomeTableProps } from "./types";

const WelcomeTable: React.FC<WelcomeTableProps> = ({ name, message, isLoading }) => {
    const childFlex = "1 1 100%";
    const childPadding = "20px 20px 20px 20px";

    const welcomeMessage = name ? `Welcome, ${name}!` : "Welcome!";

    return(
        <Container 
            maxW="container.lg" 
            textAlign="left" 
            marginBottom="48px" 
        >
            <Flex 
                flexWrap="wrap" 
                color="gray.900" 
                boxShadow="base"
                borderRadius="8px"
            >
                <Box 
                    flexBasis="100%"
                    padding={childPadding}
                    backgroundColor="gray.50"
                    borderTopRadius="8px"
                    textAlign="center"
                >
                    <Heading as="h3" fontSize="1.3rem">{welcomeMessage}</Heading>
                </Box>
                        <Box 
                            flex={childFlex}
                            padding={childPadding}
                            color="gray.700"
                            lineHeight="8"
                            textAlign={isLoading ? "center" : "left"}
                        >
                            {isLoading ? <Spinner size='sm' /> : message}
                        </Box> 
            </Flex>
        </Container>
    );
};

export default WelcomeTable;