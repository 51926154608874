import { useContext, createContext } from "react";
import { User } from "../models/User";
import { setStateBoolean, setStatePromise, setStateUser } from "../constants/types";

export interface IAuthContext {
    currentUser: User | null;
    setCurrentUser: setStateUser;
    isAuthenticated: boolean;
    setIsAuthenticated: setStateBoolean;
    shouldShowChangePassword: boolean;
    setShouldShowChangePassword: setStateBoolean;
    successfulSignInResult: Promise<any>;
    setSuccessfulSignInResult: setStatePromise;
}

const initalAppContext: IAuthContext = {
    currentUser: null,
    setCurrentUser: (): void => {
        throw new Error('setContext function must be overridden');
    },
    isAuthenticated: false,
    setIsAuthenticated: (): void => {
        throw new Error('setContext function must be overridden');
    },
    shouldShowChangePassword: false,
    setShouldShowChangePassword: (): void => {
        throw new Error('setContext function must be overridden');
    },
    successfulSignInResult: Promise.resolve(),
    setSuccessfulSignInResult: (): void => {
        throw new Error('setContext function must be overridden');
    }
}

export const AuthContext = createContext<IAuthContext>(initalAppContext);

export const useAuthContext = () => {
  return useContext(AuthContext);
}