import * as React from "react"
import {
    Heading,
    Container,
    Spacer,
    Text,
    Box,
    FormControl,
    FormHelperText,
    Button,
    useDisclosure,
    Icon,
    Flex,
    Textarea,
    Spinner
} from "@chakra-ui/react";
import { ChapterDetailProps } from "./types";
import { ContentType } from "../EditChapterDetail/enums";
import { parsePrettyDate } from "../../utils";
import { useLocation } from "react-router-dom";
import { FiSave } from "react-icons/fi";
import { BiSend, BiMessageDetail } from "react-icons/bi";
import { IReflectionAnswer } from "../../models/ReflectionAnswer";
import { serializeReflectionAnswersInput } from "./serializer";
import { IDynamicContentItem } from "../EditChapterDetail/types";
import { useQuery, UseQueryResult } from "react-query";
import { IPostOutput } from "../../constants/types";
import { pttpClient } from "../../clients/pttp";
import DefaultModal from "../modals/Default";
import DOMPurify from "dompurify";
import ReflectionSubmissionModal from "../modals/ReflectionSubmission";
import SubmittedReflectionsTable from "../SubmittedReflectionsTable";
import "./index.css";

const ChapterDetail: React.FC<ChapterDetailProps> = ({ 
    chapter, 
    isDisabled, 
    submittedReflectionAnswers,
    setChangesHaveBeenSaved,
    user,
    setAlertStatus,
    setNotice,
    isSavedLoading,
    content,
    setContent
}) => {
    const isPastDueDate = chapter.reflectionsDueDate ? (new Date() > chapter.reflectionsDueDate ? true : false) : false;
    const isOutOfTries = submittedReflectionAnswers ? submittedReflectionAnswers.filter((item) => item.chapterId === chapter.id).length > 2 : false;
    const location = useLocation() as any;
    const shouldDisable = (isDisabled ?? (location.state?.isDisabled ?? false)) as boolean;
    
    const { isOpen: isSubmitOpen, onOpen: onSubmitOpen, onClose: onSubmitClose } = useDisclosure();
    const { isOpen: isSubmittedAnswerOpen, onOpen: onSubmittedAnswerOpen, onClose: onSubmittedAnswerClose } = useDisclosure();

    // Change initialization logic on this based on if any reflection submissions were found
    const [shouldShowReflectionsTable, setShouldShowReflectionsTable] = React.useState<boolean>(false);

    const [modalBody, setModalBody] = React.useState<IReflectionAnswer[]>([]);

    const handleEditReflectionItem = (changingItem: IDynamicContentItem) => {
        const modifyableReflectionAnswersList = [...content ?? []];

        modifyableReflectionAnswersList.forEach((item) => {
            if(item.dynamicId === changingItem.dynamicId) {
                item.answer = changingItem.answer;
            }
        });

        setContent && setContent(modifyableReflectionAnswersList);
    };
    
    React.useEffect(() => {
        if(isOutOfTries) {
            setAlertStatus && setAlertStatus("warning");
            setNotice && setNotice(`Sorry, you are out of submission attempts (${3}).`);
        }

        if(submittedReflectionAnswers && submittedReflectionAnswers.length > 0) {
            setShouldShowReflectionsTable(true);
        }
    }, [submittedReflectionAnswers]);

    const { 
        isLoading: saveIsLoading,
        refetch: saveRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>([`saveReflections${chapter.id}`], async () => {
        const chapterId = chapter?.id ?? "invalidChapter";

        return pttpClient.saveReflections(
            user, 
            chapterId,
            {
                sub: user?.id ?? "invalidUserId",
                reflectionAnswers: serializeReflectionAnswersInput(content)
            }
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus && setAlertStatus("warning");
            setNotice && setNotice("We're having trouble saving reflection answers for this chapter. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setAlertStatus && setAlertStatus("success");
            setNotice && setNotice("Your reflection answers for this chapter were successfully saved!");
            window.scrollTo(0, 0);
        }
    });

    const { 
        isLoading: submitIsLoading,
        refetch: submitRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>([`submitReflections${chapter.id}`], async () => {
        const chapterId = chapter?.id ?? "invalidChapter";

        return pttpClient.submitReflections(
            user, 
            chapterId,
            {
                sub: user?.id ?? "invalidUserId",
                reflectionAnswers: serializeReflectionAnswersInput(content)
            }
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus && setAlertStatus("warning");
            setNotice && setNotice("We're having trouble submitting reflection answers for this chapter. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setAlertStatus && setAlertStatus("success");
            setNotice && setNotice("Your reflection answers for this chapter were successfully submitted! You may need to refresh the page to see your submission in the table on the bottom of the page.");
            window.scrollTo(0, 0);
        }
    });

    const { 
        refetch: deleteSavedRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>([`deleteSavedReflections${chapter.id}`], async () => {
        const chapterId = chapter?.id ?? "invalidChapter";

        return pttpClient.deleteSavedReflections(user, chapterId, { sub: user?.id ?? "invalidId" });
    }, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    const handleSave = () => {
        saveRefetch();
        setChangesHaveBeenSaved && setChangesHaveBeenSaved(true);
    };

    const handleSubmit = () => {
        // close the modal
        onSubmitClose();

        // submit reflection answers
        submitRefetch();

        // delete saved reflection answers
        deleteSavedRefetch();
    };

    const getReflectionsButtonsDisplayProperty = () => {
        return chapter.hasReflections ? undefined : "none";
    };

    const renderReflectionQuestionComponent = (changingItem: IDynamicContentItem) => {
        return(
            <Flex flexDir="row">
                <Box>
                    <Icon as={BiMessageDetail} w={26} h={26} marginTop="4px" color="red.500" />
                </Box>
                <Box paddingLeft="20px" marginBottom="26px" width="100%">
                    <Text fontSize="lg" as="b">Reflection Question</Text>
                    <Text fontSize="lg" marginBottom="10px">{changingItem.value}</Text>
                    {!isDisabled ? (isPastDueDate ? <Text>The due date for submitting reflection questions to this chapter has passed.</Text> :
                    <FormControl>
                        <Textarea 
                            value={location.state?.isDisabled ? "" : (changingItem.answer ?? "")} 
                            placeholder="Please type answer here..." 
                            rows={10}
                            onChange={(event) => {
                                handleEditReflectionItem({
                                    dynamicId: changingItem.dynamicId,
                                    value: changingItem.value,
                                    type: changingItem.type,
                                    answer: event.target.value
                                });
                            }}
                        />
                        <FormHelperText className="rq-helper-text">What are your thoughts? Please reflect on the question above.</FormHelperText>
                    </FormControl>) : <Text>Students will see a text box where they can type their answer.</Text>}
                </Box>
            </Flex>
        );
    };

    const renderActionsComponent = () => {
        return(
            <Container maxWidth="container.lg" textAlign="left">
                <Box>
                    
                </Box>
                <Button 
                    colorScheme="red" 
                    variant="outline" 
                    type="submit" 
                    marginRight="10px" 
                    isLoading={saveIsLoading}
                    loadingText="Saving..."
                    leftIcon={<FiSave />}
                    onClick={handleSave}
                    disabled={shouldDisable || isPastDueDate || user?.isAdmin}
                    marginBottom={2}
                    display={getReflectionsButtonsDisplayProperty()}
                >
                    Save Reflection Questions
                </Button>
                <Button 
                    colorScheme="red"  
                    type="submit" 
                    isLoading={submitIsLoading}
                    loadingText="Submitting..."
                    leftIcon={<BiSend />}
                    onClick={() => {
                        onSubmitOpen();
                    }}
                    disabled={shouldDisable || isPastDueDate || isOutOfTries || user?.isAdmin}
                    marginBottom={2}
                    display={getReflectionsButtonsDisplayProperty()}
                >
                    Submit Reflection Questions
                </Button>
            </Container>
        );
    };

    const renderChapterGuestAuthors = (): JSX.Element => {
        let str = null;

        if(chapter.guestAuthors && chapter.guestAuthors.length === 1) {
            str = `With ${chapter.guestAuthors[0]}`;
        } else if (chapter.guestAuthors && chapter.guestAuthors.length > 1) {
            str = `With ${chapter.guestAuthors.join(", ")}`;
        }

        return (
            <>
                <Spacer h={6} />
                <Text size="lg">{str}</Text>
            </>
        );
    }

    return(
        <Container maxW="container.lg">
            <Box padding={0} className="chapter-metadata">
                <Text>Chapter {chapter.orderNumber ?? "--"}</Text>
                <Heading as="h1">{chapter.title}</Heading>
                {chapter.guestAuthors && renderChapterGuestAuthors()}
                <Spacer h={6} />
                {chapter.reflectionsDueDate && <Text as="i" fontSize="lg">Reflection questions are due at {parsePrettyDate(chapter.reflectionsDueDate)}</Text>}
                {chapter.reflectionsDueDate && <Spacer h={6} />}
            </Box>
            <Box textAlign="left">
                {content.map((item, index) => {
                    if(item.type === ContentType.REFLECTION_QUESTION) {
                        if(isSavedLoading) {
                            const paddingBottom = index + 1 == content.length ? "30px" : "10px";

                            if(index + 1 == content.length || content[index + 1].type === ContentType.CONTENT) {
                                return (
                                    <Box textAlign="center" paddingTop="10px" paddingBottom={paddingBottom} key={index}>
                                        <Spinner />
                                    </Box>
                                );
                            } else {
                                return;
                            }
                        }

                        return (
                            <Container maxWidth="container.lg" lineHeight={7} key={index}>
                                {renderReflectionQuestionComponent(item)}
                            </Container>
                        );
                    }

                    return(
                        <Container maxWidth="container.lg" textAlign="left" key={index} className="chapter-content">
                            <Text 
                                fontSize="lg" 
                                lineHeight="7"
                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.value) !== "" ? item.value : "There is currently no content in this chapter."}}
                            ></Text>
                        </Container>
                    );
                })}
            </Box>
            {renderActionsComponent()}
            <Spacer h={6} />
            {shouldShowReflectionsTable && 
            <SubmittedReflectionsTable 
                submissions={submittedReflectionAnswers ?? []} 
                setModalBody={setModalBody} 
                onOpen={onSubmittedAnswerOpen} 
            />}
            <DefaultModal 
                isOpen={isSubmitOpen} 
                onClose={onSubmitClose} 
                action={handleSubmit}
                actionButtonLabel="Submit Reflection Questions"
                header="Submit all reflection questions?"
                body="Warning: all reflection questions will be submitted, regardless of whether or not they are empty. This action cannot be reversed."
            />
            <ReflectionSubmissionModal 
                isOpen={isSubmittedAnswerOpen} 
                onClose={onSubmittedAnswerClose} 
                content={chapter.content} 
                body={modalBody}
            />
        </Container>
    );
}

export default ChapterDetail;