import * as React from "react"
import {
  Box,
  Grid,
  Container,
} from "@chakra-ui/react";
import { useDocumentTitle } from "../../utils/hooks";
import { Redirect } from "react-router-dom";
import { useAuthContext } from "../../config/context";
import ColorModeSwitcher from "../../components/ColorModeSwitcher";
import Logout from "../../components/Logout";

const SignOut: React.FC = () => {
    useDocumentTitle("Logged Out");

    const { isAuthenticated } = useAuthContext();

    if(!isAuthenticated) {
        return <Redirect to="/login" />;
    }

    return(
        <Box textAlign="center" fontSize="md">
            <Grid minH="100vh" p={3}>
                <ColorModeSwitcher justifySelf="flex-end" />
                <Container>
                    <Logout />
                </Container>
            </Grid>
        </Box>
    );
}

export default SignOut;