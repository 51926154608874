import * as React from "react"
import {
    Box,
    Container,
    Icon
} from "@chakra-ui/react";
import { Prompt, useLocation, useParams } from "react-router-dom";
import { MdAssignment } from "react-icons/md";
import { Assignment as FetchedAssignment } from "../../models/Assignment";
import { AssignmentProps, AssignmentStateProps, IUrlParams } from "./types";
import { useBeforeunload } from "react-beforeunload";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import { Chapter } from "../../models/Chapter";
import { pttpClient } from "../../clients/pttp";
import PageError from "../../components/PageError";
import AssignmentDetail from "../../components/AssignmentDetail";
import SkeletonLoad from "../../components/SkeletonLoad";

const Assignment: React.FC<AssignmentProps> = ({ user }) => {
    useDocumentTitle("View Assignment");

    const { id } = useParams<IUrlParams>();
    const location = useLocation();

    const [assignment, setAssignment] = React.useState<FetchedAssignment|null>();
    const [chapterNumber, setChapterNumber] = React.useState<number|null>(null);
    const [changesHaveBeenSaved, setChangesHaveBeenSaved] = React.useState<boolean>(false);

    const { 
        isFetching: getIsFetching, 
        isError: getIsError,
        isFetched: getIsFetched,
        data: getData,
        refetch: getRefetch
    }: UseQueryResult<FetchedAssignment|null, Error>  = useQuery<FetchedAssignment|null, Error>([`getAssignment${id}`], async () => {
        return pttpClient.getAssignment(user, id ?? "")
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        cacheTime: 3600000,
        staleTime: 3600000
    });

    const { 
        isFetching: getChapterIsFetching, 
        isError: getChapterIsError, 
        data: getChapterData,
        refetch: getChapterRefetch
    }: UseQueryResult<Chapter|null, Error>  = useQuery<Chapter|null, Error>([`getChapter${assignment?.chapterId}`], async () => {
        return pttpClient.getChapter(user, assignment?.chapterId ?? "")
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        cacheTime: 3600000,
        staleTime: 3600000
    });

    React.useEffect(() => {
        const stateChapterNumber = location.state ? (location.state as AssignmentStateProps).chapterNumber : null;
        
        if(!stateChapterNumber) {
            // Query the chapter
            if(assignment) {
                getChapterRefetch();
            }
        } else {
            setChapterNumber(stateChapterNumber);
        }
    }, [assignment, location.state]);

    React.useEffect(() => {
        if(getChapterData) {
            setChapterNumber(getChapterData.orderNumber);
        }
    }, [getChapterData]);

    useBeforeunload((event) => {
        if (!changesHaveBeenSaved) {
          event.preventDefault();
        }
    });

    React.useEffect(() => {
        if(id) {
            getRefetch();
        }
    }, [id]);

    React.useEffect(() => {
        setAssignment(getData);
    }, [getData]);

    if(getIsFetching || getChapterIsFetching || (assignment === undefined)) {
        return (
           <SkeletonLoad />
        );
    }

    if(getIsError || getChapterIsError) {
        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    const renderComponent = (): React.ReactElement => {
        // If the assignment is undefined, it hasn't been loaded yet. If it's null or an object, it's been loaded.
        // We only want to show the page error if the assignment has been fetched and not found.
        if(getIsFetched && assignment === null) {
            return <PageError message="The assignment you're looking for doesn't exist." display={<Icon as={MdAssignment} w={180} h={180} color="red.500" />} />;
        } else if(getIsFetched && assignment) {
            return <AssignmentDetail 
                        assignment={assignment} 
                        chapterNumber={chapterNumber} 
                        setChangesHaveBeenSaved={setChangesHaveBeenSaved} 
                        user={user}
                    />;
        }

        return(
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    return(
        <>
            <Prompt
                when={!changesHaveBeenSaved}
                message="You may have unsaved changes, are you sure you want to leave?"
            />
            <Box id="_progress" backgroundColor="red.500"></Box>
            <Box textAlign="center" fontSize="md" paddingTop="20px">
                <Container maxW="container.lg" textAlign="center">
                    {renderComponent()}
                </Container>
            </Box>
        </>
    );
}

export default Assignment;