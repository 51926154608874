import { useEffect, useState, useRef } from "react";
import { FetchState } from "../constants";

// Should be domain name in prod
const client = "";

/**
 * 
 * @param url Url of endpoint starting with "/" (do not include domain name)
 * @param returnCast Casts the return value as this data type
 * @returns An object with the state of the data fetch and the casted data
 * 
 * Calls a app-defined endpoint, caches the data, and checks against the cache
 * 
 * Example for how to call:
 * const { status, data } = useFetch(url);
 * 
 * Referenced from tutorial here: https://www.smashingmagazine.com/2020/07/custom-react-hook-fetch-cache-data/
 */
export const useFetch = (url: string, returnCast: any) => {
    const cache = useRef<{[key: string]: any}>({});
    const [status, setStatus] = useState<FetchState>(FetchState.IDLE);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!url) return;

        const fetchData = async () => {
            setStatus(FetchState.FETCHING);

            if (cache.current[url]) {
                const data = cache.current[url];

                setData(data);
                setStatus(FetchState.FETCHED);
            } else {
                const response = await fetch(url);
                const data = await response.json();

                cache.current[url] = data; // set response in cache;
                
                setData(data);
                setStatus(FetchState.FETCHED);
            }
        };

        fetchData();
    }, [url]);

    return { status, data: data as typeof returnCast };
};

/**
 * Sets document title for page
 * @param title Appends "- The Holistic Conductor" to the passed in string
 */
 export const useDocumentTitle = (title: string) => {
    const finalTitle = `${title} - The Holistic Conductor`;
    const [documentTitle, setDocumentTitle] = useState(finalTitle);

    useEffect(() => {
        document.title = documentTitle; 
    },[documentTitle]);
 
    return [documentTitle, setDocumentTitle];
 };

