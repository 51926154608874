import * as React from "react"
import {
    Container,
    Grid,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalFooter,
    ModalContent,
    ModalBody,
    useDisclosure,
    Heading,
    Divider,
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
    Checkbox,
    FormControl,
    FormHelperText
} from "@chakra-ui/react";
import { IChapter } from "../../models/Chapter";
import { EditChapterState } from "../../pages/editChapter/enums";
import { EditChapterDetailProps, IDynamicContentItem } from "./types";
import { serializeContent, serializeDynamicContent } from "./serializer";
import { AddIcon, ChevronDownIcon, CloseIcon, ViewIcon } from "@chakra-ui/icons";
import { generateRandomId, getUnixStringFromDateOrNull } from "../../utils";
import { ContentType } from "./enums";
import { Prompt } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import { TextInput } from "../Inputs";
import { BiSend } from "react-icons/bi";
import { FiSave } from "react-icons/fi";
import { IPostOutput } from "../../constants/types";
import { useQuery, UseQueryResult } from "react-query";
import { pttpClient } from "../../clients/pttp";
import DatePicker from "react-datepicker";
import ChapterContent from "../ChapterContent";
import ChapterDetail from "../ChapterDetail";

const EditChapterDetail: React.FC<EditChapterDetailProps> = ({ 
    chapter, 
    state, 
    setNotice,
    user,
    setAlertStatus
}) => {
    const newContentItem: IDynamicContentItem = {
        dynamicId: `${generateRandomId(5)}`,
        type: ContentType.CONTENT,
        value: ""
    };

    const tempReflectionsDueDate = new Date();

    const [title, setTitle] = React.useState<string>(chapter?.title ?? "");
    const [guestAuthors, setGuestAuthors] = React.useState<string>(chapter?.guestAuthors ? chapter?.guestAuthors.join(", ") : "");
    const [content, setContent] = React.useState<IDynamicContentItem[]>(serializeDynamicContent(chapter?.content) ?? [newContentItem]);
    const [titleIsInvalid, setTitleIsInvalid] = React.useState<boolean>(false);
    const [reflectionsDueDate, setReflectionsDueDate] = React.useState<Date|null>(chapter?.reflectionsDueDate ?? null);
    const [enableDueDateIsChecked, setEnableDueDateIsChecked] = React.useState<boolean>(chapter?.reflectionsDueDate ? true : false);
    const [contentIsInvalid, setContentIsInvalid] = React.useState<boolean>(false);
    const [changesHaveBeenSaved, setChangesHaveBeenSaved] = React.useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useBeforeunload((event) => {
        if (!changesHaveBeenSaved) {
          event.preventDefault();
        }
    });

    const { 
        isLoading: postIsLoading,
        refetch: postRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>(["updateChapter"], async () => {
        const chapterId = chapter?.id ?? "invalidChapter";

        return pttpClient.updateChapter(
            user, 
            chapterId, 
            state,
            {
                title,
                guestAuthors: guestAuthors =="" ? null : guestAuthors.split(", "),
                content: serializeContent(content),
                reflectionsDueDate: getUnixStringFromDateOrNull(reflectionsDueDate)
            }
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus("warning");
            setNotice("We're having trouble saving the chapter. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setAlertStatus("success");
            setNotice(`The chapter was successfully ${state == EditChapterState.EDIT ? "saved" : "added"}!`);
            window.scrollTo(0, 0);
        }
    });

    const inputAsChapter: IChapter = {
        id: "",
        orderNumber: chapter?.orderNumber ?? 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        title,
        guestAuthors: guestAuthors === "" ? null : guestAuthors.split(", "),
        content: serializeContent(content),
        reflectionsDueDate
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setNotice("");

        if(title === "") {
            setTitleIsInvalid(true);
        } else {
            setTitleIsInvalid(false);
        }

        if(content === [] && content[0].value === "") {
            setContentIsInvalid(true)
        } else {
            setContentIsInvalid(false);
        }

        if(title === "" || (content === [] && content[0].value === "")) {
            setAlertStatus("error");
            setNotice("Please fill out all of the required fields.");
            window.scrollTo(0, 0);
        } else {
            postRefetch();
        }

    }

    const addContentItem = (type: ContentType) => {
        const modifyableContentList = [...content ?? []];

        modifyableContentList.push({
            dynamicId: `${generateRandomId(5)}`,
            value: "",
            type
        });

        setContent(modifyableContentList);
    };

    const renderContent = (): React.ReactElement => {
        if(content.length === 0) {
            return(
                <Box padding="25px">
                    <Text as="i">Click the dropdown below to add your first content item.</Text>
                </Box>
            );
        }

        return(
            <ChapterContent content={content} setContent={setContent} />
        );
    };

    return(
        <>
            <Prompt
                when={!changesHaveBeenSaved}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            <form
                onSubmit={(event) => {
                    setChangesHaveBeenSaved(true);
                    handleSubmit(event);
                }}
            >
                <TextInput 
                    placeholder="Chapter Title"
                    title="Chapter Title"
                    isInvalid={titleIsInvalid}
                    value={title}
                    setValue={setTitle}
                    helperText="Required. The chapter's title goes here."
                />
                <TextInput 
                    placeholder="Chapter Guest Authors"
                    title="Chapter Guest Authors"
                    isInvalid={false}
                    value={guestAuthors}
                    setValue={setGuestAuthors}
                    helperText="Please list authors separated by a comma and a space. E.g. Author 1, Author 2"
                />
                <Container maxW="container.lg" padding="0" marginBottom="40px">  
                    <Box marginBottom=".5rem">
                        <Heading as="h2" size="md" textAlign="left">
                            Due Date for Reflection Questions
                        </Heading>
                    </Box>
                    <Divider />
                    <Box textAlign="left" marginTop="1rem">
                        <Checkbox 
                            onChange={(event) => {
                                const isChecked = event.target.checked;

                                if(!isChecked) {
                                    setReflectionsDueDate(null);
                                } else {
                                    setReflectionsDueDate(tempReflectionsDueDate)
                                }

                                setEnableDueDateIsChecked(isChecked);
                            }} 
                            defaultChecked={enableDueDateIsChecked}
                        >
                            Enable due date
                        </Checkbox>  
                    </Box>
                    <Box textAlign="left" marginTop="1rem">
                        {enableDueDateIsChecked && <FormControl>
                            <DatePicker
                                selected={reflectionsDueDate}
                                onChange={(date: Date) => {
                                    setReflectionsDueDate(date);
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                            />
                            <FormHelperText lineHeight={7}>Select a due date and time for reflection questions that should apply to this chapter. <br /><i>Note: This resets this chapter's due date for all active courses.</i></FormHelperText>
                        </FormControl>}
                    </Box>                    
                </Container>               
                <Heading as="h2" size="md" textAlign="left" marginBottom=".5rem">
                    Chapter Content and Reflection Questions
                </Heading>
                <Divider />
                {renderContent()}             
                <Flex flexDirection="column" alignItems="center" marginBottom={20}>
                    <Menu colorScheme="red">
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            Add Content Item
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => addContentItem(ContentType.CONTENT)} icon={<AddIcon />}>
                                Content Block
                            </MenuItem>
                            <MenuItem onClick={() => addContentItem(ContentType.REFLECTION_QUESTION)} icon={<AddIcon />}>
                                Reflection Question
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
                <Container maxWidth="container.lg" textAlign="left" padding="0">
                    <Button 
                        colorScheme="red" 
                        variant="outline" 
                        type="button" 
                        marginRight="10px" 
                        onClick={() => onOpen()}
                        aria-label="Preview Chapter"
                        leftIcon={<ViewIcon />}
                    >
                        Preview Chapter
                    </Button>
                    <Button 
                        colorScheme="red" 
                        type="submit"
                        isLoading={postIsLoading}
                        loadingText={state === EditChapterState.ADD ? "Adding Chapter" : "Saving Changes"}
                        leftIcon={state === EditChapterState.ADD ? <BiSend /> : <FiSave />}
                        aria-label={state === EditChapterState.ADD ? "Add Chapter" : "Save Changes"}
                    >
                        {state === EditChapterState.ADD ? "Add Chapter" : "Save Changes"}
                    </Button>
                </Container>
            </form>
            <Modal onClose={onClose} size="full" isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textAlign="right">
                        <Button onClick={onClose} colorScheme="red" title="Close Chapter Preview" leftIcon={<CloseIcon />}>Close Preview</Button>
                    </ModalHeader>
                    <ModalBody>
                        <Box textAlign="center" fontSize="md" paddingTop="20px">
                            <Grid minH="100vh" p={3} alignContent="flex-start">
                                <Container maxW="container.lg">
                                    <ChapterDetail 
                                        chapter={inputAsChapter} 
                                        savedReflectionAnswers={null} 
                                        submittedReflectionAnswers={null} 
                                        isDisabled={true} 
                                        user={user}
                                        content={content}
                                    />
                                </Container>
                            </Grid>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default EditChapterDetail;