import { Expose, Transform, Type } from "class-transformer";
import { getDateFromUnixString, getDateFromUnixStringOrNull } from "../utils";
import "reflect-metadata"; // Required

export interface ICourse {
    id: string;
    teachers: string[];
    title: string;
    crn: string;
    meetingTime?: Date;
    meetingDays?: string;
    startDate: Date;
    endDate: Date;
    createdAt: Date;
    updatedAt: Date;
    syncWithBlackboard: boolean;
    blackboardId?: string;
};

export class Course {

    @Expose() id: string;
    @Expose() teachers: string[];
    @Expose() title: string;
    @Expose() crn: string;
    @Expose() meetingDays?: string;
    @Expose() blackboardId?: string;
    @Expose() syncWithBlackboard?: boolean;

    @Transform((str) => getDateFromUnixStringOrNull(str.value))
    @Expose() meetingTime?: Date | null;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() startDate: Date;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() endDate: Date;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() createdAt: Date;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() updatedAt: Date;
};