import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
     Flex ,
     Box,
     Button
} from "@chakra-ui/react";
import React from "react";
import { scrollToRef } from "../../../utils";
import { ActionsButtonsProps } from "../types";

const ActionsButtons: React.FC<ActionsButtonsProps> = ({ question, addUnsavedQuestion, setUnsavedQuestion, handleDeleteQuestion, scrollRef }) => {
    return(
        <Flex flexDirection="column" style={{position: "absolute", top: "0", right: "0"}}>
            <Box>
                <Button 
                    marginLeft="5px" 
                    size="xs" 
                    colorScheme="green" 
                    variant="outline"
                    leftIcon={<EditIcon />} 
                    w="80px"
                    onClick={() => {
                        setUnsavedQuestion && setUnsavedQuestion(null);
                        addUnsavedQuestion && addUnsavedQuestion(question.questionType, question, question.dynamicId);
                        scrollToRef(scrollRef);
                    }}
                >
                    Edit
                </Button>
            </Box>
            <Box>
                <Button 
                    marginLeft="5px" 
                    size="xs" 
                    colorScheme="red" 
                    variant="outline" 
                    leftIcon={<DeleteIcon />} 
                    w="80px"
                    onClick={() => handleDeleteQuestion && handleDeleteQuestion(question.dynamicId)}
                >
                    Delete
                </Button>
            </Box>
        </Flex>
    );
};

export default ActionsButtons;