import { Expose, Transform, Type } from "class-transformer";
import "reflect-metadata"; // Required

export class User {
    @Expose({ name: "sub" })
    @Expose() id: string;
    @Expose() email: string;
    @Expose() jwtToken: string;
    @Expose({ name: "given_name" }) givenName: string;
    @Expose({ name: "family_name" }) familyName: string;
    @Expose({ name: "custom:courseId" }) courseId: string;
    @Expose({ name: "custom:blackboardUserId" }) blackboardUserId?: string;

    @Transform(({ value }) => value?.toLowerCase() === "true")
    @Expose({ name: "custom:isAdmin" }) isAdmin: boolean;
    @Type(() => Date)
    @Expose() createdAt: Date;
    @Type(() => Date)
    @Expose() updatedAt: Date;
}