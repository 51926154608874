import { ReflectionAnswer } from "../../models/ReflectionAnswer";
import { ContentType } from "../EditChapterDetail/enums";
import { IContentItem, IDynamicContentItem } from "../EditChapterDetail/types";

export const serializeDynamicContentWithAnswer = (
    content: IContentItem[] | undefined,
    answers: ReflectionAnswer[]
): IDynamicContentItem[] => {
    if(!content) {
        return [];
    }

    const dynamicContentItems: IDynamicContentItem[] = [];

    content.forEach((item) => {
        const relevantAnswers = answers.filter((a) => a.questionId === item.id);

        let answer = "";
        if(relevantAnswers.length > 0) {
            if(relevantAnswers[0].answer) {
                answer = relevantAnswers[0].answer;
            }
        }

        dynamicContentItems.push({
            dynamicId: item.id,
            type: item.type,
            value: item.value,
            answer
        });
    });

    return dynamicContentItems;
};

export const serializeReflectionAnswersInput = (items: IDynamicContentItem[]): ReflectionAnswer[] => {
    return items.filter((item) => item.type === ContentType.REFLECTION_QUESTION)
            .filter((item) => item.answer && item.answer !== "")
            .map((item) => {
                return {
                    questionId: item.dynamicId,
                    question: item.value,
                    answer: item.answer ?? ""
                };
            });
};