import * as React from "react"
import {
  Text,
  Link,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";

const Logout: React.FC = () => {
  
  return(
    <>
        <VStack spacing={6}>
            <LockIcon color="red.400" w={20} h={20}/>
            <Heading size="md">
                You have been logged out.
            </Heading>
            <Text>
                Return to the&nbsp;
                <Link
                    color="red.400"
                    rel="noopener noreferrer"
                    href="/login"
                >
                    home screen
                 </Link>
                 .
            </Text>
        </VStack>
    </>
  );
}

export default Logout;