import * as React from "react"
import {
  Box,
  Grid,
  Container,
  Alert,
  AlertIcon,
  Text,
  AlertStatus
} from "@chakra-ui/react";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import { ChapterMetadata } from "../../models/Chapter";
import { AssignmentSubmission } from "../../models/Assignment";
import { HomeProps } from "./types";
import { getRelevantAssignmentSubmissions, resetChapterOrderNumbers } from "../../utils";
import { IReorderChaptersInput, IPostOutput, IDeleteOutput } from "../../constants/types";
import { pttpClient } from "../../clients/pttp";
import PageError from "../../components/PageError";
import SkeletonLoad from "../../components/SkeletonLoad/";
import ChapterPreview from "../../components/ChapterPreview";
import ActionBar from "../../components/ActionBar";
import WelcomeTable from "../../components/WelcomeTable";

const Home: React.FC<HomeProps> = ({ user }) => {
    useDocumentTitle("View Chapters");

    const [chapters, setChapters] = React.useState<ChapterMetadata[]>([]);
    const [temporaryChapters, setTemporaryChapters] = React.useState<ChapterMetadata[]>([]);
    const [assignmentSubmissions, setAssignmentSubmissions] = React.useState<AssignmentSubmission[]>();
    const [notice, setNotice] = React.useState<string>("");
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("warning");
    const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
    const [adminLoading, setAdminLoading] = React.useState<boolean>(true);
    const [isReorderingChapters, setIsReorderingChapters] = React.useState<boolean>(false);
    const [chapterToDelete, setChapterToDelete] = React.useState("");
    const [assignmentToDelete, setAssignmentToDelete] = React.useState("");

    const { 
        isLoading: getIsLoading, 
        isError: getIsError, 
        data: getData 
    }: UseQueryResult<ChapterMetadata[], Error>  = useQuery<ChapterMetadata[], Error>(["getChaptersMetadata"], async () => {
        return pttpClient.getChaptersMetadata(user)
    }, {
        cacheTime: 43200000,
        staleTime: 43200000
    });

    const { 
        isLoading: getAssignmentSubmissionsForUserIsLoading, 
        data: getAssignmentSubmissionsForUserData,
        refetch: getAssignmentSubmissionsForUserRefetch
    }: UseQueryResult<AssignmentSubmission[], Error>  = useQuery<AssignmentSubmission[], Error>(["getAssignmentSubmissionsMetadataForUser"], async () => {
        return pttpClient.getAssignmentSubmissionsMetadataForUser(user)
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus("warning");
            setNotice("We're having trouble reordering retrieving your assignment submissions. Please reload the page to try again.");
            window.scrollTo(0, 0);
        }
    });

    const { 
        isLoading: postIsLoading, 
        isFetching: postIsFetching,
        refetch: postRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>(["reorderChapters"], async () => {
        return pttpClient.reorderChapters(user, buildReorderInput(temporaryChapters));
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus("warning");
            setNotice("We're having trouble reordering the chapters. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setAlertStatus("success");
            setNotice("The chapters were successfully reordered!");
            setIsReorderingChapters(false);
            window.scrollTo(0, 0);
        }
    });

    const { 
        refetch: deleteChapterRefetch
    }: UseQueryResult<IDeleteOutput, Error>  = useQuery<IDeleteOutput, Error>([`deleteChapter${chapterToDelete}`], async () => {
        return pttpClient.deleteChapter(user, chapterToDelete);
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus("warning");
            setNotice("We're having trouble deleting the chapter. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            removeChapter(chapterToDelete);
            setAlertStatus("success");
            setNotice(`The chapter with the ID of ${chapterToDelete} has been successfully deleted.`);
            window.scrollTo(0, 0);
        }
    });

    const { 
        refetch: deleteAssignmentRefetch
    }: UseQueryResult<IDeleteOutput, Error>  = useQuery<IDeleteOutput, Error>([`deleteAssignment${assignmentToDelete}`], async () => {
        return pttpClient.deleteAssignment(user, assignmentToDelete);
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus("warning");
            setNotice("We're having trouble deleting the assigment. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            removeChapter(chapterToDelete);
            setAlertStatus("success");
            setNotice(`The assignment with the ID of ${assignmentToDelete} has been successfully deleted.`);
            window.scrollTo(0, 0);
        }
    });

    const buildReorderInput = (chapters: ChapterMetadata[]): IReorderChaptersInput  => {
        const input: IReorderChaptersInput = {};

        for(let i = 0; i < chapters.length; i++) {
            input[chapters[i].id] = i + 1
        };

        return input;
    };

    React.useEffect(() => {
        setIsAdmin(user?.isAdmin ?? false);
        setAdminLoading(false);

        // We're setting state here for two variables because the user can reorder the chapters
        // If the user makes changes and does not save them, we need to have the original ordered list saved
        setChapters(getData ?? []);
        setTemporaryChapters(getData ?? []);        
    }, [user, getData]);

    React.useEffect(() => {
        if(user && !user.isAdmin) {
            getAssignmentSubmissionsForUserRefetch();
        }
    }, [user]);

    React.useEffect(() => {
        setAssignmentSubmissions(getAssignmentSubmissionsForUserData);
    }, [getAssignmentSubmissionsForUserData]);

    const handleCancelReorder = () => {
        setTemporaryChapters(chapters);
    };

    const removeChapter = async (id: string) => {
        // Find the index of the chapter in our list of chapters
        let indexOf = -1;
        temporaryChapters.forEach((chapter, index) => {
            if(chapter.id === id) {
                indexOf = index;
            }
        });

        // Remove the chapter
        const modifyableChapters = [...temporaryChapters ?? []];
        modifyableChapters.splice(indexOf, 1);
 
        // Reset the chapter order number and set the state
        const resetChapters = resetChapterOrderNumbers(modifyableChapters);
        setTemporaryChapters(resetChapters);

        // Call reorderChapters
        await pttpClient.reorderChapters(user, buildReorderInput(resetChapters));
    };

    const moveChapterUp = (index: number) => {
        const modifyableChapters = [...temporaryChapters ?? []];
    
        const constant = modifyableChapters[index];
        modifyableChapters[index] = modifyableChapters[index - 1];
        modifyableChapters[index - 1] = constant;
    
        setTemporaryChapters(modifyableChapters);
    };
    
    const moveChapterDown = (index: number) => {
        const modifyableChapters = [...temporaryChapters ?? []];
    
        const constant = modifyableChapters[index];
        modifyableChapters[index] = modifyableChapters[index + 1];
        modifyableChapters[index + 1] = constant;
    
        setTemporaryChapters(modifyableChapters);
    };

    const renderNotice = (): React.ReactElement => {
        return (
            <Container maxW="container.lg" justifySelf="center" marginBottom="30px">
                <Alert status={alertStatus} borderRadius="8px" textAlign="left">
                    <AlertIcon />
                    {notice}
                </Alert>
            </Container>
        )
    };

    const renderStudentWelcomeMessage = (): React.ReactElement => {
        return(
            <>
                <Text as="i" fontSize="md">Welcome to The Holistic Conductor. <br></br><br></br>This curriculum has been developed from the understanding that conductors are Artists, Teachers, and Leaders.  As people who lead others to make music in community, it is vital that we have a holistic understanding of our art, and the ways in which we bring our best selves to the teaching process.  With this goal in mind, the essays here invite you to consider both <i>why</i> and <i>how</i> you conduct, and ways you can continue to consider these questions over the course of your career.</Text><br /><br />
                <Text as="i" fontSize="md" display="">Cheers,</Text><br />
                <Text as="i" fontSize="md">Dr. Alicia W. Walker</Text>
            </>
        );
    };

    const renderAdminWelcomeMessage = (): React.ReactElement => {
        return(
            <>
                <Text as="i" fontSize="md">
                    Welcome to The Holistic Conductor online textbook! This is the admin view. 
                    Here, you can add and edit chapters, create and edit courses, and download student reflection question submissions. 
                </Text>
            </>
        );
    };

    if(getIsLoading) {
        return (
            <SkeletonLoad />
        );
    }

    if(getIsError) {
        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    return(
        <>
            <Box textAlign="center" fontSize="md" paddingTop="20px">
                <Grid minH="100vh" p={3} alignContent="flex-start">
                    {notice !== "" && renderNotice()}
                    <WelcomeTable 
                        name={user ? user.givenName[0].toUpperCase() + user.givenName.substring(1) : null} 
                        message={isAdmin ? renderAdminWelcomeMessage() : renderStudentWelcomeMessage()} 
                        isLoading={adminLoading}
                    />
                    {isAdmin && <ActionBar 
                        setNotice={setNotice}
                        setIsReorderingChapters={setIsReorderingChapters} 
                        isReorderingChapters={isReorderingChapters} 
                        isLoading={postIsLoading}
                        isFetching={postIsFetching}
                        handleReorder={postRefetch}
                        handleCancelReorder={handleCancelReorder} 
                    />}
                    <Container maxW="container.lg">
                        {temporaryChapters === [] && <Box height="100px" border="1px">No chapters have been uploaded. Click <a href="/add-chapter" >here</a> to add one.</Box>}
                        {temporaryChapters
                            .map((chapter, index) => {
                                const relevantAssignmentSubmissions = getRelevantAssignmentSubmissions(chapter.assignment, assignmentSubmissions ?? []);

                                return <ChapterPreview 
                                            chapter={chapter} 
                                            chaptersMetadata={chapters}
                                            assignment={chapter.assignment} 
                                            assignmentSubmissions={relevantAssignmentSubmissions}
                                            user={user} 
                                            key={chapter.id} 
                                            isReorderingChapters={isReorderingChapters}
                                            moveChapterUp={moveChapterUp}
                                            moveChapterDown={moveChapterDown}
                                            index={index}
                                            numOfChapters={temporaryChapters.length} 
                                            deleteChapterRefetch={deleteChapterRefetch}
                                            deleteAssignmentRefetch={deleteAssignmentRefetch}
                                            setChapterToDelete={setChapterToDelete}
                                            setAssignmentToDelete={setAssignmentToDelete}
                                            getAssignmentSubmissionsIsLoading={getAssignmentSubmissionsForUserIsLoading}
                                        />;
                            })
                        }
                    </Container>
                </Grid>
            </Box>
        </>
    );
}

export default Home;