import React from "react";
import { FormControl, Input } from "@chakra-ui/react";
import { InputWrapper } from "./InputWrapper";
import { TextInputProps } from "./types";

export const TextInput: React.FC<TextInputProps> = ({
    helperText,
    isInvalid,
    setValue,
    value,
    placeholder,
    title,
    isDisabled
}) => {
    const renderTextInput = () => {
        return(
            <FormControl>
                <Input 
                    placeholder={placeholder} 
                    size="sm"
                    borderRadius="8px"
                    padding="18px 0px 18px 12px"
                    onChange={(event) => setValue(event.target.value)}
                    borderColor="gray.300"
                    value={value}
                    isDisabled={isDisabled ?? false}
                    isInvalid={isInvalid}
                />
            </FormControl>
        );
    };

    return(
        <InputWrapper 
            child={renderTextInput()}
            helperText={helperText}
            title={title}
        />
    );
};