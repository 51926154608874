import React from "react";
import { 
    Box, 
    Container, 
    Divider, 
    FormControl, 
    FormHelperText, 
    Heading 
} from "@chakra-ui/react";
import { InputWrapperProps } from "./types";

export const InputWrapper: React.FC<InputWrapperProps> = ({
    child,
    title,
    helperText,
    display
}) => {
    const cssDisplay = display ?? 'block';

    return(
        <Container maxW="container.lg" padding="0" marginBottom="40px" textAlign="left" display={cssDisplay}>
            <Box marginBottom=".5rem">
                <Heading as="h2" size="md" textAlign="left">
                    {title}
                </Heading>
            </Box>
            <Divider />
            <Box marginTop="1rem">
                <FormControl>
                    {child}
                    {helperText && <FormHelperText textAlign="left">{helperText}</FormHelperText>}
                </FormControl>
            </Box>
        </Container>
    );
};