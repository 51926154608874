import { IDynamicQuestion } from "../../components/EditAssignmentQuestions/types";
import { IQuestion } from "../../models/Question";

export const serializeDynamicQuestions = (questions: IQuestion[]): IDynamicQuestion[] => {
    return questions.map((question) => {
        return {
            dynamicId: question.id,
            questionType: question.questionType,
            question: question.question,
            answerChoices: question.answerChoices
        }
    });
};