import React from "react";
import ReactQuill from "react-quill";
import { QuillProps } from "./types";
import "react-quill/dist/quill.snow.css";
import "./index.css";

const Quill: React.FC<QuillProps> = ({content, setContent, placeholder, toolbar, className}) => {
    const quillRef = React.useRef<ReactQuill>(null);

    const defaultToolbar = [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, { 'align': [] }],
        ['link', 'image', 'video']
    ];

    const finalToolbar = toolbar ? toolbar : defaultToolbar;

    const modules = {
        toolbar: finalToolbar
    };

    return(
        <ReactQuill className={className ?? "default-editor"} ref={quillRef} modules={modules} placeholder={placeholder ?? ""} theme="snow" value={content} onChange={setContent} />
    );
}

export default Quill;