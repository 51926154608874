import { plainToInstance } from "class-transformer";
import { User } from "../models/User";
import { getRequestHeaders } from "../utils";
import { Chapter, ChapterMetadata, IChapter, IChapterMetadata } from "../models/Chapter";
import { 
    IReorderChaptersInput, 
    IPostOutput, 
    ISaveChapterInput, 
    IDeleteOutput,
    IReflectionAnswersInput,
    ISubOnlyInput,
    ISaveAssignmentInput,
    IAssignmentSubmissionInput,
    IGetCoursesMetadataOutput,
    ISaveCourseInput,
    IGetUrlOutput,
    IGetByCourseIdInput
} from "../constants/types";
import { EditChapterState } from "../pages/editChapter/enums";
import axios, { AxiosError } from "axios";
import { IReflectionAnswer, ISubmission, ReflectionAnswer, Submission } from "../models/ReflectionAnswer";
import { setStateString } from "../constants/types";
import { AlertStatus } from "@chakra-ui/react";
import { EditAssignmentState } from "../pages/editAssignment/enums";
import { Assignment, AssignmentSubmission, IAssignmentSubmission } from "../models/Assignment";
import { Course, ICourse } from "../models/Course";
import { EditCourseState } from "../pages/editCourse/enums";
import { getPTTPEndpoint } from "./utils";

class Api {
    private readonly endpoint;

    public constructor() {
        this.endpoint = getPTTPEndpoint();
    }

    /**
     * 
     * Assignment
     * 
     */

     public async deleteAssignment(user: User | null, id: string): Promise<IDeleteOutput> {
        this.checkNullUser(user, "deleteAssignment");

        const response = await axios.post(`${this.endpoint}/assignment/${id}/delete`, {}, { headers: getRequestHeaders(user) });

        const statusCode: number = response.status;

        return {
            statusCode
        };
    };

     public async getAssignment(user: User | null, id: string): Promise<Assignment|null> {
        this.checkNullUser(user, "getAssignment");

        try {
            const response = await axios.get(`${this.endpoint}/assignment/${id}/get`, {
                headers: getRequestHeaders(user)
            });
            const data: IChapter = response.data;
    
            return plainToInstance(Assignment, data, { excludeExtraneousValues: true });
        } catch (error) {
           const err = error as AxiosError;

           if(err.response?.status === 404) {
               return null;
           }

           throw err;
        }
    };

    public async getAssignmentSubmissionsByCourse(
        user: User | null, 
        id: string,
        body: IGetByCourseIdInput | null
   ): Promise<string|null> {
       this.checkNullUser(user, "getAssignmentSubmissionsByCourse");

       try{
           const response = await axios.post(`${this.endpoint}/assignmentSubmissions/${id}/getAssignmentSubmissionsByCourse`, {
               ...body
           }, {
               headers: getRequestHeaders(user)
           });
   
           const data: IGetUrlOutput = response.data;
       
           return data.url;
       } catch(error) {
           const err = error as AxiosError;

           // TODO: Handle 404 separately
           throw err;
       }
   };

    public async getAssignmentSubmissionForUser(
        user: User | null, 
        id: string, 
        body: ISubOnlyInput | null,
        setNotice: setStateString,
        setAlertStatus: (status: AlertStatus) => void
   ): Promise<AssignmentSubmission|null> {
       this.checkNullUser(user, "getAssignmentSubmissionForUser");

       try{
           const response = await axios.post(`${this.endpoint}/assignmentSubmission/${id}/get`, {
               ...body
           }, {
               headers: getRequestHeaders(user)
           });
           const data: IAssignmentSubmission = response.data;
    
           return plainToInstance(AssignmentSubmission, data, { excludeExtraneousValues: true });
       } catch(error) {
           const err = error as AxiosError;

           if(err.response?.status !== 404) {
               setAlertStatus("warning");
               setNotice("We're having trouble retrieving an assignment submission you may have saved for this chapter.")

               throw err;
           }
           
           return null;
       }
   };

    public async getAssignmentSubmissionsMetadataForUser(user: User | null): Promise<AssignmentSubmission[]> {
        this.checkNullUser(user, "getAssignmentSubmissionsMetadataForUser");

        try {
            const response = await axios.get(`${this.endpoint}/assignmentSubmissionsMetadata/get`, {
                headers: getRequestHeaders(user)
            });
            const data: IAssignmentSubmission[] = response.data;
    
            return plainToInstance(AssignmentSubmission, data, { excludeExtraneousValues: true });
        } catch (error) {
           const err = error as AxiosError;

           throw err;
        }
    };

    public async submitAssignment(
        user: User | null, 
        id: string, 
        body: IAssignmentSubmissionInput | null
    ): Promise<IPostOutput> {
        this.checkNullUser(user, "submitAssignment");

        try{
            const response = await axios.post(`${this.endpoint}/assignmentSubmission/${id}/create`, {
                ...body
            }, {  headers: getRequestHeaders(user) });
            const statusCode: number = response.status;
    
            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

     public async updateAssignment(
        user: User | null, 
        id: string, 
        state: EditAssignmentState,
        body: ISaveAssignmentInput | null
    ): Promise<IPostOutput> {
        this.checkNullUser(user, "updateAssignment");

        const endpoint = state === EditAssignmentState.ADD ? `${this.endpoint}/assignment/create` : `${this.endpoint}/assignment/${id}/update`;
        
        try{
            const response = await axios.post(endpoint, {
                ...body
            }, {  headers: getRequestHeaders(user) });
            const statusCode: number = response.status;
    
            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    /**
     * 
     * Chapter
     * 
     */

    public async deleteChapter(user: User | null, id: string): Promise<IDeleteOutput> {
        this.checkNullUser(user, "deleteChapter");

        const response = await axios.post(`${this.endpoint}/chapter/${id}/delete`, {}, { headers: getRequestHeaders(user) });

        const statusCode: number = response.status;

        return {
            statusCode
        };
    };

    public async getChapter(user: User | null, id: string): Promise<Chapter|null> {
        this.checkNullUser(user, "getChapter");

        try {
            const response = await axios.get(`${this.endpoint}/chapter/${id}/get`, {
                headers: getRequestHeaders(user)
            });
            const data: IChapter = response.data;
    
            return plainToInstance(Chapter, data, { excludeExtraneousValues: true });
        } catch (error) {
           const err = error as AxiosError;

           if(err.response?.status === 404) {
               return null;
           }

           throw err;
        }
    };

    public async getChaptersMetadata(user: User | null): Promise<ChapterMetadata[]> {
        this.checkNullUser(user, "getChaptersMetadata");

        try{
            const response = await axios.get(`${this.endpoint}/chapters/get`, {
                headers: getRequestHeaders(user)
            });
    
            const data: IChapterMetadata[] = response.data;
        
            return data.map((a) => {
                return  plainToInstance(ChapterMetadata, a, { excludeExtraneousValues: true });
            })
            .sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1);
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    public async reorderChapters(user: User | null, body: IReorderChaptersInput): Promise<IPostOutput> {
        this.checkNullUser(user, "reorderChapters");

        try{
            const response = await axios.post(`${this.endpoint}/chapters/reorder`, {
                ...body
            }, {  headers: getRequestHeaders(user) });

            const statusCode: number = response.status;

            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    public async updateChapter(
        user: User | null, 
        id: string, 
        state: EditChapterState,
        body: ISaveChapterInput | null
    ): Promise<IPostOutput> {
        this.checkNullUser(user, "updateChapter");

        const endpoint = state === EditChapterState.ADD ? `${this.endpoint}/chapter/create` : `${this.endpoint}/chapter/${id}/update`;
        
        try{
            const response = await axios.post(endpoint, {
                ...body
            }, {  headers: getRequestHeaders(user) });
            const statusCode: number = response.status;
    
            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    /**
     * 
     * Courses
     * 
     */

     public async getCourse(user: User | null, id: string): Promise<Course|null> {
        this.checkNullUser(user, "getCourse");

        try {
            const response = await axios.get(`${this.endpoint}/course/${id}/get`, {
                headers: getRequestHeaders(user)
            });
            const data: ICourse = response.data;
    
            return plainToInstance(Course, data, { excludeExtraneousValues: true });
        } catch (error) {
           const err = error as AxiosError;

           if(err.response?.status === 404) {
               return null;
           }

           throw err;
        }
    };

     public async getCoursesMetadata(user: User | null): Promise<IGetCoursesMetadataOutput> {
        this.checkNullUser(user, "getCoursesMetadata");

        try{
            const response = await axios.get(`${this.endpoint}/courses/get`, {
                headers: getRequestHeaders(user)
            });
    
            const data: ICourse[] = response.data;
        
            const serializedData: Course[] = data.map((a) => {
                return  plainToInstance(Course, a, { excludeExtraneousValues: true });
            })
            .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);

            const activeCourses = serializedData.sort((a, b) => a.startDate > b.startDate ? 1 : -1).filter((course) => new Date().getTime() <= course.endDate.getTime());
            const expiredCourses = serializedData.sort((a, b) => a.startDate > b.startDate ? 1 : -1).filter((course) => new Date().getTime() > course.endDate.getTime());

            return {
                activeCourses,
                expiredCourses
            }
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    public async updateCourse(
        user: User | null, 
        id: string, 
        state: EditCourseState,
        body: ISaveCourseInput | null
    ): Promise<IPostOutput> {
        this.checkNullUser(user, "updateCourse");

        const endpoint = state === EditCourseState.ADD ? `${this.endpoint}/course/create` : `${this.endpoint}/course/${id}/update`;
        
        try{
            const response = await axios.post(endpoint, {
                ...body
            }, {  headers: getRequestHeaders(user) });
            const statusCode: number = response.status;
    
            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    /**
     * 
     * Reflections
     * 
     */

     public async getSavedReflectionAnswersForChapter(
         user: User | null, 
         id: string, 
         body: ISubOnlyInput | null,
         setNotice: setStateString,
         setAlertStatus: (status: AlertStatus) => void
    ): Promise<ReflectionAnswer[]> {
        this.checkNullUser(user, "getSavedReflectionAnswersForChapter");

        try{
            const response = await axios.post(`${this.endpoint}/reflections/${id}/getSaved`, {
                ...body
            }, {
                headers: getRequestHeaders(user)
            });
    
            const data: IReflectionAnswer[] = response.data;
        
            return data.map((a) => {
                return  plainToInstance(ReflectionAnswer, a, { excludeExtraneousValues: true });
            });
        } catch(error) {
            const err = error as AxiosError;

            if(err.response?.status !== 404) {
                setAlertStatus("warning");
                setNotice("We're having trouble retrieving reflection questions you may have saved for this chapter.")
            }
            return [];
        }
    };

    public async getSubmittedReflectionAnswersForChapter(
        user: User | null, 
        id: string, 
        body: ISubOnlyInput | null,
        setNotice: setStateString,
        setAlertStatus: (status: AlertStatus) => void
   ): Promise<Submission[]> {
       this.checkNullUser(user, "getSubmittedReflectionAnswersForChapter");

       try{
           const response = await axios.post(`${this.endpoint}/reflections/${id}/getSubmitted`, {
               ...body
           }, {
               headers: getRequestHeaders(user)
           });
   
           const data: ISubmission[] = response.data;
       
           return data.map((a) => {
               return  plainToInstance(Submission, a, { excludeExtraneousValues: true });
           });
       } catch(error) {
           const err = error as AxiosError;

           if(err.response?.status !== 404) {
               setAlertStatus("warning");
               setNotice("We're having trouble retrieving reflection questions you may have submitted for this chapter.")
           }
           return [];
       }
   };

     public async saveReflections(
        user: User | null, 
        id: string, 
        body: IReflectionAnswersInput | null
    ): Promise<IPostOutput> {
        this.checkNullUser(user, "saveReflections");

        try{
            const response = await axios.post(`${this.endpoint}/reflections/${id}/save`, {
                ...body
            }, {  headers: getRequestHeaders(user) });
            const statusCode: number = response.status;
    
            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    public async submitReflections(
        user: User | null, 
        id: string, 
        body: IReflectionAnswersInput | null
    ): Promise<IPostOutput> {
        this.checkNullUser(user, "submitReflections");

        try{
            const response = await axios.post(`${this.endpoint}/reflections/${id}/submit`, {
                ...body
            }, {  headers: getRequestHeaders(user) });
            const statusCode: number = response.status;
    
            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    public async deleteSavedReflections(
        user: User | null, 
        id: string,
        body: ISubOnlyInput | null
    ): Promise<IPostOutput> {
        this.checkNullUser(user, "deleteSavedReflections");

        try{
            const response = await axios.post(`${this.endpoint}/reflections/${id}/deleteSaved`,
                {
                    ...body
                },
                {  
                    headers: getRequestHeaders(user),
                }
            );
            const statusCode: number = response.status;
    
            return { statusCode };
        } catch(error) {
            const err = error as AxiosError;

            throw err;
        }
    };

    public async getChapterSubmittedReflectionsByCourse(
        user: User | null, 
        id: string,
        body: IGetByCourseIdInput | null
   ): Promise<string|null> {
       this.checkNullUser(user, "getChapterSubmittedReflectionsByCourse");

       try{
           const response = await axios.post(`${this.endpoint}/reflections/${id}/getChapterSubmittedByCourse`, {
               ...body
           }, {
               headers: getRequestHeaders(user)
           });
   
           const data: IGetUrlOutput = response.data;
       
           return data.url;
       } catch(error) {
           const err = error as AxiosError;

           // TODO: Handle 404 separately
           throw err;
       }
   };

    /**
     * 
     * Utils
     * 
     */

    public checkNullUser(user: User | null, origin: string): void {
        if(!user) {
            throw new Error(`[PTTPClient::${origin}] Unable to fetch data because user is null.`)
        }
    }
}

export const pttpClient = new Api();