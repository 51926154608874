import React from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { 
    Box, 
    Button, 
    Container, 
    Flex, 
    Grid, 
    Heading, 
    Modal, 
    ModalBody, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    Spacer,
    Text
} from "@chakra-ui/react";
import { ContentType } from "../../EditChapterDetail/enums";
import { ReflectionSetProps } from "./types";

const ReflectionSubmissionModal: React.FC<ReflectionSetProps> = ({content, body, isOpen, onClose}) => {
    return(
        <Modal onClose={onClose} size="full" isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box textAlign="right" flexBasis="50%">
                        <Button onClick={onClose} colorScheme="red" title="Close Modal" leftIcon={<CloseIcon />}>Close</Button>
                    </Box>
                </ModalHeader>
                <ModalBody>
                    <Box textAlign="center" fontSize="md" paddingTop="20px">
                        <Grid minH="100vh" p={3} alignContent="flex-start">
                            <Container maxW="container.lg">
                                <Box textAlign="center" flexBasis="50%" marginBottom="48px">
                                    <Heading as="h3" fontSize="1.3rem">Reflection Submission</Heading>
                                </Box>
                                {body.map((item, index) => {
                                    const reflectionQuestions = content.filter((content) => content.type === ContentType.REFLECTION_QUESTION);

                                    return reflectionQuestions.map((question) => {
                                        if(item.questionId === question.id) {
                                            return(
                                                <Box key={index} textAlign="left">
                                                    <Text
                                                        as="b"
                                                        lineHeight={9}
                                                    >
                                                        {question.value}
                                                    </Text>
                                                    <Text
                                                        lineHeight={9}
                                                    >
                                                        {item.answer}
                                                    </Text>
                                                    <Spacer h={4} />
                                                </Box>
                                            ); 
                                        }
                                    });
                                })}
                            </Container>
                        </Grid>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ReflectionSubmissionModal;