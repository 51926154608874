import React from "react";
import {
    Container,
    Text,
    Box,
    Flex,
    Tooltip,
    IconButton,
    FormControl,
    Input,
    FormHelperText,
    Select
} from "@chakra-ui/react";
import { EditTrueFalseQuestionProps } from "../types";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { QuestionType } from "../../../models/Question";
import { generateRandomId } from "../../../utils";

const EditTrueFalseQuestion: React.FC<EditTrueFalseQuestionProps> = ({question, answer, setUnsavedQuestion, handleAddQuestion, dynamicId}) => {
    const [dynamicAnswer, setDynamicAnswer] = React.useState<string>(answer ?? "Select answer");
    const [dynamicQuestion, setDynamicQuestion] = React.useState<string>(question ?? "");

    return(
        <Container padding="0px 20px 50px 20px" maxW="container.lg">
            <Flex>
                <Box w="12px" bg="red.100"></Box>
                <Box flex="1" marginLeft="10px">
                    <Text as="b">True/False Question:</Text>
                    <Flex flexBasis="100%" paddingTop="10px">
                        <Box flex="18" marginRight="10px">
                            <FormControl>
                                <Input placeholder="What would you like to ask?" value={dynamicQuestion} onChange={(event) => setDynamicQuestion(event.target.value)} />
                                <FormHelperText textAlign="left">Question</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box flex="6" marginRight="10px">
                            <FormControl>
                            <Select 
                                placeholder="Select answer" 
                                onChange={(event) => setDynamicAnswer(event.target.value)}
                                defaultValue={dynamicAnswer}
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Select>
                                <FormHelperText textAlign="left">Answer</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box flex="1" textAlign="right" marginRight="10px">
                            <Tooltip hasArrow label="Stop Editing Without Saving" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                                <IconButton 
                                    aria-label="delete-question" 
                                    size="md" 
                                    colorScheme="red" 
                                    icon={<CloseIcon w={3} h={3} />} 
                                    onClick={() => setUnsavedQuestion(null)}
                                />
                            </Tooltip>
                        </Box>
                        <Box flex="1" textAlign="right">
                            <Tooltip hasArrow label="Add Question" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                                <IconButton 
                                    aria-label="add-question" 
                                    size="md" 
                                    colorScheme="green" 
                                    icon={<CheckIcon w={3} h={3} />} 
                                    onClick={() => handleAddQuestion({
                                        dynamicId: dynamicId ?? `dynamicId_${generateRandomId(5)}`,
                                        questionType: QuestionType.TRUE_FALSE,
                                        question: dynamicQuestion,
                                        answer: dynamicAnswer
                                    }, dynamicId ? true : false)}
                                />
                            </Tooltip>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Container>
    );
};

export default EditTrueFalseQuestion;