import * as React from "react"
import {
    Spacer,
    Text,
    Link,
    Icon
} from "@chakra-ui/react";
import { PageErrorProps } from "./types";
import { BiCommentError } from "react-icons/bi";

const PageError: React.FC<PageErrorProps> = ({ message, display }) => {

    const renderHomeMessage = (): React.ReactElement => {
        return(
            <>
                &nbsp;Click&nbsp;
                <Link
                    color="red.400"
                    rel="noopener noreferrer"
                    href="/"
                >
                    here&nbsp;
                </Link>
                to return home.
            </>
        );
    };

    const renderComponent = (message: string, display: JSX.Element, isError?: boolean): React.ReactElement => {
        const isAnError = isError ?? false;

        return(
            <>
                <Spacer h={10} />
                <Text>
                    {message}
                    {!isAnError && renderHomeMessage()}
                </Text>
                <Spacer h={20} />
                {display}
            </>
        );
    };

    if((!message) && (!display)) {
        return renderComponent(
            "An unexpected error occured while processing your request. Please try again.",
            <Icon as={BiCommentError} w={180} h={180} color="red.500" />,
            true
        );
    }

    if(!display && message) {
        return renderComponent(
            message ?? "",
            <Icon as={BiCommentError} w={180} h={180} color="red.500" />
        );
    }

    if(!message && display) {
        return renderComponent(
            "An unexpected error occured while processing your request. Please try again.",
            display ?? <></>
        );
    }

    return renderComponent(
        message ?? "",
        display ?? <></>
    );
}

export default PageError;