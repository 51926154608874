import * as React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from "@chakra-ui/react";
import { ModalProps } from "./types";
import DOMPurify from "dompurify";

const DefaultModal: React.FC<ModalProps> = ({ isOpen, onClose, body, actionButtonLabel, action, header }) => {
  
    return(
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text align="left" fontSize="lg" lineHeight="9" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(body)}}></Text>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={action ? action : onClose}>
                    {actionButtonLabel}
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default DefaultModal;