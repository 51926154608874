import * as React from "react"
import {
    Box,
    Grid,
    Container,
    Alert,
    AlertIcon,
    Icon,
    Heading,
    AlertStatus
} from "@chakra-ui/react";
import { useParams, useLocation } from "react-router-dom";
import { BsBookHalf } from "react-icons/bs";
import { IUrlParams } from "../chapter/types";
import { Course } from "../../models/Course";
import { EditCourseState } from "./enums";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import { pttpClient } from "../../clients/pttp";
import { EditCourseProps } from "./types";
import { CourseStateProps } from "../course/types";
import PageError from "../../components/PageError";
import SkeletonLoad from "../../components/SkeletonLoad";
import EditCourseDetail from "../../components/EditCourseDetail";

const EditCourse: React.FC<EditCourseProps> = ({ user }) => {
    useDocumentTitle("Edit Course");

    const { id } = useParams<IUrlParams>();
    const location = useLocation();
    
    const [course, setCourse] = React.useState<Course|null>();
    const [notice, setNotice] = React.useState<string>("");
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("warning");
    const [editCourseState, setEditCourseState] = React.useState<EditCourseState>(EditCourseState.EDIT);

    const { 
        isFetching: getIsFetching, 
        isFetched: getIsFetched, 
        data: getData,
        refetch: getRefetch
    }: UseQueryResult<Course|null, Error>  = useQuery<Course|null, Error>([`getCourse${id}`], async () => {
        return pttpClient.getCourse(user, id ?? "")
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        cacheTime: 3600000,
        staleTime: 3600000
    });

    React.useEffect(() => {
        const stateCourse = location.state ? (location.state as CourseStateProps).course : null;

        if(location.pathname.endsWith("/add/course/") || location.pathname.endsWith("/add/course")) {
            setEditCourseState(EditCourseState.ADD);
        } else {
            if(!stateCourse) {
                if(id !== undefined) {
                    getRefetch();
                }
            } else {
                setCourse(stateCourse);
            }
        }
    }, [id, location.pathname]);

    React.useEffect(() => {
        if(getData) {
            setCourse(getData);
        }
    }, [getData]);


    if(getIsFetching || (editCourseState === EditCourseState.EDIT && course === undefined)) {
        return (
            <SkeletonLoad />
        );
    }

    const renderHeader = () => {
        return <Heading marginBottom={10}>{editCourseState === EditCourseState.EDIT ? "Edit" : "Add"} Course</Heading>
    };

    const renderComponent = (): React.ReactElement => {
        if(editCourseState === EditCourseState.EDIT) {
            // If the course is undefined, it hasn't been loaded yet. If it's null or an object, it's been loaded.
            // We only want to show the page error if the course has been fetched and not found.
            if(getIsFetched && (course === null || (course && course.endDate.getTime() < new Date().getTime()))) {
                return <PageError message="This course doesn't exist." display={<Icon as={BsBookHalf} w={180} h={180} color="red.500" />} />;
            } else if(course) {
                return (
                    <>  
                        {renderHeader()}
                        <EditCourseDetail 
                            state={editCourseState} 
                            course={course} 
                            setNotice={setNotice} 
                            user={user} 
                            setAlertStatus={setAlertStatus}
                        />
                    </>
                );
            }
        }

        return (
            <>
                {renderHeader()}
                <EditCourseDetail 
                    state={EditCourseState.ADD} 
                    setNotice={setNotice} 
                    setAlertStatus={setAlertStatus}
                    user={user} 
                />
            </>
        );
    }

    return(
        <>
            <Box id="_progress" backgroundColor="red.500"></Box>
            <Box textAlign="center" fontSize="md" paddingTop="20px">
                <Grid minH="100vh" p={{ sm: 0, lg: 3}} alignContent="flex-start">
                {
                    notice !== "" &&
                    <Container maxW="container.lg"  marginBottom="40px">
                        <Alert status={alertStatus} borderRadius="8px" textAlign="left" justifySelf="center">
                            <AlertIcon />
                            {notice}
                        </Alert>
                    </Container>
                }
                    <Container maxW="container.lg">
                        {renderComponent()}
                    </Container>
                </Grid>
            </Box>
        </>
    );
}

export default EditCourse;