import React from "react";
import {
  Container
} from "@chakra-ui/react";
import { WrapperProps } from "./types";
import { adminPages } from "../../constants";
import { useAuthContext } from "../../config/context";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";
import SkeletonLoad from "../../components/SkeletonLoad";
import Footer from "../../components/Footer";
import NotFound from "../404";

const Wrapper: React.FC<WrapperProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const { currentUser, shouldShowChangePassword } = useAuthContext();

    React.useEffect(() => {
        setIsLoading(false);
    }, []);

    if(currentUser && (!currentUser.isAdmin && adminPages.includes(props.component))) {
        return <NotFound />;
    } 

    if(shouldShowChangePassword) {
        return (
            <Redirect to={{
                pathname: '/changepassword',
                state: { 
                        email: currentUser?.email ?? "",
                        forgotPassword: false
                    }
                }}
            />
        );
    }

    return(
        <Container 
            maxW="container.lg" 
            paddingTop="20px" 
            maxH="100vh" 
            textAlign="center"
        >
                <Header user={currentUser} />
                {(isLoading) ? <SkeletonLoad /> : <props.component user={currentUser} />}
            <Footer />
        </Container>
    );
}

export default Wrapper;