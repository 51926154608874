import * as React from "react"
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Input,
  Text,
  Link,
  Button,
  InputGroup,
  Heading,
  InputRightElement,
  VStack,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormHelperText,
  Spacer
} from "@chakra-ui/react";
import { UnlockIcon } from "@chakra-ui/icons";
import { LoginProps } from "./types";
import { useHistory } from "react-router-dom";
import { cognito } from "../../clients/cognito";
import DOMPurify from "dompurify";

const Login: React.FC<LoginProps> = ({ error, setEmail, setPassword, handleSubmit, isLoading }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [resetEmail, setResetEmail] = React.useState<string>("");
    const [resetEmailIsInvalid, setResetEmailIsInvalid] = React.useState<boolean>();
    const [inputError, setInputError] = React.useState<string>("");

    const handleShowPassword = () => setShowPassword(!showPassword);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const modalHeader = "Forgot password?";
    const modalBody = "Please enter the email associated with your account and we'll send you a verifacation code that will allow you to reset your password.";
    const modalActionButtonLabel = "Reset Password";

    const history = useHistory();
    const modalAction = () => history.push(
        "/changepassword",
        {
            email: resetEmail,
            forgotPassword: true
        }
    );

    const action = async () => {
        if(resetEmail === "") {
            setResetEmailIsInvalid(true);
        } else {
            try {
                await cognito.initiatePasswordReset(resetEmail, setInputError);
            } catch(error: any) {
                setResetEmailIsInvalid(true);
            }
            setResetEmailIsInvalid(false);

            onClose();
            modalAction();
        }
    };
  
    return(
        <>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <VStack spacing={8}>
                    <Heading size="lg">
                        Welcome to The Holistic Conductor online textbook!
                    </Heading>
                    <Text>
                        Please sign in using your school email and password.
                    </Text>
                    {
                        error !== "" &&
                        <Alert status="error" borderRadius="8px" textAlign="left">
                            <AlertIcon />
                            {error}
                        </Alert>
                    }
                    <Input 
                        placeholder="Email" 
                        size="md" 
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <InputGroup size="md">
                        <Input
                            pr="4.5rem"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter password"
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                            <Button
                                 h="1.75rem" 
                                 size="sm" 
                                 colorScheme="red" 
                                 variant="ghost" 
                                 onClick={handleShowPassword}
                                 aria-label={showPassword ? "Hide password" : "Show password"}
                            >
                                {showPassword ? "Hide" : "Show"}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Button 
                        leftIcon={<UnlockIcon />} 
                        colorScheme="red"  
                        width="100%" 
                        type="submit"
                        aria-label="Sign in"
                        isLoading={isLoading}
                        loadingText="Signing in..."
                    >
                        Sign in
                    </Button>
                    <Link
                        color="red.400"
                        fontSize="sm"
                        rel="noopener noreferrer"
                        onClick={onOpen}
                    >
                        Forgot password?
                    </Link>
                </VStack>
            </form>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{modalHeader}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text align="left" fontSize="lg" lineHeight="9" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(modalBody)}}></Text>
                        <Spacer h={5} />
                        <FormControl>
                            <Input
                                value={resetEmail}
                                pr="4.5rem"
                                type={"text"}
                                placeholder="Enter email address"
                                onChange={(event) => setResetEmail(event.target.value)}
                                isInvalid={resetEmailIsInvalid}
                            />
                            <FormHelperText textAlign="left" color={resetEmailIsInvalid ? "red.500" : "gray.400"}>
                                {resetEmailIsInvalid ? "You must enter a valid email address." : "Your email address"}
                            </FormHelperText>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={action}>
                            {modalActionButtonLabel}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Login;