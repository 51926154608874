import React from "react";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { InputWrapper } from "./InputWrapper";
import { SwitcherProps } from "./types";

export const Switcher: React.FC<SwitcherProps> = ({
    helperText,
    id,
    isChecked,
    setIsChecked,
    title,
    display
}) => {
    const handleSwitch = () => {
        if(isChecked) {
            setIsChecked(false);
        } else {
            setIsChecked(true);
        }
    };

    const renderSwitcher = () => {
        return(
            <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor={id} mb="0">
                    Do you want to sync this assignment with the student's Blackboard gradebook?
                </FormLabel>
                <Switch id={id} colorScheme="red" isChecked={isChecked} onChange={() => handleSwitch()}/>
            </FormControl>
        );
    };

    return(
        <InputWrapper 
            child={renderSwitcher()}
            helperText={helperText}
            title={title}
            display={display}
        />
    );
};