import { Expose, Transform, Type } from "class-transformer";
import { getDateFromUnixString } from "../utils";
import "reflect-metadata"; // Required

export interface IReflectionAnswer {
    questionId: string;
    question: string;
    answer: string;
};

export class ReflectionAnswer {
    @Expose() questionId: string;
    @Expose() question: string;
    @Expose() answer: string;
};

export class Submission {
    @Expose() id: string;
    @Expose() chapterId: string;

    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() createdAt: Date;
    
    @Type(() => ReflectionAnswer)
    @Expose() reflectionAnswers: ReflectionAnswer[];
}

export interface ISubmission {
    chapterId: string;
    reflectionAnswers: IReflectionAnswer[];
    createdAt: Date;
};