import * as React from "react"
import {
  Text,
  Link,
  Spacer,
  Box,
  Grid,
  Container,
  Heading,
  Center,
  Icon
} from "@chakra-ui/react";
import { BiMessageSquareError } from "react-icons/bi";
import { useDocumentTitle } from "../utils/hooks";

const NotFound: React.FC = () => {
    useDocumentTitle("Page Not Found");

    return(
        <>
            <Box textAlign="center" fontSize="md">
                <Grid minH="100vh">
                    <Center>
                        <Container>
                                <Icon as={BiMessageSquareError} w={32} h={32} color="gray.600" marginBottom={5} />
                                <Heading color="red.500">404 - Page Not Found!</Heading>
                                <Spacer h={6} />
                                <Text lineHeight={8}>
                                    Sorry, but we couldn't find that page. <br /> Click&nbsp;
                                    <Link
                                        color="red.400"
                                        rel="noopener noreferrer"
                                        href="/"
                                    >
                                        here&nbsp;
                                    </Link>
                                    to return to the home page.
                                </Text>
                        </Container>
                    </Center>
            </Grid>
        </Box>
    </>
    );
}

export default NotFound;