import React, { ReactElement } from "react";
import {
    Button,
    Flex,
    Box,
    Text
} from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons";
import { QuestionType } from "../../models/Question";
import { EditAssignmentQuestionsProps, IDynamicQuestion } from "./types";
import { EditTrueFalseQuestion, EditMultipleChoiceQuestion, EditShortAnswerQuestion } from "./";
import { MultipleChoiceQuestionDetail, ShortAnswerQuestionDetail, TrueFalseQuestionDetail } from "../AssignmentQuestionsDetail";

const EditAssignmentQuestions: React.FC<EditAssignmentQuestionsProps> = ({dynamicQuestions}) => {

    const [questionsList, setQuestionsList] = React.useState<IDynamicQuestion[] | null>(null);
    const [unsavedQuestion, setUnsavedQuestion] = React.useState<ReactElement | null>(null);
    const scrollRef = React.useRef(null);

    const handleAddQuestion = (question: IDynamicQuestion, isEditing: boolean) => {
        const modifyableQuestionsList = [...questionsList ?? []];

        if(isEditing) {
            modifyableQuestionsList.forEach((modifyableQuestion) => {
                if(modifyableQuestion.dynamicId === question.dynamicId) {
                    modifyableQuestion.question = question.question;
                    modifyableQuestion.answer = question.answer;
                    modifyableQuestion.answerChoices = question.answerChoices;
                }
            });
        } else {
            modifyableQuestionsList.push(question);
        }

        setQuestionsList(modifyableQuestionsList);
        setUnsavedQuestion(null);
    }

    const handleDeleteQuestion = (dynamicId: string) => {
        const modifyableQuestionsList = [...questionsList ?? []];

        for(let i = 0; i < modifyableQuestionsList.length; i++) {
            if(modifyableQuestionsList[i].dynamicId === dynamicId) {
                modifyableQuestionsList.splice(i, 1);
            }
        }

        setQuestionsList(modifyableQuestionsList);
    };

    React.useEffect(() => {
        setQuestionsList(dynamicQuestions ?? []);
    }, [dynamicQuestions]);

    const renderQuestion = (question: IDynamicQuestion, number: number) => {
        switch(question.questionType) {
            case QuestionType.TRUE_FALSE:
                return <TrueFalseQuestionDetail 
                            question={question} 
                            number={number} 
                            key={number} 
                            setUnsavedQuestion={setUnsavedQuestion}
                            addUnsavedQuestion={addUnsavedQuestion}
                            handleDeleteQuestion={handleDeleteQuestion}
                            scrollRef={scrollRef}
                            isEditing 
                        />;
            case QuestionType.MULTIPLE_CHOICE:
                return <MultipleChoiceQuestionDetail
                            question={question} 
                            number={number} 
                            key={number} 
                            setUnsavedQuestion={setUnsavedQuestion}
                            addUnsavedQuestion={addUnsavedQuestion}
                            handleDeleteQuestion={handleDeleteQuestion}
                            scrollRef={scrollRef}
                            isEditing 
                        />;
            case QuestionType.SHORT_ANSWER:
                return <ShortAnswerQuestionDetail
                            question={question} 
                            number={number} 
                            key={number} 
                            setUnsavedQuestion={setUnsavedQuestion}
                            addUnsavedQuestion={addUnsavedQuestion}
                            handleDeleteQuestion={handleDeleteQuestion}
                            scrollRef={scrollRef}
                            isEditing 
                        />;
            default:
                return <Text>Cannot determine question type.</Text>;
        }
    }

    const renderQuestionsPreviewList = () => {
        if(questionsList) {
            let number = 0;
            return questionsList.map((question) => {
                number++;
                return renderQuestion(question, number);
            })
        }

        return (
            <Text size="md" paddingBottom="30px">No questions have been added yet.</Text>
        )
    };

    const renderUnsavedQuestion = () => {
        if(unsavedQuestion) {
            return unsavedQuestion;
        }

        return <></>;
    }

    // TODO: Pull in questions and question answers for each assignment and parse a questionUnit for each unsaved question
    const addUnsavedQuestion = (type: QuestionType, question?: IDynamicQuestion, dynamicId?: string) => {
        switch(type) {
            case QuestionType.TRUE_FALSE:
                setUnsavedQuestion(<EditTrueFalseQuestion 
                    setUnsavedQuestion={setUnsavedQuestion} 
                    question={question?.question} 
                    answer={question?.answer}
                    handleAddQuestion={handleAddQuestion}
                    dynamicId={dynamicId}
                />);
                break;
            case QuestionType.MULTIPLE_CHOICE:
                setUnsavedQuestion(<EditMultipleChoiceQuestion 
                    setUnsavedQuestion={setUnsavedQuestion} 
                    question={question?.question} 
                    answer={question?.answer}
                    savedAnswerChoices={question?.answerChoices}
                    handleAddQuestion={handleAddQuestion}
                    dynamicId={dynamicId}
                />);
                break;
            case QuestionType.SHORT_ANSWER:
                setUnsavedQuestion(<EditShortAnswerQuestion
                    setUnsavedQuestion={setUnsavedQuestion}
                    question={question?.question}
                    handleAddQuestion={handleAddQuestion}
                    dynamicId={dynamicId}
                />);
                break;
            default:
                setUnsavedQuestion(null);
                break;

        }
    }

    return(
        <>
            <Flex flexDir="column">
                {questionsList === [] ? <Box><Text>No assignment questions have been added.</Text></Box> : renderQuestionsPreviewList()}
            </Flex>
            <Flex paddingTop="30px" ref={scrollRef}>
                {renderUnsavedQuestion()}
            </Flex>
            <Flex className="add-question-button-container" flexDirection="column" textAlign="center">
                <Box
                    marginLeft="5px" 
                    marginBottom="18px"
                >
                    Add question from type:            
                </Box>
                <Box>
                    <Button 
                        marginLeft="5px" 
                        marginBottom="8px"
                        size="sm" 
                        colorScheme="red" 
                        variant="outline" 
                        leftIcon={<AddIcon />} 
                        onClick={() => addUnsavedQuestion(QuestionType.TRUE_FALSE)}
                    >
                        True/False
                    </Button>
                </Box>
                <Box>
                    <Button 
                        marginLeft="5px" 
                        marginBottom="8px"
                        size="sm" 
                        colorScheme="red" 
                        variant="outline" 
                        leftIcon={<AddIcon />} 
                        onClick={() => addUnsavedQuestion(QuestionType.MULTIPLE_CHOICE)}
                    >
                        Multiple Choice
                    </Button>
                </Box>
                <Box>
                    <Button 
                        marginLeft="5px" 
                        marginBottom="8px"
                        size="sm" 
                        colorScheme="red" 
                        variant="outline" 
                        leftIcon={<AddIcon />} 
                        onClick={() => addUnsavedQuestion(QuestionType.SHORT_ANSWER)}
                    >
                        Short Answer
                    </Button>
                </Box>
            </Flex>
        </>
    );
}

export default EditAssignmentQuestions;