import { Box, Container, Grid, SkeletonText } from "@chakra-ui/react";
import React from "react";

const SkeletonLoad: React.FC = () => {
    return(
        <Box textAlign="center" fontSize="md" paddingTop="20px">
            <Grid minH="100vh" p={3} alignContent="flex-start">
                <Container maxW="container.lg">
                    <SkeletonText mt='6' noOfLines={10} spacing='4' />   
                </Container>
            </Grid>
        </Box>
    );
};

export default SkeletonLoad;