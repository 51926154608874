import * as React from "react"
import {
  Input,
  Text,
  Button,
  InputGroup,
  Heading,
  InputRightElement,
  VStack,
  Alert,
  AlertIcon,
  LinkBox,
  LinkOverlay,
  FormHelperText,
  FormControl
} from "@chakra-ui/react";
import { ChangePasswordProps } from "./types";

const ChangePassword: React.FC<ChangePasswordProps> = ({ 
    notice, 
    handleSubmit, 
    setNewPassword, 
    setRepeatPassword,
    email,
    setResetEmail,
    setCode,
    alertStatus,
    forgotPassword=false
}) => {

    const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = React.useState<boolean>(false);

    const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleShowRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);

    const renderForgotPasswordInputs = () => {
        return(
            <>
                <FormControl>
                        <Input
                            pr="4.5rem"
                            type={"text"}
                            placeholder="Enter email"
                            value={email}
                            onChange={(event) => {
                                setResetEmail && setResetEmail(event.target.value);
                            }}
                        />
                        <FormHelperText textAlign="left">Your email</FormHelperText>
                </FormControl>
                <FormControl>
                    <InputGroup size="md">
                        <Input
                            pr="4.5rem"
                            type={"text"}
                            placeholder="Enter verification code"
                            onChange={(event) => {
                                setCode && setCode(event.target.value);
                            }}
                        />
                    </InputGroup>   
                    <FormHelperText textAlign="left">The verification code you received via email</FormHelperText>
                </FormControl>   
            </>
        );
    };

    return(
        <>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <VStack spacing={8} textAlign="left">
                    <Heading size="lg">
                        Reset Password
                    </Heading>
                    <Text alignSelf="flex-start">
                        Please complete the following fields to reset your password {forgotPassword && <>using the verification code sent to your email address. Please note that the the email containing the code will come from <b>no-reply@verificationemail.com</b>. You may need to check your spam</>}.
                    </Text>
                    {
                        notice !== "" &&
                        <LinkBox as="article" rounded="md" width="100%">
                            <Alert status={alertStatus} borderRadius="8px" textAlign="left">
                                <AlertIcon />
                                    <LinkOverlay href={alertStatus === "success" ? "/" : "#"}>
                                        {notice}
                                    </LinkOverlay>
                            </Alert>
                        </LinkBox>
                    }
                    {forgotPassword && renderForgotPasswordInputs()}
                    <FormControl>
                        <InputGroup size="md">
                            <Input
                                pr="4.5rem"
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Enter new password"
                                onChange={(event) => setNewPassword(event.target.value)}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" colorScheme="red" variant="ghost" onClick={handleShowNewPassword}>
                                    {showNewPassword ? "Hide" : "Show"}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormHelperText textAlign="left">Your new password</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputGroup size="md">
                            <Input
                                pr="4.5rem"
                                type={showRepeatPassword ? "text" : "password"}
                                placeholder="Repeat new  password"
                                onChange={(event) => setRepeatPassword(event.target.value)}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" colorScheme="red" variant="ghost" onClick={handleShowRepeatPassword}>
                                    {showRepeatPassword ? "Hide" : "Show"}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormHelperText textAlign="left">Repeat your new password</FormHelperText>
                    </FormControl>
                    <Button colorScheme="red"  width="100%" type="submit">
                        Reset my password
                    </Button>
                </VStack>
            </form>
        </>
    );
}

export default ChangePassword;