import { 
    Container,
    Flex,
    Box,
    Textarea,
    Text
} from "@chakra-ui/react";
import React from "react";
import { ActionsButtons } from "..";
import { QuestionDetailProps } from "../types";

const ShortAnswerQuestionDetail: React.FC<QuestionDetailProps> = ({ 
    question, 
    number, 
    setUnsavedQuestion, 
    addUnsavedQuestion, 
    handleDeleteQuestion,
    scrollRef,
    isEditing=false 
}) => {
    const [isShown, setIsShown] = React.useState<boolean>(false);

    return (
        <Container 
            marginBottom="30px" 
            maxW="container.lg" 
            padding="0"
            key={question.question} 
            onMouseEnter={() => setIsShown(true)} 
            onMouseLeave={() => setIsShown(false)}
            style={{position: "relative"}}
        >
            <Flex marginBottom="10px">
                <Box>
                    <Text as="b">Question {number}</Text>: <Text as="i">Short Answer</Text>
                </Box>
            </Flex>
            <Flex marginBottom={!isEditing ? "14px" : "20px"}>
                <Box>
                    <Text>{question.question}</Text>
                </Box>
            </Flex>
            {!isEditing && 
                <Flex marginBottom="20px">
                    <Textarea placeholder="Write answer here..." maxW="600px"/>
                </Flex>
            }
            {(isEditing && isShown) && <ActionsButtons 
                                            question={question} 
                                            setUnsavedQuestion={setUnsavedQuestion} 
                                            addUnsavedQuestion={addUnsavedQuestion} 
                                            handleDeleteQuestion={handleDeleteQuestion}
                                            scrollRef={scrollRef}
                                            number={0} 
                                       />}
        </Container>
    );
};

export default ShortAnswerQuestionDetail;