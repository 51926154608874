import React from "react";
import { 
    Box, 
    Container, 
    Flex, 
    Heading, 
    Text,
    Link
} from "@chakra-ui/react";
import { SubmittedReflectionsTableProps } from "./types";
import { parsePrettyDate } from "../../utils";

const SubmittedReflectionsTable: React.FC<SubmittedReflectionsTableProps> = ({ 
    submissions, 
    setModalBody, 
    onOpen
}) => {
    const childFlex = "1 1 100%";
    const childPadding = "20px 20px 20px 20px";

    return(
        <Container 
            maxW="container.lg" 
            textAlign="left" 
            marginBottom="48px" 
            paddingTop="48px"
        >
            <Flex 
                flexWrap="wrap" 
                color="gray.900" 
                boxShadow="base"
                borderRadius="8px"
            >
                <Box 
                    flexBasis="100%"
                    padding={childPadding}
                    backgroundColor="gray.50"
                    borderTopRadius="8px"
                    textAlign="center"
                >
                    <Heading as="h3" fontSize="1.3rem">Previous Reflection Submissions</Heading>
                </Box>
                {submissions !== [] && submissions.map((submission, index) => {
                    return(
                        <Box 
                            flex={childFlex}
                            padding={childPadding}
                            color="gray.700"
                            key={index}
                        >
                            <Link
                                display="inline"
                                as="i"
                                key={index}
                                onClick={() => {
                                    setModalBody(submission.reflectionAnswers);
                                    onOpen();
                                }}
                            >
                                View Submission
                            </Link>
                            <br />
                            <Text as="i" fontSize="sm">Submitted on {parsePrettyDate(submission.createdAt)}</Text>
                        </Box> 
                    );
                })}
            </Flex>
        </Container>
    );
};

export default SubmittedReflectionsTable;