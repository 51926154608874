import React from "react";
import { InputWrapper } from "./InputWrapper";
import { QuillInputProps } from "./types";
import Quill from "../Quill";

export const QuillInput: React.FC<QuillInputProps> = ({
    content,
    helperText,
    placeholder,
    setContent,
    title
}) => {
    const renderQuillInput = () => {
        return(
            <Quill content={content} setContent={setContent} placeholder={placeholder} />
        );
    };

    return(
        <InputWrapper 
            child={renderQuillInput()}
            helperText={helperText}
            title={title}
        />
    );
};