import react from "react";
import {
    Box,
    Flex,
    Spacer,
    Text,
    Tooltip,
    IconButton,
    Divider,
    Container
} from "@chakra-ui/react";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import { CoursePreviewProps } from "./types";
import { useHistory } from "react-router-dom";

const CoursePreview: React.FC<CoursePreviewProps> = ({ 
    course,
    endDate
}) => {
    let history = useHistory();

    const handleViewCourseButtonClick = () => {
        history.push(`course/${course.id}`, {
            course
        });

        window.scrollTo(0, 0);
    };

    const handleEditCourseButtonClick = () => {
        history.push(`edit/course/${course.id}`, {
            course
        });

        window.scrollTo(0, 0);
    };

    const renderViewCourseButton = () => {
        return(
            <Box padding=".8rem .2rem 1rem 0rem">
                <Tooltip 
                    hasArrow 
                    placement="top" 
                    label="View Course Info"
                    bg="gray.700"
                    color="white" 
                    borderRadius="5px" 
                    padding="5px 10px 5px 10px"
                >
                    <IconButton
                        as="a"
                        onClick={() => handleViewCourseButtonClick()}
                        variant="ghost"
                        colorScheme="gray"
                        aria-label="View Course Info"
                        icon={<ViewIcon />}
                        cursor="pointer"
                    />
                </Tooltip>
            </Box>
        );
    };

    const renderActiveActions = (): React.ReactElement => {
        return(
            <>
                {renderViewCourseButton()}
                <Box padding=".8rem .2rem 1rem 0rem">
                    <Tooltip 
                        hasArrow 
                        label="Edit Course" 
                        placement="top" 
                        bg="gray.700" 
                        color="white" 
                        borderRadius="5px" 
                        padding="5px 10px 5px 10px"
                    >
                        <IconButton
                            as="a"
                            onClick={() => handleEditCourseButtonClick()}
                            variant="ghost"
                            colorScheme="gray"
                            aria-label="Edit Course"
                            icon={<EditIcon />}
                            cursor="pointer"
                        />
                    </Tooltip>
                </Box>
            </>
        );
    }

    return(
        <>
            <Container paddingTop="5px" marginBottom="18px" borderRadius="8px" boxShadow="base" maxWidth="100%">
                <Flex height="70px">
                    <Box padding="1.2rem .25rem 1rem .6rem">
                        <Text fontSize="lg" fontStyle="italic" noOfLines={1}>{course.title}</Text>
                    </Box>
                    <Box padding="1.2rem 1rem 1rem .25rem">
                        <Text fontSize="sm" display={{ sm: "none", lg: "inline"}} noOfLines={1}>| CRN: {course.crn}</Text>
                    </Box>
                    <Spacer />
                    {(endDate.getTime() > new Date().getTime() ) ? renderActiveActions() : renderViewCourseButton()}
                </Flex>
                <Divider />
            </Container>
        </>
    );
}

export default CoursePreview;