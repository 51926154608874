import React from "react";
import Course from "../pages/course";
import Courses from "../pages/courses";
import EditAssignment from "../pages/editAssignment";
import EditChapter from "../pages/editChapter";
import EditCourse from "../pages/editCourse";

/**
 * 
 * Enums
 * 
 */

export enum FetchState {
    IDLE,
    FETCHING,
    FETCHED,
    FETCH_ERROR
};

export type InitialState = {
    status: FetchState;
    error: string|null;
    data: [];
};

export enum HttpMethod {
    GET = "GET",
    POST = "POST",
    DELETE = "DELETE"
}

/**
 * 
 * Routing
 * 
 */

export const adminPages: React.FC<any>[] = [
    Course,
    Courses,
    EditAssignment,
    EditChapter,
    EditCourse
];