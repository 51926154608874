import { IContentItem } from "../components/EditChapterDetail/types";
import { Expose, Transform, Type } from "class-transformer";
import { getDateFromUnixString, getDateFromUnixStringOrNull } from "../utils";
import { AssignmentMetadata, IAssignmentMetadata } from "./Assignment";
import "reflect-metadata"; // Required

export class Chapter {
    @Expose() id: string;
    @Expose() title: string;
    @Expose() orderNumber: number;
    @Expose() guestAuthors: string[] | null;
    @Expose() content: IContentItem[];

    @Transform((str) => getDateFromUnixStringOrNull(str.value))
    @Expose() reflectionsDueDate: Date | null;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() createdAt: Date;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() updatedAt: Date;

    @Type(() => Boolean)
    @Expose() hasReflections?: boolean;
}

export interface IChapter {
    id: string;
    title: string;
    orderNumber: number;
    guestAuthors: string[] | null;
    createdAt: Date;
    updatedAt: Date;
    content: IContentItem[];
    reflectionsDueDate: Date | null;
    hasReflections?: boolean;
};

export interface IChapterMetadata {
    id: string;
    title: string;
    orderNumber: number;
    reflectionsDueDate: Date | null;
    assignment: IAssignmentMetadata | null;
    hasReflections?: boolean;
};

export class ChapterMetadata {
    @Expose() id: string;
    @Expose() title: string;
    @Expose() orderNumber: number;

    @Transform((str) => getDateFromUnixStringOrNull(str.value))
    @Expose() reflectionsDueDate: Date | null;

    @Type(() => Boolean)
    @Expose() hasReflections?: boolean;
    @Type(() => AssignmentMetadata || null)
    @Expose() assignment: AssignmentMetadata | null;
}