import * as React from "react"
import {
    Heading,
    Container,
    Text,
    Box,
    Flex
} from "@chakra-ui/react";
import { CourseTableProps, IDetailItem, Value } from "./types";
import { isBoolean, isDate, isString, isStringList, parsePrettyDate, parsePrettyTime } from "../../utils";

const CourseTable: React.FC<CourseTableProps> = ({ course }) => {
    const childFlex = "1 1 300px";
    const childPadding = "20px 20px 20px 20px";

    const detailItems: IDetailItem[] = [
        {
            heading: "Title",
            value: course.title
        },
        {
            heading: "CRN",
            value: course.crn
        },
        {
            heading: "Instructors",
            value: course.teachers ?? "--"
        },
        {
            heading: "Meeting Days",
            value: course.meetingDays ?? "--"
        },
        {
            heading: "Meeting Time",
            value: course.meetingTime ?? "--"
        },
        {
            heading: "Start Date",
            value: course.startDate
        },
        {
            heading: "End Date",
            value: course.endDate
        },
        {
            heading: "Sync with Blackboard",
            value: course.syncWithBlackboard ?? false
        },
        {
            heading: "Blackboard ID",
            value: course.blackboardId ?? "--"
        },
    ];

    const renderValue = (value: Value, heading: string="default") => {
        if(isString(value)) {
            return value;
        }

        if(isStringList(value)) {
            return value.join(', ');
        }

        if(isBoolean(value)) {
            const boolString = value.toString();
            return boolString[0].toUpperCase() + boolString.substring(1);
        }

        if(isDate(value)) {
            if(heading === "Meeting Time") {
                return parsePrettyTime(value);
            } else {
                return parsePrettyDate(value, false);
            }
        }
    };

    const renderDetailItem = (heading: string, value: Value) => {
        return(
            <Box 
                flex={childFlex}
                padding={childPadding}
                color="gray.700"
                key={heading}
            >
                <Text as="b" lineHeight="9">{heading}</Text>
                <Text lineHeight="7">{renderValue(value, heading)}</Text>
            </Box> 
        );
    };

    return(
        <Container 
            maxW="container.lg" 
            textAlign="left" 
        >
            <Flex 
                flexWrap="wrap" 
                color="gray.900" 
                boxShadow="base"
                borderRadius="8px"
            >
                <Box 
                    flexBasis="100%"
                    padding={childPadding}
                    backgroundColor="gray.50"
                    borderTopRadius="8px"
                    textAlign="center"
                >
                    <Heading as="h3" fontSize="1.3rem">Course Details</Heading>
                </Box>
                {detailItems.map((item) => {
                    return renderDetailItem(item.heading, item.value);
                })}
            </Flex>
        </Container>
    );
}

export default CourseTable;