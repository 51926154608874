import { AssignmentFormat } from "../components/EditAssignmentDetail/enums";
import { IQuestion, Question } from "./Question";
import { Expose, Transform, Type } from "class-transformer";
import { deserializeAssignmentFormat, getDateFromUnixString } from "../utils";
import "reflect-metadata"; // Required

export class Assignment {
    @Expose() id: string;
    @Expose() chapterId: string;
    @Expose() title: string;
    @Expose() description: string;

    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() dueDate: Date;
    @Type(() => Boolean)
    @Expose() syncWithBlackboard: boolean;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() createdAt: Date;
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() updatedAt: Date;
    @Transform((str) => deserializeAssignmentFormat(str.value))
    @Expose() format: AssignmentFormat;
    @Type(() => Question)
    @Expose() questions?: Question[];
};

export interface IAssignment {
    id: string;
    chapterId: string;
    courseId: string;
    title: string;
    description: string;
    dueDate: string;
    numOfRetries?: number;
    syncWithBlackboard?: boolean;
    createdAt: string;
    updatedAt: string;
    format: AssignmentFormat;
    questions?: IQuestion[];
}

export class AssignmentSubmission {
    @Expose() id: string;
    @Expose() assignmentId: string;
    @Expose() assignmentFormat: AssignmentFormat;
    @Expose() url?: string;
    
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() createdAt: Date;
};

export interface IAssignmentSubmission {
    assignmentFormat: AssignmentFormat;
    assignmentId: string;
    id: string;
    url?: string;
    answers?: ISubmissionAnswer[];
    createdAt: string;
};

export class SubmissionAnswer {
    @Expose() id: string;
    @Expose() questionId: string;

    // To do: figure this out
    @Type(() => String)
    @Expose() answer: String | Boolean;
};

export interface ISubmissionAnswer {
    id: string;
    questionId: string;
    answer: boolean|string;
}

export interface IAssignmentMetadata {
    id: string;
    numOfRetries: number;
    dueDate: string;
};

export class AssignmentMetadata {
    @Expose() id: string;
    @Expose() numOfRetries: number;
    
    @Transform((str) => getDateFromUnixString(str.value))
    @Expose() dueDate: Date;
};