import * as React from "react"
import {
    Container,
    Grid,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalFooter,
    ModalContent,
    ModalBody,
    Heading,
    Divider,
    useDisclosure,
    Text
} from "@chakra-ui/react";
import { EditAssignmentState } from "../../pages/editAssignment/enums";
import { IQuestion } from "../../models/Question";
import { plainToInstance } from "class-transformer";
import { EditAssignmentDetailProps } from "./types";
import { Assignment as FetchedAssignment, IAssignment } from "../../models/Assignment";
import { 
    DateTimePicker, 
    Dropdown, 
    QuillInput,
    TextInput 
} from "../Inputs";
import { BiSend } from "react-icons/bi";
import { FiSave } from "react-icons/fi";
import { ViewIcon } from "@chakra-ui/icons";
import { AssignmentFormat } from "./enums";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router-dom";
import { getUnixStringFromDate, getUnixStringFromDateOrNull } from "../../utils";
import { useQuery, UseQueryResult } from "react-query";
import { IPostOutput } from "../../constants/types";
import { pttpClient } from "../../clients/pttp";
import EditAssignmentQuestions from "../EditAssignmentQuestions";
import AssignmentDetail from "../AssignmentDetail";

const EditAssignmentDetail: React.FC<EditAssignmentDetailProps> = ({ 
    assignment, 
    state, 
    setNotice, 
    chapterDropdownOptions, 
    dynamicQuestions,
    user,
    setAlertStatus
}) => {
    const [chapterId, setChapterId] = React.useState<string>(assignment?.chapterId ?? "");
    const [description, setDescription] = React.useState<string>(assignment?.description ?? "");
    const [title, setTitle] = React.useState<string>(assignment?.title ?? "");
    // Only Video is implemented as a feature
    const [selectedFormat, setSelectedFormat] = React.useState<string>(AssignmentFormat.VIDEO);
    const [questions, setQuestions] = React.useState<IQuestion[]>(assignment?.questions ?? []);
    const [dueDate, setDueDate] = React.useState<Date>(assignment?.dueDate ?? new Date("January 1, 2000"));
    const [chapterIdIsInvalid, setChapterIdIsInvalid] = React.useState<boolean>(false);
    const [titleIsInvalid, setTitleIsInvalid] = React.useState<boolean>(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = React.useState<boolean>(false);
    const [dueDateIsInvalid, setDueDateIsInvalid] = React.useState(false);
    const [changesHaveBeenSaved, setChangesHaveBeenSaved] = React.useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { 
        isLoading: postIsLoading,
        refetch: postRefetch,
    }: UseQueryResult<IPostOutput, Error>  = useQuery<IPostOutput, Error>([`updateAssignment${assignment?.id}`], async () => {
        const assignmentId = assignment?.id ?? "invalidAssignment";

        return pttpClient.updateAssignment(
            user, 
            assignmentId, 
            state,
            {
                chapterId,
                title,
                description,
                dueDate: getUnixStringFromDateOrNull(dueDate)
            }
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            setAlertStatus && setAlertStatus("warning");
            setNotice("We're having trouble saving the assignment. Please try again.");
            window.scrollTo(0, 0);
        },
        onSuccess: () => {
            setAlertStatus && setAlertStatus("success");
            setNotice(`The assignment was successfully ${state == EditAssignmentState.EDIT ? "saved" : "added"}!`);
            window.scrollTo(0, 0);
        }
    });

    const renderFormatTitle = () => {
        switch(selectedFormat) {
            case(AssignmentFormat.QUIZ):
                return "Add or Remove Assignment Questions";
            case(AssignmentFormat.DOCUMENT):
                return <>Document Submission Field</>;
            default:
                return <>Video Submission Field</>;
        }
    }

    const renderFormatFields = () => {
        switch(selectedFormat) {
            case(AssignmentFormat.QUIZ):
                return <EditAssignmentQuestions dynamicQuestions={dynamicQuestions} />;
            case(AssignmentFormat.DOCUMENT):
                return <Text>The student will see an upload button that they can click to select a PDF file from their device.</Text>;
            default:
                return <Text>The student will see an field where they can input a hosted link to their video.</Text>;
        }
    }

    const inputAsAssignmentObject: IAssignment = {
        id: "",
        chapterId,
        courseId: "",
        title,
        description,
        dueDate: `${dueDate.getTime() / 1000}`,
        questions,
        format: selectedFormat as AssignmentFormat,
        createdAt: getUnixStringFromDate(new Date),
        updatedAt: getUnixStringFromDate(new Date)
    };

    const inputAsAssignment = plainToInstance(FetchedAssignment, inputAsAssignmentObject, { excludeExtraneousValues: true });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setNotice("");

        if(title === "") {
            setTitleIsInvalid(true);
        } else {
            setTitleIsInvalid(false);
        }

        if(chapterId === "") {
            setChapterIdIsInvalid(true);
        } else {
            setChapterIdIsInvalid(false);
        }

        if(description === "") {
            setDescriptionIsInvalid(true);
        } else {
            setDescriptionIsInvalid(false);
        }

        if(chapterId === "" || description === "" || title === "") {
            setAlertStatus && setAlertStatus("error");
            setNotice("Please fill out all of the required fields.");
            window.scrollTo(0, 0);
        } else {
            postRefetch();
            setChangesHaveBeenSaved(true);
        }

    };

    useBeforeunload((event) => {
        if (!changesHaveBeenSaved) {
          event.preventDefault();
        }
    });

    return(
        <>
            <Prompt
                when={!changesHaveBeenSaved}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <form onSubmit={handleSubmit}>
                <Container maxWidth="container.lg" textAlign="left" padding="0">
                {state === EditAssignmentState.ADD && <Dropdown 
                    title="Associated Chapter"
                    isInvalid={chapterIdIsInvalid}
                    setSelection={setChapterId}
                    defaultValue={chapterId}
                    helperText="Required. What chapter is this the assignment for?"
                    options={chapterDropdownOptions ?? []}
                    selection={chapterId}
                />}
                <TextInput 
                    placeholder="Assignment Title"
                    title="Assignment Title"
                    value={title}
                    setValue={setTitle}
                    isInvalid={titleIsInvalid}
                    helperText="Required. The assignment title goes here."
                />
                <QuillInput 
                    title="Assignment Description"
                    placeholder="Assignment Description"
                    isInvalid={descriptionIsInvalid}
                    setContent={setDescription}
                    content={description}
                    helperText="Required. The assignment description goes here."
                />
                <DateTimePicker 
                    title="Assignment Due Date"
                    isInvalid={dueDateIsInvalid}
                    date={dueDate}
                    setDate={setDueDate}
                    helperText="Required. The due data and time for this assignment across all active courses. Click to set."
                />
                </Container>
                <Container maxWidth="container.lg" 
                    textAlign="left" 
                    padding={0}
                    margin="0px 0px 30px 0px"
                >
                    <Box marginBottom=".5rem">
                        <Heading as="h2" size="md" textAlign="left">
                            {renderFormatTitle()}
                        </Heading>
                    </Box>
                    <Divider colorScheme="red" />
                    <Box marginTop="1rem">
                        {renderFormatFields()}
                    </Box>
                </Container>
                <Container maxWidth="container.lg" textAlign="left" padding="0">
                    <Button 
                        colorScheme="red" 
                        variant="outline" 
                        type="button" 
                        marginRight="10px" 
                        onClick={() => onOpen()}
                        aria-label="Preview Assignment"
                        leftIcon={<ViewIcon />}
                    >
                        Preview Assignment
                    </Button>
                    <Button 
                        colorScheme="red"  
                        type="submit"
                        isLoading={postIsLoading}
                        leftIcon={state === EditAssignmentState.ADD ? <BiSend /> : <FiSave />}
                        aria-label={state === EditAssignmentState.ADD ? "Add Assignment" : "Save Changes"}
                    >
                        {state === EditAssignmentState.ADD ? "Add Assignment" : "Save Changes"}
                    </Button>
                </Container>
            </form>
            <Modal onClose={onClose} size="full" isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign="right">
                    <Button onClick={onClose} colorScheme="red" title="Close Assignment Preview">Close Preview</Button>
                </ModalHeader>
                <ModalBody>
                    <Box textAlign="center" fontSize="md" paddingTop="20px">
                        <Grid minH="100vh" p={3} alignContent="flex-start">
                            <Container maxW="container.lg">
                                <AssignmentDetail 
                                    assignment={inputAsAssignment} 
                                    isPreviewing={true} 
                                    chapterNumber={null}
                                    user={user}
                                />
                            </Container>
                        </Grid>
                    </Box>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    );
};

export default EditAssignmentDetail;