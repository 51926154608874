import * as React from "react"
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Grid,
    Button,
    Container
} from "@chakra-ui/react";
import { 
    ChevronDownIcon, 
    AddIcon,
    UpDownIcon,
    ArrowForwardIcon,
    DeleteIcon
} from "@chakra-ui/icons";
import { FiSave } from "react-icons/fi";
import { useHistory } from "react-router";
import { ActionBarProps } from "./types";

const ActionBar: React.FC<ActionBarProps> = ({ 
    isReorderingChapters, 
    setIsReorderingChapters, 
    isLoading,
    isFetching,
    handleReorder,
    handleCancelReorder,
    setNotice
}) => {
    const history = useHistory();

    const handleSave = () => {
        setNotice("");
        handleReorder();
    };

    const toggleReorder = () => {
        setIsReorderingChapters(!isReorderingChapters);
    };

    const renderLeftMenu = () => {
        return(
            <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Chapter Actions
                </MenuButton>
                <MenuList>
                    <MenuItem icon={<AddIcon />} onClick={() => history.push("/add/chapter")}>Add</MenuItem>
                    <MenuItem 
                        icon={<UpDownIcon />}
                        onClick={toggleReorder}
                    >Reorder</MenuItem>
                </MenuList>
            </Menu>
        );
    };

    const renderLeftCancelChangesButton = () => {
        return(
            <Button 
                aria-label="Cancel Changes"
                leftIcon={<DeleteIcon />} 
                onClick={() => {
                    handleCancelReorder();
                    toggleReorder();
                }}
            >
                Cancel Changes
            </Button>
        );
    };

    const renderRightCoursesButton = () => {
        return(
            <Button 
                rightIcon={<ArrowForwardIcon />} 
                onClick={() => history.push("/courses")}
                aria-label="Courses"
            >
                Courses
            </Button>          
        );
    };

    const renderRightSaveOrderButton = () => {
        return(
            <Button 
                leftIcon={<FiSave />} 
                onClick={handleSave}
                aria-label="Save Chapter Order"
                colorScheme="red"
                isLoading={isLoading || isFetching}
                loadingText="Reordering"
            >
                Save Chapter Order
            </Button>          
        );
    };

    return(
        <Container maxW="container.lg" marginBottom="18px">
            <Grid templateColumns="1fr 1fr">
                <Box justifySelf="flex-start" alignSelf="center">
                    {isReorderingChapters ? renderLeftCancelChangesButton() : renderLeftMenu()}
                </Box>
                <Box justifySelf="flex-end"  alignSelf="center">
                    {isReorderingChapters ? renderRightSaveOrderButton() : renderRightCoursesButton()}
                </Box>
            </Grid>
        </Container>
    );
}

export default ActionBar;