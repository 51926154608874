import * as React from "react"
import {
    Box,
    Flex,
    Spacer,
    Text,
    Icon,
    Tooltip,
    IconButton,
    Divider,
    Container,
    Spinner
} from "@chakra-ui/react";
import { Link } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/hooks";
import { EditIcon, ViewIcon, DeleteIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { GrAdd } from "react-icons/gr";
import { parsePrettyDate } from "../../utils";
import { ChapterPreviewProps } from "./types";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { BsChatSquareDots } from "react-icons/bs";
import { IconType } from "react-icons";
import DeleteItemModal from "../modals/DeleteItem";

const ChapterPreview: React.FC<ChapterPreviewProps> = ({ 
    chapter,
    chaptersMetadata,
    user, 
    deleteChapterRefetch,
    deleteAssignmentRefetch,
    setChapterToDelete,
    setAssignmentToDelete,
    assignment, 
    assignmentSubmissions, 
    isReorderingChapters,
    moveChapterUp,
    moveChapterDown,
    index,
    numOfChapters,
    getAssignmentSubmissionsIsLoading
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isDeleteAssignment, setIsDeleteAssignment] = React.useState<boolean>(false);
    const [modalMessage, setModalMessage] = React.useState<string>(`Proceed with caution! The ${isDeleteAssignment && "assignment associated with the"} chapter titled "${chapter.title}" will be permanently deleted.`);

    const assignmentIsComplete = assignmentSubmissions.length > 0;
    const deleteChapterMessage = `Proceed with caution! The  chapter titled "${chapter.title}" will be permanently deleted.`;
    const deleteAssignmentMessage = `Proceed with caution! The assignment associated with the chapter titled "${chapter.title}" will be permanently deleted.`;

    const iconColor = assignmentIsComplete ? "green.500" : "gray.700";

    const dueDate = assignment ? assignment.dueDate : new Date("January 1, 2000"); // Default to date in the past
    const isPastDueDate = assignment && assignment.dueDate < new Date();

    let history = useHistory();

    const handleAddAssignmentButtonClick = () => {
        history.push('add/assignment', {
            chaptersMetadata
        });

        window.scrollTo(0, 0);
    };

    const handleViewAssignmentButtonClick = (id: string) => {
        history.push(`/assignment/${id}`, {
            chapterNumber: chapter.orderNumber
        });

        window.scrollTo(0, 0);
    };

    const handleClick = (isAdmin: boolean) => {
        if(isAdmin) {
            history.push({
                pathname: `/chapter/${chapter.id}`,
                state: { isDisabled: true }
            });
        } else {
            history.push(`/chapter/${chapter.id}`);
        }

        window.scrollTo(0, 0);
    };

    const displayViewChapterButton = (isAdmin: boolean): React.ReactElement => {
        return(
            <Box  padding=".6rem .2rem 1rem 0rem" cursor="pointer">
                <Tooltip hasArrow label="View Chapter" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                    <IconButton
                        as="a"
                        onClick={() => handleClick(isAdmin)}
                        variant="ghost"
                        colorScheme="gray"
                        aria-label="View Chapter"
                        icon={<ViewIcon />}
                    />
                </Tooltip>
            </Box>
        );
    }

    const displayAdminChapterActions = (): React.ReactElement => {
        return(
            <>
                {displayViewChapterButton(true)}
                <Box padding=".6rem .2rem 1rem 0rem">
                    <Tooltip hasArrow label="Edit Chapter" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                        <IconButton
                            as="a"
                            href={`/edit/chapter/${chapter.id}`}
                            variant="ghost"
                            colorScheme="gray"
                            aria-label="Edit Chapter"
                            icon={<EditIcon />}
                        />
                    </Tooltip>
                </Box>
                <Box  padding=".6rem .4rem 1rem 0rem">
                    <Tooltip hasArrow label="Delete Chapter" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                        <IconButton
                            as="a"
                            href={"#"}
                            onClick={() => {
                                setModalMessage(deleteChapterMessage);
                                setIsDeleteAssignment(false);
                                setChapterToDelete(chapter.id);
                                onOpen();
                            }}
                            variant="ghost"
                            color="red.600"
                            aria-label="Delete Chapter"
                            icon={<DeleteIcon />}
                        />
                    </Tooltip>
                </Box>
            </>
        );
    }

    const displayUserAssignmentActions = (): React.ReactElement => {
        if(getAssignmentSubmissionsIsLoading) {
            return(
                <Spinner size="xs" />
            );
        }

        if(!isPastDueDate && !assignmentIsComplete) {
            return(
                 <Link 
                    display="inline" 
                    color="red.500"
                    onClick={() => handleViewAssignmentButtonClick(assignment?.id ?? "")}
                >
                    Start Assignment
                </Link>
            );
        }

        if(isPastDueDate && !assignmentIsComplete) {
            return(
                 <Text>The due date for this assignment was {parsePrettyDate(dueDate)}</Text>
            );
        }

        return <Link href={assignmentSubmissions[0].url ?? "#"} target="_BLANK" color="red.500">View Submission</Link>;
    }

    const displayChapterAssignmentInfo = (): React.ReactElement => {
        if(user && user.isAdmin) {
            return(
                <>
                    <Text fontSize="sm">
                        <span style={{ fontWeight: "bold" }}>Chapter Assignment: </span> {!assignment ? "None " : "There is an assignment for this chapter."}
                    </Text>
                </>
            );
        }

        return (
            <>
                <Flex fontSize="sm" flexDirection="row">
                    <Box marginRight="5px">
                        <Text as="b">Chapter Assignment: </Text> 
                    </Box>
                    <Box>
                        {!assignment ? "No assignment for this chapter!" : displayUserAssignmentActions() }
                    </Box> 
                </Flex>
            </>
        );
    }

    const generateTooltip = (icon: IconType, label: string) => {
        const dimension = icon === NotAllowedIcon ? 5 : 4;
        const color = icon === NotAllowedIcon ? "gray.400" : iconColor;

        return(
            <Box padding="1rem .9rem 1rem 1rem" zIndex="1">
                <Tooltip hasArrow label={label} placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                    <span><Icon as={icon} w={dimension} h={dimension} color={color} /></span>
                </Tooltip>
            </Box>
        );
    }

    const displayUserAssignmentTooltip = (): React.ReactElement => {
        if(assignment) {
            if(assignmentIsComplete) {
                return generateTooltip(FaCheckCircle, "Assignment Complete");
            } else {
                if(isPastDueDate) {
                    return generateTooltip(NotAllowedIcon as IconType, "Due Date Passed");
                } 
                
                return generateTooltip(FaRegCircle, `Assignment Incomplete - Due by ${parsePrettyDate(dueDate)}`);
            }
        }

        return generateTooltip(NotAllowedIcon as IconType, "No Assignment");
    }

    const displayAdminAssignmentTooltip = (): React.ReactElement => {
        if(assignment) {
            return(
                <Flex>
                    <Box padding=".6rem .2rem 1rem 0rem">
                        <Tooltip hasArrow label="Edit Assignment" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                            <IconButton
                                as="a"
                                href={`/edit/assignment/${assignment.id}`}
                                variant="ghost"
                                colorScheme="gray"
                                aria-label="Edit Assignment"
                                icon={<FiEdit2 />}
                            />
                        </Tooltip>
                    </Box>
                    <Box  padding=".6rem .4rem 1rem 0rem">
                        <Tooltip hasArrow label="Delete Assignment" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                            <IconButton
                                as="a"
                                href={"#"}
                                onClick={() => {
                                    setModalMessage(deleteAssignmentMessage);
                                    setIsDeleteAssignment(true);
                                    setAssignmentToDelete && setAssignmentToDelete(assignment.id);
                                    onOpen();
                                }}
                                variant="ghost"
                                color="red.600"
                                aria-label="Delete Assignment"
                                icon={<IoMdRemoveCircleOutline />}
                            />
                        </Tooltip>
                    </Box>
                </Flex>
            );
        }

        return(
            <Box  padding=".6rem .4rem 1rem 0rem">
                <Tooltip hasArrow label="Add Assignment" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                    <IconButton
                        as="a"
                        variant="ghost"
                        colorScheme="gray"
                        aria-label="Add Assignment"
                        icon={<GrAdd />}
                        onClick={handleAddAssignmentButtonClick}
                        cursor="pointer"
                    />
                </Tooltip>
            </Box>
        );
    }

    const displayAssignmentAction = (): React.ReactElement => {
        if(user && user.isAdmin) {
            return(displayAdminAssignmentTooltip());
        }

        return(displayUserAssignmentTooltip());
    }

    return(
        <>
            <Flex>
                <Container paddingTop="5px" marginBottom="18px" borderRadius="8px" boxShadow="base" maxWidth="100%">
                    <Flex height="70px">
                        <Box padding="1rem .25rem 1rem .6rem">
                            <Text fontSize="lg" fontStyle="italic" noOfLines={1}>Chapter {index + 1}: {chapter.title}</Text>
                        </Box>
                        {(user && !user.isAdmin) && chapter.hasReflections && <Box padding="1.3rem .2rem 1rem .4rem">
                            <Tooltip hasArrow label="This chapter has in-paragraph reflection questions" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                                <span><Icon as={BsChatSquareDots} /></span>
                            </Tooltip>
                        </Box>}
                        <Spacer />
                        {user?.isAdmin && displayAdminChapterActions()}
                        {!user?.isAdmin && displayViewChapterButton(false)}
                    </Flex>
                    <Divider />
                    <Flex>
                        <Box padding="1rem 0rem 1rem .6rem" textAlign="left">
                            {displayChapterAssignmentInfo()}
                        </Box>
                        <Spacer />
                        <Box alignSelf="center">
                            {displayAssignmentAction()}
                        </Box>
                    </Flex>
                </Container>
                <Flex flexDir="column" justifyContent="start" display={isReorderingChapters ? "block" : "none"}>
                    <Box marginLeft="10px" marginBottom="5px" alignSelf="flex-start">
                        <Tooltip hasArrow label="Move Chapter Up" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                            <IconButton
                                size="md"
                                aria-label="move-chapter-up" 
                                icon={<AiOutlineArrowUp />}
                                onClick={() => moveChapterUp(index)}
                                disabled={index === 0}
                            />
                        </Tooltip>
                    </Box>
                    <Box marginLeft="10px" alignSelf="flex-start">
                        <Tooltip hasArrow label="Move Chapter Down" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                            <IconButton
                                size="md"
                                aria-label="move-chapter-up" 
                                icon={<AiOutlineArrowDown />}
                                onClick={() => moveChapterDown(index)}
                                disabled={index === numOfChapters - 1}
                            />
                        </Tooltip>
                    </Box>
                </Flex>
            </Flex>
            <DeleteItemModal 
                isOpen={isOpen} 
                onClose={onClose} 
                onDelete={isDeleteAssignment ? () => deleteAssignmentRefetch() : () => deleteChapterRefetch()}
                message={modalMessage} 
                typeToDelete={isDeleteAssignment ? "assignment" : "chapter"} 
            />
        </>
    );
}

export default ChapterPreview;