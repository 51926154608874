import { ICognitoConfiguration } from "../constants/types";
import { Stage } from "./constants";

export const getStage = (): Stage => {
    const domain = window.location.hostname;

    switch(domain) {
        case "devo.holisticconductor.com":
            return Stage.DEVO;
        case "holisticconductor.com":
            return Stage.PROD;
        default:
            return Stage.TEST;
    }
};

export const getPTTPEndpoint = (stage?: Stage): string => {
    if(!stage) {
        stage = getStage();
    }

    // For DEVO, we're using TEST configuration
    switch(stage) {
        case Stage.PROD:
            return "https://api.holisticconductor.com";
        default:
            return "https://v60phqxdq0.execute-api.us-east-1.amazonaws.com/devo";
    }
};

export const getCognitoConfig = (stage?: Stage): ICognitoConfiguration => {
    if(!stage) {
        stage = getStage();
    }

    // For DEVO, we're using TEST configuration
    switch(stage) {
        case Stage.PROD:
            return {
                userPoolId: "us-east-1_iDV1iYPT3",
                userPoolWebClientId: "3um55fjm75fdfmf047b1s91b4n"
            };
        default:
            return {
                userPoolId: "us-east-1_lQsTA35j3",
                userPoolWebClientId: "24g387viqdajk652kb4fdkmn6v"
            }
    }
};