import React from "react";
import {
    Alert,
    AlertIcon,
    AlertStatus,
    Box,
    Button,
    Container,
    Flex,
    Icon,
    Text
} from "@chakra-ui/react";
import { api } from "../../clients/api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BsBookHalf } from "react-icons/bs";
import { Course as FetchedCourse } from "../../models/Course";
import { useQuery, UseQueryResult } from "react-query";
import { CourseProps, CourseStateProps } from "./types";
import { IUrlParams } from "../chapter/types";
import { ArrowBackIcon, EditIcon } from "@chakra-ui/icons";
import { ChapterMetadata } from "../../models/Chapter";
import { DownloadType } from "./enums";
import { IAssignment } from "../../models/Assignment";
import { getChaptersWithAssignments, getChaptersWithReflectionQuestions } from "../../utils";
import { useDocumentTitle } from "../../utils/hooks";
import { pttpClient } from "../../clients/pttp";
import PageError from "../../components/PageError";
import SkeletonLoad from "../../components/SkeletonLoad/";
import CourseTable from "../../components/CourseTable";
import SubmissionDownload from "../../components/SubmissionDownload";

const Course: React.FC<CourseProps> = ({ user }) => {
    useDocumentTitle("View Course");

    const location = useLocation();
    const history = useHistory();
    const { id } = useParams<IUrlParams>();

    const [course, setCourse] = React.useState<FetchedCourse|null>();
    const [chapters, setChapters] = React.useState<ChapterMetadata[]>([]);
    const [assignments, setAssignments] = React.useState<IAssignment[]>([]);
    const [notice, setNotice] = React.useState<string>("");
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("warning");

    const { 
        isFetching: getIsFetching, 
        isFetched: getIsFetched,
        isError: getIsError, 
        data: getData,
        refetch: getRefetch
    }: UseQueryResult<FetchedCourse|null, Error>  = useQuery<FetchedCourse|null, Error>([`getCourse${id}`], async () => {
        return pttpClient.getCourse(user, id ?? "")
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        cacheTime: 3600000,
        staleTime: 3600000
    });

    const { 
        refetch: getChaptersMetadataRefetch, 
        data: getChaptersMetadataData,
        isFetching: getChaptersMetadataIsFetching 
    }: UseQueryResult<ChapterMetadata[], Error>  = useQuery<ChapterMetadata[], Error>(["getChaptersMetadata"], async () => {
        return pttpClient.getChaptersMetadata(user)
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        onError: (() => {
            setAlertStatus("warning");
            setNotice("We're having trouble retrieving the chapters. Please refresh the page to try again.")
        })
    });

    React.useEffect(() => {
        getChaptersMetadataRefetch();
    }, [course]);

    React.useEffect(() => {
        setChapters(getChaptersMetadataData ?? []);
    }, [getChaptersMetadataData]);

    React.useEffect(() => {
        const stateCourse = location.state ? (location.state as CourseStateProps).course : null;

        if(!stateCourse) {
            if(id !== undefined) {
                getRefetch();
            }
        } else {
            setCourse(stateCourse);
        }
    }, [location.state, id]);

    React.useEffect(() => {
        if(getData !== undefined) {
            setCourse(getData);
        }
    }, [getData]);

    if(getIsFetching || course === undefined) {
        return (
            <SkeletonLoad />
        );
    }

    if(getIsFetched && getIsError) {
        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    const renderComponent = (): React.ReactElement => {
        // If the course is undefined, it hasn't been loaded yet. If it's null or an object, it's been loaded.
        // We only want to show the page error if the course has been fetched and not found.
        if(course === null) {
            return (
                <Container maxW="container.lg">
                    <PageError message="This course doesn't exist." display={<Icon as={BsBookHalf} w={180} h={180} color="red.500" />} />
                </Container>
            );
        } else if(course) {
            return (
                <>
                    <CourseTable course={course} />
                    <SubmissionDownload 
                        chapters={getChaptersWithReflectionQuestions(chapters)} 
                        type={DownloadType.REFLECTIONS}
                        user={user}
                        setAlertStatus={setAlertStatus}
                        setNotice={setNotice}
                        course={course}
                        isFetching={getChaptersMetadataIsFetching}
                    />
                    <SubmissionDownload 
                        chapters={getChaptersWithAssignments(chapters)}
                        type={DownloadType.ASSIGNMENTS}
                        user={user}
                        setAlertStatus={setAlertStatus}
                        setNotice={setNotice}
                        course={course}
                        isFetching={getChaptersMetadataIsFetching}
                    />
                </>
            );
        }

        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    return(
        <>
            {
                notice !== "" &&
                <Container maxW="container.lg"  marginBottom="40px">
                    <Alert status={alertStatus} borderRadius="8px" textAlign="left" justifySelf="center">
                        <AlertIcon />
                        <Text>
                            {notice}
                        </Text>
                    </Alert>
                </Container>
            }
            <Container maxW="container.lg" marginBottom="18px" marginTop="10px">
                <Flex justifyContent="space-between">
                    <Box justifySelf="flex-start" alignSelf="center">
                        <Button leftIcon={<ArrowBackIcon />} onClick={() => history.goBack()}>
                            Return to Courses
                        </Button>
                    </Box>
                    {course && <Box>
                        <Button rightIcon={<EditIcon />} onClick={() => history.push(`/edit/course/${course.id}`)}>
                            Edit Course Details
                        </Button>
                    </Box>}
                </Flex>
            </Container>
            <Box fontSize="md" paddingTop="20px" paddingLeft="0px">
                {renderComponent()}
            </Box>
        </>
    );
}

export default Course;