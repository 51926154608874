import * as React from "react"
import {
    Box,
    Grid,
    Container,
    Alert,
    AlertIcon,
    Icon,
    Heading,
    AlertStatus,
    Text,
    Link
} from "@chakra-ui/react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { BsBookHalf } from "react-icons/bs";
import { Chapter } from "../../models/Chapter";
import { IUrlParams } from "../chapter/types";
import { EditChapterState } from "./enums";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import { EditChapterProps } from "./types";
import { pttpClient } from "../../clients/pttp";
import PageError from "../../components/PageError";
import EditChapterDetail from "../../components/EditChapterDetail";
import SkeletonLoad from "../../components/SkeletonLoad";

const EditChapter: React.FC<EditChapterProps> = ({ user }) => {
    useDocumentTitle("Edit Chapter");

    const [chapter, setChapter] = React.useState<Chapter|null>();
    const [notice, setNotice] = React.useState<string>("");
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("error");
    const [editChapterState, setEditChapterState] = React.useState<EditChapterState>(EditChapterState.EDIT);

    const { id } = useParams<IUrlParams>();
    const location = useLocation();
    const history = useHistory();

    const { 
        isLoading: getIsLoading, 
        isError: getIsError, 
        isFetching: getIsFetching,
        isFetched: getIsFetched,
        data: getData,
        refetch: getRefetch
    }: UseQueryResult<Chapter|null, Error>  = useQuery<Chapter|null, Error>([`getChapter${id}`], async () => {
        return pttpClient.getChapter(user, id ?? "")
    }, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    React.useEffect(() => {
        if(location.pathname.endsWith("/add/chapter/") || location.pathname.endsWith("/add/chapter")) {
            setEditChapterState(EditChapterState.ADD);
        } else {
            if(id !== undefined) {
                getRefetch();
            }
        }
    }, [id, location.pathname]);

    React.useEffect(() => {
        setChapter(getData);
    }, [getData]);

    if(getIsFetching || (editChapterState === EditChapterState.EDIT && chapter === undefined)) {
        return (
            <SkeletonLoad />
        );
    }

    if(getIsError) {
        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    const renderHeader = () => {
        return <Heading marginBottom={10}>{editChapterState === EditChapterState.EDIT ? "Edit" : "Add"} Chapter</Heading>
    };

    const renderComponent = (): React.ReactElement => {
        if(editChapterState === EditChapterState.EDIT) {
            // If the chapter is undefined, it hasn't been loaded yet. If it's null or an object, it's been loaded.
            // We only want to show the page error if the chapter has been fetched and not found.
            if(getIsFetched && chapter === null) {
                return <PageError message="This chapter doesn't exist." display={<Icon as={BsBookHalf} w={180} h={180} color="red.500" />} />;
            } else if (getIsFetched && chapter) {
                return (
                    <>  
                        {renderHeader()}
                        <EditChapterDetail 
                            state={editChapterState} 
                            chapter={chapter} 
                            setNotice={setNotice} 
                            user={user}
                            setAlertStatus={setAlertStatus}
                        />
                    </>
                );
            }
        }

        return (
            <>
                {renderHeader()}
                <EditChapterDetail 
                    state={EditChapterState.ADD} 
                    setNotice={setNotice} 
                    user={user}
                    setAlertStatus={setAlertStatus}
                />
            </>
        );
    }

    return(
        <>
            <Box id="_progress" backgroundColor="red.500"></Box>
            <Box textAlign="center" fontSize="md" paddingTop="20px">
                <Grid minH="100vh" p={{ sm: 0, lg: 3}} alignContent="flex-start">
                    {
                        notice !== "" &&
                        <Container maxW="container.lg"  marginBottom="40px">
                            <Alert status={alertStatus} borderRadius="8px" textAlign="left" justifySelf="center">
                                <AlertIcon />
                                <Text>
                                    {notice}
                                    &nbsp;{alertStatus === "success" && <Link onClick={() => history.push("/")}>Click here to return home.</Link>}
                                </Text>
                            </Alert>
                        </Container>
                    }
                    <Container maxW="container.lg">
                        {renderComponent()}
                    </Container>
                </Grid>
            </Box>
        </>
    );
}

export default EditChapter;