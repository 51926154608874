import { Auth } from "@aws-amplify/auth";
import { plainToInstance } from "class-transformer";
import { User } from "../models/User";
import { getCognitoConfig } from "./utils";
import { setStateBoolean, setStatePromise, setStateString, setStateUser } from "../constants/types";
import { AlertStatus } from "@chakra-ui/react";

class Cognito {
    public constructor() {
        const cognitoConfig = getCognitoConfig();

        Auth.configure({
            region: "us-east-1",
            storage: localStorage,
            ...cognitoConfig
        });
    };

    public async signIn(
        email: string, 
        password: string, 
        setError: setStateString, 
        setIsAuthenticated: setStateBoolean, 
        setShouldShowChangePassword: setStateBoolean, 
        setSuccessfulSignInResult: setStatePromise,
        setCurrentUser: setStateUser
    ) {
        try {
            const result = await Auth.signIn(email, password);
            console.log(`New user login!`);

            // Get the user attributes to be serialized
            let attributes;
            if(result?.signInUserSession?.idToken?.jwtToken) {
                attributes = {
                    ...result.attributes,
                    jwtToken: result.signInUserSession.idToken.jwtToken
                }
            } else {
                attributes = result.attributes
            }

            // Set current user in the global context
            const userObject = plainToInstance(User, attributes, { excludeExtraneousValues: true });
            setCurrentUser(userObject);

            setSuccessfulSignInResult(result);
            setIsAuthenticated(true);

            if(result.challengeName === "NEW_PASSWORD_REQUIRED") {
                setShouldShowChangePassword(true);
            }

        } catch (error: any) {
            setError(error.message);

            console.log(`Exception: ${error.message}`);
        }
    }

    public async completeNewPassword(
        successfulSignInResult: any, 
        newPassword: string, 
        setNotice: setStateString, 
        setIsAuthenticated: setStateBoolean, 
        setShouldShowChangePassword: setStateBoolean, 
        setAlertStatus: (status: AlertStatus) => void
    ) {
        try {
            await Auth.completeNewPassword(successfulSignInResult, newPassword, {});

            setIsAuthenticated(false);
            setShouldShowChangePassword(false);
            setNotice("You've successfully reset your password. Click on this notice to go to the home page.")
            setAlertStatus("success");
        } catch(error: any) {
            setNotice(error.message);
            setAlertStatus("error");

            console.log(`Exception: ${error}`);
        }
    }

    public async getUserSession(
        setIsAuthenticated: setStateBoolean,
        setCurrentUser: setStateUser
    ) {
        console.log("Logging user in...");

        try {
            const currentUser = await Auth.currentAuthenticatedUser();

            await Auth.currentSession();

            if(currentUser) {
                console.log(`User is authenticated!`);

                setIsAuthenticated(true);

                const attributes = {
                    ...currentUser.attributes,
                    jwtToken: currentUser.signInUserSession.idToken.jwtToken
                };

                // Set current user in the global context
                const userObject = plainToInstance(User, attributes, { excludeExtraneousValues: true });
                setCurrentUser(userObject);
            } else {
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.log(`Authentication: ${error}`);
            setIsAuthenticated(false);
        }
    }

    public async signOut(
        setIsAuthenticated: setStateBoolean, 
        setError: setStateString,
        setCurrentUser: setStateUser
    ) {
        try{
            await Auth.signOut();
            setIsAuthenticated(false);
            setCurrentUser(null);
        } catch(error: any) {
            console.log(`Exception: ${error.message}`);
            setError(error.message);
        } finally {

        }
    }

    public async initiatePasswordReset(
        email: string, 
        setError: setStateString
    ) {
        try{
            await Auth.forgotPassword(email);
        } catch(error: any) {
            console.log(`Exception: ${error.message}`);
            setError(error.message);
        }
    }

    public async resetPassword(
        email: string,
        code: string,
        password: string,
        setNotice: setStateString,
        setAlertStatus: (status: AlertStatus) => void
    ) {
        try{
            await Auth.forgotPasswordSubmit(email, code, password);
            setNotice("You've successfully reset your password. Click on this notice to go to the home page.")
            setAlertStatus("success");
        } catch(error: any) {
            console.log(`Exception: ${error.message}`);
            setNotice(error.message);
            setAlertStatus("error");
        }
    }
}

export const cognito = new Cognito();