import { Expose, Transform } from "class-transformer";
import "reflect-metadata"; // Required

export interface IQuestion {
    id: string;
    questionType: QuestionType,
    question: string;
    answerChoices?: string[];
}

export class Question {
    @Expose() id: string;
    @Expose() question: string;
    @Expose() answerChoices: string[];

    @Transform(() => QuestionType) // Does this work?
    @Expose() questionType: QuestionType;
};

export interface IQuestionAnswer {
    id: string;
    questionId: string;
    questionType: QuestionType,
    answer?: string;
}

export class QuestionAnswer {
    @Expose() id: string;
    @Expose() questionId: string;
    @Expose() answer: string;

    @Transform(() => QuestionType)
    @Expose() questionType: QuestionType;
};

export enum QuestionType {
    TRUE_FALSE = "TrueFalse",
    MULTIPLE_CHOICE = "MultipleChoice",
    SHORT_ANSWER = "ShortAnswer"
};