import { IContentItem, IDynamicContentItem } from "./types";

export const serializeDynamicContent = (content: IContentItem[] | undefined): IDynamicContentItem[] => {
    if(!content) {
        return [];
    }

    return content.map((item) => {
        return {
            dynamicId: item.id,
            type: item.type,
            value: item.value
        };
    });
};

export const serializeContent = (dynamicContent: IDynamicContentItem[] | undefined): IContentItem[] => {
    if(!dynamicContent) {
        return [];
    }

    return dynamicContent.map((item) => {
        return {
            id: item.dynamicId,
            type: item.type,
            value: item.value
        };
    });
};