import * as React from "react"
import {
  Box,
  Grid,
  Container,
  AlertStatus,
} from "@chakra-ui/react";
import { useAuthContext } from "../../config/context";
import { cognito } from "../../clients/cognito";
import { useDocumentTitle } from "../../utils/hooks";
import { useLocation } from "react-router-dom";
import { UpdatePasswordProps } from "./types";
import ChangePassword from "../../components/ChangePassword";

const UpdatePassword: React.FC = () => {
    useDocumentTitle("Reset Password");

    const location = useLocation();
    const email = location.state ? (location.state as UpdatePasswordProps).email : "";
    const forgotPassword = location.state ? (location.state as UpdatePasswordProps).forgotPassword : true;

    const { successfulSignInResult, setIsAuthenticated, setShouldShowChangePassword } = useAuthContext();

    const [newPassword, setNewPassword] = React.useState<string>("");
    const [repeatPassword, setRepeatPassword] = React.useState<string>("");
    const [notice, setNotice] = React.useState<string>("");
    const [code, setCode] = React.useState<string>("");
    const [resetEmail, setResetEmail] = React.useState<string>(email);
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("error")

    const arePasswordsEqual = (newPassword: string, repeatPassword: string): boolean => {
        return newPassword === repeatPassword;
    };

    const handleUpdatePasswordSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setNotice("");
        setAlertStatus("error");

        if(arePasswordsEqual(newPassword, repeatPassword)) {
            cognito.completeNewPassword(
                successfulSignInResult, 
                newPassword, 
                setNotice, 
                setIsAuthenticated, 
                setShouldShowChangePassword,
                setAlertStatus
            );
        } else {
            setNotice("The passwords you have entered do not match.")
            setAlertStatus("error");
        }
    };

    const handleResetPasswordSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        setNotice("");
        setAlertStatus("error");

        if(arePasswordsEqual(newPassword, repeatPassword)) {
            cognito.resetPassword(
                email, 
                code, 
                newPassword,
                setNotice,
                setAlertStatus
            );
        } else {
            setNotice("The passwords you have entered do not match.");
            setAlertStatus("error");
        }
    };

    return(
        <Box textAlign="center" fontSize="md">
            <Grid minH="100vh" p={3}>
                <Container alignSelf="center">
                    <ChangePassword  
                        forgotPassword={forgotPassword}
                        email={resetEmail}
                        setResetEmail={setResetEmail}
                        setCode={setCode}
                        setNewPassword={setNewPassword} 
                        setRepeatPassword={setRepeatPassword}
                        handleSubmit={!forgotPassword ? handleUpdatePasswordSubmit : handleResetPasswordSubmit} 
                        notice={notice}
                        alertStatus={alertStatus}
                    />
                </Container>
            </Grid>
        </Box>
    );
};

export default UpdatePassword;