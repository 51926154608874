import * as React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from "@chakra-ui/react";
import { DeleteItemModalProps } from "./types";

const DeleteItemModal: React.FC<DeleteItemModalProps> = ({ isOpen, onClose, onDelete, typeToDelete, message }) => {

    const handleDelete = () => {
        onDelete();
        onClose();
    };
  
    return(
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete {typeToDelete}?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {message}
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button colorScheme="red" mr={3} onClick={handleDelete}>
                      Delete
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
);
}

export default DeleteItemModal;