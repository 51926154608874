import * as React from "react"
import {
    Box,
    Container,
    Flex,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    Tooltip,
    Text,
    MenuButton,
    Menu,
    MenuList,
    MenuItem,
    useDisclosure
} from "@chakra-ui/react";
import { ChapterContentProps, ISpoofedEvent } from "./types";
import { IDynamicContentItem } from "../EditChapterDetail/types";
import { ContentType } from "../EditChapterDetail/enums";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { generateRandomId } from "../../utils";
import DeleteItemModal from "../modals/DeleteItem/view";
import Quill from "../Quill";
import "./index.css";

const ChapterContent: React.FC<ChapterContentProps> = ({ content, setContent }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [itemToDelete, setItemToDelete] = React.useState<string>("");
    
    const handleEditContentItem = (contentItem: IDynamicContentItem) => {
        const modifyableContentList = [...content ?? []];

        modifyableContentList.forEach((item) => {
            if(item.dynamicId === contentItem.dynamicId) {
                item.value = contentItem.value;
            }
        });

        setContent(modifyableContentList);
    };

    const handleDeleteContentItem = (dynamicId: string) => {
        const modifyableContentList = [...content ?? []];

        for(let i = 0; i < modifyableContentList.length; i++) {
            if(modifyableContentList[i].dynamicId === dynamicId) {
                modifyableContentList.splice(i, 1);
            }
        }

        setContent(modifyableContentList);
    };

    const addContentItemBelow = (type: ContentType, anchorDynamicId: string) => {
        const modifyableContentList = [...content ?? []];

        const indexOfAnchor = modifyableContentList.map((item) => item.dynamicId).indexOf(anchorDynamicId);

        modifyableContentList.splice(indexOfAnchor + 1, 0, {
            dynamicId: `${generateRandomId(5)}`,
            value: "",
            type
        });

        setContent(modifyableContentList);
    };

    const onContentChange = (event: ISpoofedEvent, dynamicId: string) => {
        handleEditContentItem({
            dynamicId,
            value: event.target.value,
            type: ContentType.CONTENT
        });
    };

    const renderContentItem = (contentItem: IDynamicContentItem) => {
        switch(contentItem.type) {
            case ContentType.CONTENT:
                return(
                    <FormControl marginBottom="40px">
                        <Quill 
                            content={contentItem.value} 
                            placeholder="Content Block" 
                            setContent={(html: any) => onContentChange({ target: { value: html } }, contentItem.dynamicId ?? "")}
                            className="chapter-editor"
                        />
                        <FormHelperText textAlign="left">
                            <b>Chapter Content</b>. Write your content block here. It can be formatted text, links, pictures, or video!
                        </FormHelperText>
                    </FormControl>
                )
            case ContentType.REFLECTION_QUESTION:
                return(
                    <FormControl marginBottom="40px">
                        <Input 
                            placeholder={"Reflection Question"} 
                            size="sm"
                            borderRadius="8px"
                            padding="18px 0px 18px 12px"
                            onChange={(event) => handleEditContentItem({
                                dynamicId: contentItem.dynamicId,
                                value: event.target.value,
                                type: ContentType.CONTENT
                            })}
                            borderColor="gray.300"
                            value={contentItem.value}
                            isInvalid={false}
                        />
                        <FormHelperText textAlign="left">
                                <b>Reflection Question</b>. Write your short answer question here.
                        </FormHelperText>
                    </FormControl>
                );
            default:
                return <Text>Content item is of unrecognized type.</Text>;
        }
    };

    return(
        <Container maxW="container.lg" padding="0" marginTop="1rem">
            {content.map((contentItem, index) => {
                return(
                    <Flex flexBasis="100%" paddingTop="10px" key={index}>
                        <Box flex="24" marginRight="10px">
                            {renderContentItem(contentItem)}
                        </Box>
                        <Box flex="1" marginRight="10px">
                            <Menu>
                                <MenuButton 
                                    aria-label="add-content-item-below" 
                                    size="md" 
                                    as={IconButton}
                                    icon={<AiOutlineArrowDown />} 
                                />
                                <MenuList>
                                    <MenuItem onClick={() => addContentItemBelow(ContentType.CONTENT, contentItem.dynamicId)} icon={<AddIcon />}>
                                        Add Content Block Below
                                    </MenuItem>
                                    <MenuItem onClick={() => addContentItemBelow(ContentType.REFLECTION_QUESTION, contentItem.dynamicId)} icon={<AddIcon />}>
                                        Add Reflection Question Below
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                        <Box flex="1" textAlign="right">
                            <Tooltip hasArrow label="Delete Content Item Without Saving" placement="top" bg="gray.700" color="white" borderRadius="5px" padding="5px 10px 5px 10px">
                                <IconButton 
                                    aria-label="delete-content-item" 
                                    size="md" 
                                    colorScheme="red" 
                                    icon={<CloseIcon w={3} h={3} />}
                                    onClick={() => {
                                        setItemToDelete(contentItem.dynamicId);
                                        onOpen();
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    </Flex>
                );
            })}
            <DeleteItemModal 
                typeToDelete="content block"
                isOpen={isOpen} 
                message={`Are you sure you want to delete this content block? Proceed with caution! This action cannot be undone`}
                onClose={onClose} 
                onDelete={() => handleDeleteContentItem(itemToDelete)}
            />
        </Container>
    );
}

export default ChapterContent;