import React from "react";
import { DateTimePickerProps } from "./types";
import DatePicker from "react-datepicker";
import { InputWrapper } from "./InputWrapper";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
    date,
    helperText,
    isInvalid,
    setDate,
    showTimeSelectOnly=false,
    showDateSelectOnly=false,
    title
}) => {
    const [selectedDate, setSelectedDate] = React.useState<Date>(date);

    const dateFormat = showDateSelectOnly ? "MM/dd/yyyy" : (showTimeSelectOnly ? "h:mm aa" : "MM/dd/yyyy h:mm aa");

    const renderDatePicker = () => {
        return(
            <DatePicker
                selected={selectedDate}
                onChange={(d: Date) => {
                    setDate(d);
                    setSelectedDate(d);
                }}
                timeInputLabel="Time:"
                dateFormat={dateFormat}
                showTimeInput={!showDateSelectOnly}
                showTimeSelectOnly={showTimeSelectOnly}
                disabled={isInvalid}
            />
        );
    };

    return(
        <InputWrapper 
            child={renderDatePicker()}
            helperText={helperText}
            title={title}
        />
    );
};