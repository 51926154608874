import React from "react";
import {
    Alert,
    AlertIcon,
    AlertStatus,
    Box,
    Container,
    Grid,
    Icon,
    Text
} from "@chakra-ui/react";
import { Prompt, useParams } from "react-router-dom";
import { BsBookHalf } from "react-icons/bs";
import { Chapter as FetchedChapter } from "../../models/Chapter";
import { ChapterProps, IUrlParams } from "./types";
import {  ReflectionAnswer, Submission } from "../../models/ReflectionAnswer";
import { useBeforeunload } from "react-beforeunload";
import { useDocumentTitle } from "../../utils/hooks";
import { useQuery, UseQueryResult } from "react-query";
import { pttpClient } from "../../clients/pttp";
import { serializeDynamicContentWithAnswer } from "../../components/ChapterDetail/serializer";
import { IDynamicContentItem } from "../../components/EditChapterDetail/types";
import ChapterDetail from "../../components/ChapterDetail";
import PageError from "../../components/PageError";
import SkeletonLoad from "../../components/SkeletonLoad/";
import "./index.css";

const Chapter: React.FC<ChapterProps> = ({ previewChapter, user }) => {
    useDocumentTitle("View Chapter");

    const [chapter, setChapter] = React.useState<FetchedChapter|null>();
    const [savedReflectionAnswers, setSavedReflectionAnswers] = React.useState<ReflectionAnswer[]|null>(null);
    const [submittedReflectionAnswers, setSubmittedReflectionAnswers] = React.useState<Submission[]|null>(null);
    const [changesHaveBeenSaved, setChangesHaveBeenSaved] = React.useState<boolean>(false);
    const [notice, setNotice] = React.useState<string>("");
    const [alertStatus, setAlertStatus] = React.useState<AlertStatus>("warning");
    const [chapterContentIsSerializing, setChapterContentIsSerializing] = React.useState<boolean>(true);
    const [content, setContent] = React.useState<IDynamicContentItem[]>([]);
    const { id } = useParams<IUrlParams>();

    const { 
        isFetching: getIsFetching, 
        isError: getIsError, 
        isFetched: getIsFetched,
        data: getData,
        refetch: getRefetch
    }: UseQueryResult<FetchedChapter|null, Error>  = useQuery<FetchedChapter|null, Error>([`getChapter${id}`], async () => {
        return pttpClient.getChapter(user, id ?? "")
    }, {
        refetchOnWindowFocus: false,
        enabled: false,
        cacheTime: 3600000,
        staleTime: 3600000
    });

    const { 
        isLoading: getSavedReflectionsIsLoading, 
        data: getSavedReflectionsData,
        refetch: getSavedReflectionsRefetch
    }: UseQueryResult<ReflectionAnswer[], Error>  = useQuery<ReflectionAnswer[], Error>([`getSavedReflectionAnswers${id}`], async () => {
        return pttpClient.getSavedReflectionAnswersForChapter(
            user, 
            id ?? "", 
            { sub: user?.id ?? "invalidId" }, 
            setNotice,
            setAlertStatus
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    const { 
        data: getSubmittedReflectionsData,
        refetch: getSubmittedReflectionsRefetch
    }: UseQueryResult<Submission[], Error>  = useQuery<Submission[], Error>([`getSubmittedReflectionAnswers${id}`], async () => {
        return pttpClient.getSubmittedReflectionAnswersForChapter(
            user, 
            id ?? "", 
            { sub: user?.id ?? "invalidId" }, 
            setNotice,
            setAlertStatus
        );
    }, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    const initScrollProgressBar = () => {
        const scrollTop = document.documentElement["scrollTop"] || document.body["scrollTop"];
        const scrollBottom = (document.documentElement["scrollHeight"] || document.body["scrollHeight"]) - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / scrollBottom * 100 + "%";
        
        document.getElementById("_progress")?.style.setProperty("--scroll", scrollPercent);
    };

    document.addEventListener("scroll", () => initScrollProgressBar(), { passive: true });

    useBeforeunload((event) => {
        if (!changesHaveBeenSaved) {
          event.preventDefault();
        }
    });

    React.useEffect(() => {
        if(id !== undefined) {
            if(previewChapter && id === "preview") {
                setChapter(previewChapter);
            } else {
                getRefetch();
            }
        }
    }, [previewChapter, id]);

    React.useEffect(() => {
        setChapter(getData);
    }, [getData]);

    React.useEffect(() => {
        if(chapter && !user?.isAdmin) {
            getSavedReflectionsRefetch();
            getSubmittedReflectionsRefetch();
        }
    }, [chapter]);

    React.useEffect(() => {
        setSavedReflectionAnswers(getSavedReflectionsData ?? null);
    }, [getSavedReflectionsData]);

    React.useEffect(() => {
        if(chapter) {
            setContent(serializeDynamicContentWithAnswer(chapter.content, savedReflectionAnswers ?? []));
            setChapterContentIsSerializing && setChapterContentIsSerializing(false);
        }
    }, [chapter, savedReflectionAnswers]);

    React.useEffect(() => {
        setSubmittedReflectionAnswers(getSubmittedReflectionsData ?? null);
    }, [getSubmittedReflectionsData]);

    if(getIsFetching || chapter === undefined || chapterContentIsSerializing) {
        return (
            <SkeletonLoad />
        );
    }

    if(getIsError) {
        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    const renderComponent = (): React.ReactElement => {
        // If the chapter is undefined, it hasn't been loaded yet. If it's null or an object, it's been loaded.
        // We only want to show the page error if the chapter has been fetched and not found.
        if(getIsFetched && chapter === null) {
            return (
                <Container maxW="container.lg">
                    <PageError message="The chapter you're looking for doesn't exist." display={<Icon as={BsBookHalf} w={180} h={180} color="red.500" />} />
                </Container>
            );
        } else if(getIsFetched && chapter) {
            if(user?.isAdmin) {
                <ChapterDetail 
                    chapter={chapter} 
                    savedReflectionAnswers={savedReflectionAnswers} 
                    submittedReflectionAnswers={submittedReflectionAnswers} 
                    user={user}
                    content={content}
                    setContent={setContent}
                />
            }

            return (
                <ChapterDetail 
                    chapter={chapter} 
                    savedReflectionAnswers={savedReflectionAnswers} 
                    submittedReflectionAnswers={submittedReflectionAnswers} 
                    setChangesHaveBeenSaved={setChangesHaveBeenSaved}
                    user={user}
                    setNotice={setNotice}
                    setAlertStatus={setAlertStatus}
                    isSavedLoading={getSavedReflectionsIsLoading}
                    content={content}
                    setContent={setContent}
                />
            );
        }

        return (
            <Container maxW="container.lg">
                <PageError />
            </Container>
        );
    }

    return(
        <>
            {!user?.isAdmin && <Prompt
                when={!changesHaveBeenSaved}
                message="You may have unsaved changes, are you sure you want to leave?"
            />}
            <Box id="_progress" backgroundColor="red.500"></Box>
            <Box fontSize="md" paddingTop="20px">
                <Grid minH="100vh" p={{ sm: 0, lg: 3}} alignContent="flex-start">
                    {
                        notice !== "" &&
                        <Container maxW="container.lg"  marginBottom="40px">
                            <Alert status={alertStatus} borderRadius="8px" textAlign="left" justifySelf="center">
                                <AlertIcon />
                                <Text>
                                    {notice}
                                </Text>
                            </Alert>
                        </Container>
                    }
                    {renderComponent()}
                </Grid>
            </Box>
        </>
    );
}

export default Chapter;